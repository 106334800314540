import React, { useContext, useEffect, useMemo, useState } from 'react';
import ProyectosContainer from '../ProyectosContainer';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AppContext from '../../../../AppContext';
import { useData } from '../../../../DataContext';
import JefaturasService from '../../../../services/JefaturasService';
import SubProyectosService from '../../../../services/SubProyectosService';
import VoluntariosService from '../../../../services/VoluntariosService';
import AlertUC from '../../../ui/AlertUC';
import { ButtonUC } from '../../../ui/ButtonUCcta';
import { InputUC } from '../../../ui/InputUC';
import ModalUC from '../../../ui/ModalUC';
import { SelectUC } from '../../../ui/SelectUC';
import { SubTitle } from '../../../ui/SubTitle';
import Table from '../../../ui/Table';

function Jefaturas({ id }) {
   const token = useContext(AppContext);
   const { contextValues, setValues } = useData();

   const history = useHistory();
   const [busqueda, setBusqueda] = useState('');
   const [isRol, setIsRol] = useState(false);
   const [voluntarios, setVoluntarios] = useState([]);
   const [voluntario, setVoluntario] = useState({});
   const [nivel, setNivel] = useState({ value: 0 });
   const [jefes, setJefes] = useState([]);
   const [roles, setRoles] = useState([]);
   const [addModal, setAddModal] = useState(false);
   const [addModalRoles, setAddModalRoles] = useState(false);
   const [subProyectos, setSubProyectos] = useState([]);
   const [selectedEtiquetas, setSelectedEtiquetas] = useState({});
   const [selectedSubProyecto, setSelectedSubProyecto] = useState([]);
   const [feedback, setFeedback] = useState({
      isOpen: false,
      realizado: false,
      id: null,
      respuesta: '',
      timestamp: '',
      errors: [],
   });
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm({
      mode: 'onBlur',
   });

   useEffect(() => {
      JefaturasService.getCargos(id, token).then((res) => {
         nombresSubproyectos(res.respuesta);
      });
      SubProyectosService.get(id, token).then((res) => {
         let sub = [];
         res.map((subpro) => sub.push({ value: subpro.id, label: subpro.nombre }));
         setSubProyectos(sub);
      });
      if (busqueda.length > 2) {
         VoluntariosService.findVoluntario(busqueda, token)
            .then((response) => setVoluntarios(response.data))
            .catch((err) => err);
      } else {
         setVoluntarios([]);
      }
   }, [busqueda]);

   useEffect(() => {
      JefaturasService.getRol(id, token).then((res) => {
         nombresSubproyectosRol(res.respuesta);
      });
   }, []);

   const nombresSubproyectos = (cargos) => {
      cargos.forEach((j) => {
         let nomSub = j.subproyectos == null ? (j.nomcargo == 'Jefe 1' ? 'Todos' : 'No asignado') : j.subproyectos.map((s) => s.label).join(', ');
         j.nomSub = nomSub;
      });
      setJefes(cargos);
   };
   const nombresSubproyectosRol = (cargos) => {
      cargos.forEach((j) => {
         let nomSub = j.subproyectos == null ? (j.nomcargo == 'Jefe 1' ? 'Todos' : 'No asignado') : j.subproyectos.map((s) => s.label).join(', ');
         j.nomSub = nomSub;
      });
      setRoles(cargos);
   };
   const onSubmit = () => {
      setValues({ cargos: jefes });
      setValues({ roles: roles });
   };

   const addJefe = () => {
      setAddModal(true);
      setVoluntario({});
      setIsRol(false);
      setSelectedSubProyecto([]);
   };
   const addRol = () => {
      setAddModalRoles(true);
      setIsRol(true);
      setVoluntario({});
      setSelectedSubProyecto([]);
   };
   const editJefe = (e, bosses) => {
      let index = bosses.findIndex((boss) => boss.rut == e.rut);
      let vol = bosses.find((vol) => vol.rut == e.rut);
      setVoluntario(bosses[index]);
      setNivel({ value: bosses[index].codcargo, label: bosses[index].nomcargo });
      setSelectedSubProyecto(bosses[index].subproyectos);
      setAddModal(true);
   };

   const editRol = (e, rols) => {
      let vol = rols.find((r) => r.rut == e.rut && r.nomSub == e.nomSub && r.nomcargo == e.nomcargo);
      setVoluntario(vol);
      setSelectedSubProyecto(vol.subproyectos);
      setAddModalRoles(true);
   };
   const agregarJefe = async () => {
      if (Object.keys(voluntario).length === 0) {
         alert('debe seleccionar un voluntario');
         return false;
      }
      if (nivel.value == 0) {
         alert('debe seleccionar un nivel');
         return false;
      }
      if (nivel.value > 37 && selectedSubProyecto.length == 0) {
         alert('debe seleccionar un subproyecto');
         return false;
      }
      let nomSub;
      if (nivel.value > 37) {
         nomSub = selectedSubProyecto.map((sub) => sub.label + ' ');
      } else {
         nomSub = 'Todos';
      }
      const newJefe = {
         id: voluntario.id ? voluntario.id : null,
         codproyecto: id,
         codUsuario: voluntario.codUsuario,
         codcargo: nivel.value,
         subproyectos: selectedSubProyecto,
         nom: voluntario.nom,
         apellPat: voluntario.apellPat,
         rut: voluntario.rut,
         dv: voluntario.dv,
         nomSub: nomSub,
         nomcargo: nivel.label,
      };

      let index = jefes.findIndex((volun) => volun.rut === voluntario.rut);
      if (index != -1) {
         jefes.splice(index, 1);
      } else {
         index = jefes.length;
      }
      const response = await JefaturasService.createCargo(newJefe, token);
      const newJefesArray = await JefaturasService.getCargos(id, token);
      nombresSubproyectos(newJefesArray.respuesta);
      setFeedback({
         isOpen: true,
         ...response,
      });
      setBusqueda('');
      setVoluntario({});
      setNivel({ value: 0 });
      setSelectedSubProyecto([]);
      setAddModal(false);
   };
   const getJefes = () => {
      JefaturasService.getCargos(token).then((res) => setJefes(res));
   };
   const agregarRol = async () => {
      if (Object.keys(voluntario).length === 0) {
         alert('debe seleccionar un voluntario');
         return false;
      }
      if (selectedSubProyecto.length === 0) {
         alert('debe seleccionar un subproyecto');
         return false;
      }
      if (Object.keys(selectedEtiquetas).length === 0) {
         alert('debe escoger un rol');
         return false;
      }

      const newRol = {
         id: voluntario.id ? voluntario.id : null,
         codproyecto: id,
         codUsuario: voluntario.codUsuario,
         nom: voluntario.nom,
         apellPat: voluntario.apellPat,
         codcargo: selectedEtiquetas.value,
         subproyectos: selectedSubProyecto,
         rut: voluntario.rut,
         nomSub: selectedSubProyecto[0].label,
         nomRol: selectedEtiquetas.label,
         key: new Date().getTime(),
      };
      const response = await JefaturasService.createRol(newRol, token);
      const newRolesArray = await JefaturasService.getRol(id, token);
      setFeedback({
         isOpen: true,
         ...response,
      });
      nombresSubproyectosRol(newRolesArray.respuesta);
      setBusqueda('');
      setSelectedSubProyecto([]);
      setSelectedEtiquetas([]);
      setVoluntario({});
      setAddModalRoles(false);
   };

   const deleteSelectedJefe = () => {
      setVoluntario({});
      setNivel({ value: 0 });
   };

   const deleteJefe = async (r, bosses) => {
      let aux = bosses.findIndex((volun) => volun.rut == r.rut);
      let response = await JefaturasService.deleteCargo(token, r.id);
      const newJefesArray = await JefaturasService.getCargos(id, token);
      console.log('response delete', response);
      setFeedback({
         isOpen: true,
         ...response,
      });
      nombresSubproyectos(newJefesArray.respuesta);
   };

   const deleteRol = async (r) => {
      console.log(r);
      let response = await JefaturasService.deleteRol(token, r.id);
      console.log('response delete', response);
      setFeedback({
         isOpen: true,
         ...response,
      });
      let newRolesArray = await JefaturasService.getRol(id, token);
      nombresSubproyectosRol(newRolesArray.respuesta);
   };
   const searchVoluntario = (e) => {
      setBusqueda(e.target.value);
   };

   const selectVoluntario = (e) => {
      const rut = e.target.id;
      if (!isRol) {
         let aux = jefes.find((boss) => boss.rut == rut);
         if (aux == undefined) {
            const vol = voluntarios.find((volun) => volun.rut == rut);
            setVoluntario(vol);
            setBusqueda('');
            setVoluntarios([]);
         } else {
            alert('voluntario ya ingresado!');
         }
      } else {
         const vol = voluntarios.find((volun) => volun.rut == rut);
         setVoluntario(vol);
         setBusqueda('');
         setVoluntarios([]);
      }
   };
   const handleNivel = (e) => {
      setNivel(e);
   };

   const handleSelectSub = (e) => {
      if (!Array.isArray(e)) {
         let selsub = [e];
         setSelectedSubProyecto(selsub);
      } else {
         setSelectedSubProyecto(e);
      }
   };

   const handleSelectRol = (e) => {
      setSelectedEtiquetas(e);
   };

   const enviarProyecto = () => {
      JefaturasService.create(contextValues, token);
   };
   const voluntariosEncontrados = voluntarios.map((vol) => (
      <div id={vol.rut} key={vol.rut} onClick={selectVoluntario}>
         {vol.nom} {vol.apellPat} {vol.rut}
         {vol.dv && <>- {vol.dv}</>}
         <i id={vol.rut} className="uc-icon icon-shape--rounded mx-2">
            edit
         </i>
      </div>
   ));

   const columns = useMemo(
      () => [
         {
            Header: 'Acciones',
            accessor: 'actions',
            Cell: (props) => {
               const rowValues = props.row.values;
               const bosses = props.data;
               return (
                  <div>
                     <span to={`/voluntarios/editar-voluntario/${rowValues.id}`}>
                        <span onClick={() => editJefe(rowValues, bosses)} title="Editar">
                           <i className="uc-icon icon-shape--rounded mx-2">edit</i>
                        </span>
                     </span>

                     <span onClick={() => deleteJefe(rowValues, bosses)} title="Eliminar">
                        <i className="uc-icon icon-shape--rounded mx-2">delete</i>
                     </span>
                  </div>
               );
            },
         },
         {
            Header: 'Rut',
            accessor: 'rut',
         },
         {
            Header: 'Nombre',
            accessor: 'nom',
         },
         {
            Header: 'Apellido',
            accessor: 'apellPat',
         },
         {
            Header: 'Nivel',
            accessor: 'nomcargo',
         },
         {
            Header: 'Subproyectos',
            accessor: 'nomSub',
         },
      ],
      [],
   );

   const columnsRol = useMemo(
      () => [
         {
            Header: 'Acciones',
            accessor: 'id',
            Cell: (props) => {
               const rowValues = props.row.values;
               const rols = props.data;
               return (
                  <div>
                     <span to={`/voluntarios/editar-voluntario/${rowValues.id}`}>
                        <span onClick={() => editRol(rowValues, rols)} title="Editar">
                           <i className="uc-icon icon-shape--rounded mx-2">edit</i>
                        </span>
                     </span>

                     <span onClick={() => deleteRol(rowValues)} title="Eliminar">
                        <i className="uc-icon icon-shape--rounded mx-2">delete</i>
                     </span>
                  </div>
               );
            },
         },
         {
            Header: 'Rut',
            accessor: 'rut',
         },
         {
            Header: 'Nombre',
            accessor: 'nom',
         },
         {
            Header: 'Rol',
            accessor: 'nomcargo',
         },
         {
            Header: 'Subproyectos',
            accessor: 'nomSub',
         },
      ],
      [],
   );

   const saveAndExit = () => {
      history.push('/proyectos');
   };

   return (
      <ProyectosContainer>
         <div className="container-fluid">
            <SubTitle value="Asignar jefaturas" />
            <div className="d-flex justify-content-between row ">
               <div className="col-lg-4 py-2">
                  <ButtonUC type="button" className="uc-btn btn-primary" onClick={() => addJefe()}>
                     Agregar jefe
                  </ButtonUC>
               </div>
            </div>

            {feedback.isOpen && (
               <div className="mt-4">
                  <AlertUC feedback={feedback} setFeedback={setFeedback} />
               </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
               <div className="row">
                  <div className="col-lg">
                     <Table columns={columns} data={jefes} />
                  </div>
               </div>
               <ButtonUC type="button" className="uc-btn btn-primary" onClick={() => addRol()}>
                  Agregar rol
               </ButtonUC>
               <div className="row">
                  <div className="col-lg">
                     <Table columns={columnsRol} data={roles} />
                  </div>
               </div>
            </form>

            <div></div>
         </div>
         {addModal && (
            <ModalUC
               setModal={setAddModal}
               title="Asignar Jefatura"
               setAlertType="success"
               // style={{ maxWidth: "250px" }}
            >
               <div className="row">
                  <div className="col-md-6">
                     <InputUC
                        label="Buscar Voluntario"
                        onChange={searchVoluntario}
                        value={
                           voluntario.nom != undefined
                              ? `${voluntario.nom} ${voluntario.apellPat} ${voluntario.rut}${voluntario.dv ? '-' + voluntario.dv : ''}`
                              : busqueda
                        }
                        readOnly={voluntario.nom != undefined ? true : false}
                     />
                     <div className="mb-24">{voluntariosEncontrados}</div>
                  </div>
                  <div className="col-md-2 ">
                     {voluntario.nom && (
                        <>
                           <label>
                              <b>Eliminar</b>
                           </label>
                           <div>
                              <i className="uc-icon" onClick={deleteSelectedJefe}>
                                 delete_outline
                              </i>
                           </div>
                        </>
                     )}
                  </div>
                  {voluntario.nom && (
                     <div className="col-md-4">
                        <SelectUC
                           label="Nivel Jefatura"
                           dominio="jefaturas"
                           onChange={handleNivel}
                           defaultValue={{ value: voluntario.codcargo, label: voluntario.nomcargo }}
                        />
                     </div>
                  )}
               </div>
               {voluntario.nom && (
                  <>
                     {nivel.value == 38 && (
                        <SelectUC
                           label="Subproyectos encargados"
                           closeMenuOnSelect={true}
                           isMulti
                           defaultValue={selectedSubProyecto}
                           options={subProyectos}
                           onChange={handleSelectSub}
                        />
                     )}
                     {nivel.value == 39 && (
                        <SelectUC
                           label="Subproyecto encargado"
                           closeMenuOnSelect={true}
                           defaultValue={selectedSubProyecto}
                           options={subProyectos}
                           onChange={handleSelectSub}
                        />
                     )}
                  </>
               )}
               <div className="">
                  <a className="uc-btn btn-cta" onClick={agregarJefe}>
                     Agregar
                  </a>
               </div>
            </ModalUC>
         )}
         {addModalRoles && (
            <ModalUC setModal={setAddModalRoles} title="Asignar Rol" setAlertType="success" style={{ maxWidth: '250px' }}>
               <div className="row">
                  <div className="col-md-8">
                     <InputUC
                        label="Buscar Voluntario"
                        onChange={searchVoluntario}
                        value={
                           voluntario.nom != undefined
                              ? `${voluntario.nom} ${voluntario.apellPat} ${voluntario.rut}${voluntario.dv ? '-' + voluntario.dv : ''}`
                              : busqueda
                        }
                        readOnly={voluntario.nom != undefined ? true : false}
                     />
                     <div className="mb-24">{voluntariosEncontrados}</div>
                  </div>
                  <div className="col-md-4">
                     {voluntario.nom && (
                        <>
                           <label>
                              <b>Eliminar</b>
                           </label>
                           <div>
                              <i className="uc-icon" onClick={deleteSelectedJefe}>
                                 delete_outline
                              </i>
                           </div>
                        </>
                     )}
                  </div>
               </div>
               {voluntario.nom && (
                  <div>
                     <SelectUC options={subProyectos} onChange={handleSelectSub} defaultValue={selectedSubProyecto} />

                     <SelectUC
                        closeMenuOnSelect={true}
                        dominio="roles"
                        defaultValue={{ value: voluntario.codcargo, label: voluntario.nomcargo }}
                        onChange={handleSelectRol}
                     />
                  </div>
               )}
               <div className="">
                  <a className="uc-btn btn-cta" onClick={agregarRol}>
                     Agregar
                  </a>
               </div>
            </ModalUC>
         )}

         <div className="row ">
            <div className="col-lg-12 justify-content-around py-4 d-flex">
               <ButtonUC type="submit" className="uc-btn btn-cta" style={{ maxWidth: '250px' }}>
                  Guardar
               </ButtonUC>
            </div>
         </div>
      </ProyectosContainer>
   );
}

export default Jefaturas;
