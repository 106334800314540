import React from "react";

export default function Denegado() {
  return (
    <>
      <div className="container px-20 p-24 my-28">
        <h1>No tienes acceso a esta url!</h1>
        <div className="uc-text-divider divider-danger my-12"></div>
        <div className="row container justify-content-center" style={{ maxWidth: "800px" }}>
          <p className="lead ">Contacta con un administrador si este mensaje no es correcto.</p>
          {/* <hr className="my-4" /> */}
        </div>
      </div>
    </>
  );
}
