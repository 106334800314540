import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import UserContext from "../../UserContext";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import { Redirect } from "react-router";

import ProyectoService from "../../services/ProyectoService";
import SubProyectosService from "../../services/SubProyectosService";
import InscripService from "../../services/InscripService";

import FormInscripcion from "./FormInscripcion";
import ModalUC from "./../ui/ModalUC";

function InscripcionComponent() {
  const { codproyecto } = useParams();
  const token = useContext(AppContext);
  const user = useContext(UserContext);
  const history = useHistory();

  const [proyecto, setProyecto] = useState({});
  const [subproyectos, setSubproyectos] = useState({});
  const [cuestionario, setCuestionario] = useState([]);
  const [cuestionarios, setCuestionarios] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [selectedSub, setSelectedSub] = useState(null);
  const [feedback, setFeedback] = useState({
    isOpen: false,
    realizado: false,
    id: null,
    respuesta: "",
    timestamp: "",
    errors: [],
  });
  const [confirm, setConfirm] = useState({
    modal: false,
    code: 0,
    title: "Confirmar",
    text: "Confirmar acción",
    onConfirm: false,
  });

  const selectSubproyecto = (e) => {
    const subProyectoCuestionario = cuestionarios.find(cuestionario => cuestionario.codSubProyecto === e);
    if (subProyectoCuestionario) {
      setCuestionario(JSON.parse(subProyectoCuestionario.cuestionario));
    } else {
      const cuestionarioPredeterminado = cuestionarios.find(cuestionario => cuestionario.predeterminado === 's');
      if (cuestionarioPredeterminado) setCuestionario(JSON.parse(cuestionarioPredeterminado.cuestionario));
    }
    setSelectedSub(e);
  };

  const onSubmit = async () => {
    let req = false;
    let preg = [];
    cuestionario.forEach((c) => {
      if (c.respuestaReq) {
        switch (c.tipoPregunta) {
          case "abierta":
            if (c.respuesta === undefined) {
              req = true;
              preg.push(c.enunciado);
            } else if (c.respuesta.trim() === "") {
              req = true;
              preg.push(c.enunciado);
            }
            break;
          case "alternativa":
            let findTrue = c.opciones.find((op) => op.checked);
            if (!findTrue) {
              req = true;
              preg.push(c.enunciado);
            }
            break;
          case "seleccionMultiple":
            let count = c.opciones.filter((op) => op.checked).length;
            if (count === 0) {
              req = true;
              preg.push(c.enunciado);
            }
            break;
          case "subirArchivo":
            if (c.respuesta === undefined || c.respuesta === null) {
              req = true;
              preg.push(c.enunciado);
            } else if (c.respuesta.trim() === "") {
              req = true;
              preg.push(c.enunciado);
            }
            break;
          case "fecha":
            if (c.respuesta === undefined) {
              req = true;
              preg.push(c.enunciado);
            } else if (c.respuesta.trim() === "") {
              req = true;
              preg.push(c.enunciado);
            }
            break;
          case "numerica":
            if (c.respuesta === undefined) {
              req = true;
              preg.push(c.enunciado);
            } else if (c.respuesta.trim() === "") {
              req = true;
              preg.push(c.enunciado);
            }
            break;
          default:
            break;
        }
      }
    });

    if (req) {
      let mensaje = (
        <div>
          <h3 className="mb-24">Debe responder las preguntas requeridas</h3>
          {preg.map((p) => (
            <div>{p}</div>
          ))}
        </div>
      );

      setConfirm({
        ...confirm,
        modal: true,
        text: mensaje,
        onConfirm: false,
      });
    } else {
      const res = await InscripService.checkRestricciones(codproyecto, user.codUsuario, selectedSub, token);

      console.log(res);
      setConfirm({
        ...confirm,
        modal: true,
        code: res.code,
        text: res.respuesta,
        onConfirm: true,
      });
    }
  };

  const accept = () => {
    if (confirm.onConfirm) {
      onConfirm();
    }
    setConfirm({
      ...confirm,
      modal: false,
    });
  };
  const cancel = () => {
    setConfirm({
      ...confirm,
      modal: false,
    });
  };
  const onConfirm = async () => {
    const estado = confirm.code === 4 ? 46 : 45;
    console.log(cuestionario);
    if (confirm.code !== 1) {
      const inscripcion = {
        codUsuario: user.codUsuario,
        codSubProyecto: selectedSub,
        estado: estado,
        pago: 28,
        respta: JSON.stringify(cuestionario),
      };

      let req = false;
      let preg = [];
      cuestionario.forEach((c) => {
        if (c.respuestaReq) {
          switch (c.tipoPregunta) {
            case "abierta":
              if (c.respuesta === undefined) {
                req = true;
                preg.push(c.enunciado);
              } else if (c.respuesta.trim() === "") {
                req = true;
                preg.push(c.enunciado);
              }
              break;
            case "alternativa":
              let findTrue = c.opciones.find((op) => op.checked);
              if (!findTrue) {
                req = true;
                preg.push(c.enunciado);
              }
              break;
            case "seleccionMultiple":
              let count = c.opciones.filter((op) => op.checked).length;
              if (count === 0) {
                req = true;
                preg.push(c.enunciado);
              }
              break;
            case "subirArchivo":
              if (c.respuesta === undefined || c.respuesta === null) {
                req = true;
                preg.push(c.enunciado);
              } else if (c.respuesta.trim() === "") {
                req = true;
                preg.push(c.enunciado);
              }
              break;
            case "fecha":
              if (c.respuesta === undefined) {
                req = true;
                preg.push(c.enunciado);
              } else if (c.respuesta.trim() === "") {
                req = true;
                preg.push(c.enunciado);
              }
              break;
            case "numerica":
              if (c.respuesta === undefined) {
                req = true;
                preg.push(c.enunciado);
              } else if (c.respuesta.trim() === "") {
                req = true;
                preg.push(c.enunciado);
              }
              break;
            default:
              break;
          }
        }
      });

      if (req) {
        alert("Preguntas requeridas \n" + preg.map((p) => p + "\n"));
        console.log(preg);
      } else {
        try {
          let response = await InscripService.create(inscripcion, token);
          response.respuesta = response.realizado
            ? confirm.code !== 4
              ? "Quedaste inscrito en lista de espera"
              : "Se ha inscrito al proyecto"
            : response.respuesta;
          setFeedback({
            isOpen: true,
            ...response,
          });
          if (response.realizado) {
            const newMensaje = {
              codUsuario: user.codUsuario,
              codSubproyecto: selectedSub,
              coment: confirm.code === 4 ? "Quedaste inscrito en lista de espera" : "Se ha inscrito al proyecto",
              tipo: `Asunto: Inscripción`,
            };
            const res = await InscripService.enviarMensaje(newMensaje, token);
            console.log(res.respuesta);
            setTimeout(() => {
              history.push("/mis-proyectos");
            }, 5000);
          }
        } catch (err) {
          setFeedback({
            isOpen: true,
            ...err,
          });
        }
      }
    } else {
      setFeedback({
        ...feedback,
        isOpen: true,
        respuesta: "No se ha podido inscribir",
      });
    }
  };

  useEffect(() => {
    ProyectoService.fetchById(codproyecto, token)
      .then((response) => {
        setProyecto(response);
        setEtiquetas(response.etiquetas);
        console.log(response);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    ProyectoService.fetchCuestionarios(codproyecto, token, true)
      .then((response) => {
        console.log(response);
        if (response.status !== 404) {
          setCuestionarios(response.respuesta);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    SubProyectosService.get(codproyecto, token, true)
      .then((res) => setSubproyectos(res.map((option) => ({ value: option.id, label: option.nombre }))))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <FormInscripcion
        onSubmit={onSubmit}
        proyecto={proyecto}
        subproyectos={subproyectos}
        cuestionario={cuestionario}
        setCuestionario={setCuestionario}
        etiquetas={etiquetas}
        selectSubproyecto={selectSubproyecto}
        feedback={feedback}
        setFeedback={setFeedback}
        selectedSub={selectedSub}
      />
      {confirm.modal && (
        <ModalUC setModal={setConfirm} title={confirm.title} setAlertType="success">
          <div className="mb-24 p-24">
            <h4>{confirm.text}</h4>
          </div>
          <div className="row">
            <div className="col-md-4">
              <a href="#" className="uc-btn btn-primary" onClick={() => accept()}>
                Aceptar
              </a>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <a href="#" className="uc-btn btn-primary" onClick={() => cancel()}>
                Cancelar
              </a>
            </div>
          </div>
        </ModalUC>
      )}
    </div>
  );
}

export default InscripcionComponent;
