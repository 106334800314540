import React from 'react'

export const SubTitle = ({ value }) => {
    return (
        <div className='d-flex align-items-center py-4'>
            <h3>{value}</h3>
            <span className="uc-heading-decoration"></span>
        </div>
    )
}

export default SubTitle
