import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import Footer from "../components/ui/Footer";
import Topbar from "../components/ui/Topbar";
import LoginComponent from "../components/Auth/LoginComponent";

import CreateVoluntarioCas from "../components/backoffice/voluntarios/CreateVoluntarioCas";
import RecuperarContrasena from "../components/Auth/RecuperarConstrasena";

export default function AuthRoutes({ saveToken }) {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Topbar />
      <div style={{ minHeight: "50vh", paddingBottom: "260px" }} className="container px-20 p-24 mt-20">
        <Switch>
          <Route exact path="/" children={<LoginComponent saveToken={saveToken} />} />
          <Route exact path="/inscripcion" component={CreateVoluntarioCas} />
          <Route exact path="/recuperar-contrasena" component={RecuperarContrasena} />
          <Route exact path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
      <Footer />
    </div>
  );
}
