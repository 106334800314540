import React, { useEffect } from "react";
import UserContext from "../../../../UserContext";
import AppContext from "../../../../AppContext";

import InscripService from "../../../../services/InscripService";
import Table from "../../../ui/Table";
import { useHistory } from "react-router";

function Notificaciones() {
  const user = React.useContext(UserContext);
  const token = React.useContext(AppContext);
  const history = useHistory();

  const [messages, setMessages] = React.useState([]);

  const marcarNoLeido = (rowValues) => {
    console.log(rowValues);
    history.push(`/proyectos/editar/${rowValues.codProyecto}/sub/${rowValues.codSubProyecto}/inscrito/${rowValues.codUsuarioAfecta}?mensaje=true`);
  };

  const marcarComoLeido = async (param, val) => {
    const { original } = param.row;
    const bitacora = {
      codSubproyecto: original.codSubProyecto,
      codUsuarioAccion: original.codUsuarioAccion,
      codUsuarioAfecta: original.codUsuarioAfecta,
      coment: original.coment,
      fechaActualiz: original.fechaActualiz,
      id: original.codBitacora,
      tipo: original.tipo,
      visto: val ? "s" : "n",
    };
    console.log(val);
    InscripService.setVisto(bitacora, token);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Acciones",
        accessor: "actions",
        Cell: (props) => {
          const rowValues = props.row.values;
          return (
            <div style={{ width: "70px" }}>
              <span>
                <span onClick={() => marcarNoLeido(rowValues)} title="Ver mensaje">
                  <i className="uc-icon  mx-2">visibility</i>
                </span>
              </span>
              <span>
                <span title="Marcar como leido" className="mx-2">
                  <input
                    type="checkbox"
                    defaultChecked={props.row.values.visto === "s"}
                    onChange={(val) => {
                      marcarComoLeido(props, val.target.checked);
                    }}
                  />
                </span>
              </span>
            </div>
          );
        },
      },
      {
        Header: "Fecha",
        accessor: "fechaActualiz",
        Cell: (props) => props.row.values.fechaActualiz.substring(0, 10),
      },
      {
        Header: "Mensaje",
        accessor: "coment",
      },
      {
        Header: "Nombre",
        accessor: "nomUsuario",
      },
      {
        Header: "Apellido",
        accessor: "apellidoPaterno",
      },
      {
        Header: "Proyecto",
        accessor: "nomProyecto",
      },
      {
        Header: "Subproyecto",
        accessor: "nomSubProyecto",
      },
      {
        Header: "codProyecto",
        accessor: "codProyecto",
      },
      {
        Header: "codSubProyecto",
        accessor: "codSubProyecto",
      },
      {
        Header: "codUsuarioAfecta",
        accessor: "codUsuarioAfecta",
      },
    ],
    []
  );
  const initialState = {
    hiddenColumns: ["codProyecto", "codSubProyecto", "codUsuarioAfecta"],
  };
  React.useEffect(() => {
    InscripService.getNotificaciones(user.codUsuario, token).then((r) => {
      if (r) {
       const uniqueData = Array.from(new Set(r.respuesta.map(JSON.stringify))).map(JSON.parse);
       setMessages(uniqueData);
     } else {
       setMessages([]);
     }
    });
  }, []);
  return (
    <>
      <Table columns={columns} data={messages} initialState={initialState} />
    </>
  );
}

export default Notificaciones;
