import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import AppContext from '../../../AppContext';
import UserContext from '../../../UserContext';
import JefaturasService from '../../../services/JefaturasService';
import PerfilService from '../../../services/PerfilService';
import ProyectoService from '../../../services/ProyectoService';
import AlertUC from '../../ui/AlertUC';
import ProyectosContainer from './ProyectosContainer';
import Cuestionario from './formularios/Cuestionario';
import Informacion from './formularios/Informacion';
import Jefaturas from './formularios/Jefaturas';
import Restricciones from './formularios/Restricciones';
import Subproyectos from './formularios/Subproyectos';
import Navbar from './ui/Navbar';
import ListaVoluntarios from './verVoluntarios/ListaVoluntarios';

function EditProyecto() {
   const token = useContext(AppContext);
   const user = useContext(UserContext);
   const useQuery = () => new URLSearchParams(useLocation().search);
   const query = useQuery();
   const sub = query.get('sub');
   const vol = query.has('vol');

   const [step, setStep] = useState(vol ? 6 : 1);
   const [imagen, setImagen] = useState();
   const [informacion, setInformacion] = useState({});
   const [restricciones, setRestricciones] = useState({});
   const [acceso, setAcceso] = useState(false);
   const { idPro } = useParams();
   console.log(useParams());
   const [feedback, setFeedback] = useState({
      isOpen: false,
      realizado: false,
      id: null,
      respuesta: '',
      timestamp: '',
      errors: [],
   });
   const fetchInformacion = async () => {
      try {
         const response = await ProyectoService.fetchById(idPro, token);
         const formattedValues = {
            id: idPro,
            nom: response.nom,
            idEstado: response.idEstado,
            descripcion: response.descripcion,
            fechaInscripIni: response.fechaInscripIni,
            fechaInscripTerm: response.fechaInscripTerm,
            fechaIni: response.fechaIni,
            fechaTerm: response.fechaTerm,
            reqPago: response.reqPago,
            aporteminimo: response.aporteminimo,
            aportesugerido: response.aportesugerido,
            urlProyecto: response.urlProyecto,
            urlInstagram: response.urlInstagram,
            categorias: response.categorias,
            etiquetas: response.etiquetas,
         };
         setImagen(response.urlLogo);
         setInformacion(formattedValues);
      } catch (err) {
         console.log(err);
      }
   };

   const updateInformacion = async (data) => {
      const inscripIni = data.fechaInscripIni;
      const inscripTerm = data.fechaInscripTerm;
      const inicio = data.fechaIni;
      const termino = data.fechaTerm;
      console.log(data);
      const formattedValues = {
         id: idPro,
         nom: data.nom,
         idEstado: data.idEstado,
         descripcion: data.descripcion,
         fechaInscripIni: moment(inscripIni).format('YYYY-MM-DD'),
         fechaInscripTerm: moment(inscripTerm).format('YYYY-MM-DD'),
         fechaIni: moment(inicio).format('YYYY-MM-DD'),
         fechaTerm: moment(termino).format('YYYY-MM-DD'),
         aporteminimo: data.aporteminimo,
         aportesugerido: data.aportesugerido,
         categorias: data.categorias,
         etiquetas: data.etiquetas,
         urlLogo: imagen,
         urlProyecto: data.urlProyecto,
         urlInstagram: data.urlInstagram,
         requierePago: data.aporteminimo != null ? true : false,

         codUsuarioModif: user.codUsuario,
      };

      try {
         const response = await ProyectoService.updateInformacion(formattedValues, idPro, token);
         setFeedback({
            isOpen: true,
            ...response,
         });
      } catch (err) {
         console.log(err);
      }
      fetchInformacion();
   };

   const fetchRestricciones = async () => {
      try {
         const response = await ProyectoService.fetchRestricciones(idPro, token);
         const formattedValues = {
            hasMaxIntegrantes: response.respuesta.maxIntegrantes ? true : false,
            maxIntegrantes: response.respuesta.maxIntegrantes,
            confir: response.respuesta.confir === 's',
            usuarioUC: response.respuesta.usuarioUC === 's',
            validarVacantes: response.respuesta.validarVacantes === 's',

            hasAreasEstudio: response.respuesta.hasAreasEstudio,
            hasMaxEstudio: response.respuesta.hasMaxEstudio,
            hasMaxGenero: response.respuesta.hasMaxGenero,
            hasMinEdad: response.respuesta.hasMinEdad,
            hasMaxEdad: response.respuesta.hasMaxEdad,

            idAreasEstudio: response.respuesta.idAreasEstudio,
            maxEstudio: response.respuesta.maxEstudio,
            maxGenero: response.respuesta.maxGenero,
            minEdad: response.respuesta.minEdad,
            maxEdad: response.respuesta.maxEdad,
         };
         setRestricciones(formattedValues);
      } catch (err) {
         console.log(err);
      }
   };

   const updateRestricciones = async (data) => {
      const formattedValues = {
         codUsuarioModif: 1,
         maxIntegrantes: data.maxIntegrantes,
         confir: data.confir ? 's' : 'n',
         usuarioUC: data.usuarioUC ? 's' : 'n',
         validarVacantes: data.hasMaxIntegrantes ? 's' : 'n',
         idAreasEstudio: data.idAreasEstudio,
         maxEstudio: data.maxEstudio,
         maxGenero: data.maxGenero,
         minEdad: data.minEdad,
         maxEdad: data.maxEdad,

         hasAreasEstudio: data.hasAreasEstudio,
         hasMaxIntegrantes: data.hasMaxIntegrantes,
         hasMaxEstudio: data.hasMaxEstudio,
         hasMaxGenero: data.hasMaxGenero,
         hasMinEdad: data.hasMinEdad,
         hasMaxEdad: data.hasMaxEdad,
      };
      const response = await ProyectoService.updateRestricciones(formattedValues, idPro, token);
      setFeedback({
         isOpen: true,
         ...response,
      });
      fetchRestricciones();
   };

   const updateSubproyectos = () => {
      setStep(5);
   };

   const verPermisos = async () => {
      let res = false;
      const hasPermise = await PerfilService.permisoHandler(user, 'Proyectos');
      if (!hasPermise) {
         const jefaturasSub = await JefaturasService.getSubProyectos(user.codUsuario, token);
         console.log('jefaturasSub', jefaturasSub, idPro);
         const curJef = jefaturasSub.filter((j) => parseInt(j.codProyecto) === parseInt(idPro));
         console.log(curJef);
         if (curJef.length > 0) {
            if (curJef[0].codDominioValor === 37) res = true;
         }
      } else {
         res = true;
      }

      return res;
   };
   useEffect(() => {
      fetchInformacion();
      fetchRestricciones();
      if (sub) setStep(4);
      verPermisos().then((r) => setAcceso(r));
   }, []);

   return acceso ? (
      <ProyectosContainer>
         <Navbar step={step} setStep={setStep} id={idPro} />
         {step === 1 && <Informacion id={idPro} defaults={informacion} onSubmit={updateInformacion} imagen={imagen} setImagen={setImagen} edit />}
         {step === 2 && <Restricciones id={idPro} defaults={restricciones} onSubmit={updateRestricciones} />}
         {step === 3 && <Cuestionario id={idPro} setFeedback={setFeedback} />}
         {step === 4 && <Subproyectos id={idPro} token={token} onSubmit={updateSubproyectos} />}
         {step === 5 && <Jefaturas id={idPro} token={token} />}
         {step === 6 && <ListaVoluntarios id={idPro} token={token} />}
         {feedback.isOpen && <AlertUC feedback={feedback} setFeedback={setFeedback} />}
      </ProyectosContainer>
   ) : (
      'no puedes acceder'
   );
}

export default EditProyecto;
