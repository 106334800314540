import React, { useState } from 'react'

function ProyectosContainer({ children, ...props }) {

    return (
        <div {...props}>
            {children}
        </div>
    )
}

export default ProyectosContainer
