import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../../AppContext";
import ProyectoService from "../../../../services/ProyectoService";
import { ButtonUC } from "../../../ui/ButtonUCcta";
import SelectUC from "../../../ui/SelectUC";
import { SubTitle } from "../../../ui/SubTitle";
import ProyectosContainer from "../ProyectosContainer";

function Cuestionario({ id, setFeedback }) {
  const token = useContext(AppContext);
  
  const [nombreCuestionario, setNombreCuestionario] = useState(null);
  const [isDefault, setIsDefault] = useState(false);
  const [preguntas, setPreguntas] = useState([]);
  const [cuestionarios, setCuestionarios] = useState([]);
  const [selectedCuestionario, setSelectedCuestionario] = useState(null);
  const [cuestionarioOpciones, setCuestionarioOpciones] = useState([]);
  const [selectedCuestionarioOpcion, setSelectedCuestionarioOpcion] = useState([]);
  const [curQuiz, setCurQuiz] = useState();

  function handleTipoRes(e) {
    let option = e.target.value.split("|");
    let index = preguntas.findIndex((preg) => preg.id.toString() === option[0].toString());
    preguntas[index].tipoPregunta = option[1];
    preguntas[index].opciones = [];
    if (option[1] === "alternativa" || option[1] === "seleccionMultiple") {
      preguntas[index].opciones = [{ alternativa: "" }];
    }
    let newArray = [...preguntas];
    setPreguntas(newArray);
  }

  const fetchCuestionarios = async () => {
    try {
       const response = await ProyectoService.fetchCuestionarios(id, token);
       if (response.respuesta) {
          setCuestionarios(response.respuesta);
       }
    } catch (err) {
       console.log(err);
    }
 };

 const isValidCuestionario = (cuestionariosPredeterminados) => {
  if (!isDefault) {
    if (cuestionariosPredeterminados.length === 0) {
      setFeedback({
        isOpen: true,
        realizado: false,
        respuesta: "Tiene que haber al menos un cuestionario predeterminado"
    });

    return false;
    }
  }

  return true;
 }

  const updateCuestionario = async () => {
    setFeedback({ isOpen: false });

    if (selectedCuestionario) {
      // Updating an existing questionnaire
      const cuestionariosPredeterminados = cuestionarios
        .filter(cuestionario => cuestionario.codCuestionario !== selectedCuestionario.codCuestionario && cuestionario.predeterminado === 's');
      if (!isValidCuestionario(cuestionariosPredeterminados)) {
        return;
      }

      const cuestionario = {
        codProyecto: id,
        codSubProyecto: selectedCuestionario.codSubProyecto,
        nombreCuestionario: nombreCuestionario,
        cuestionario: JSON.stringify(preguntas),
        predeterminado: isDefault ? 's' : 'n'
      }
  
      const response = await ProyectoService.updateCuestionario(cuestionario, selectedCuestionario.codCuestionario, token);
      if (response.realizado) {
        setCuestionarios(c=> {
          return c.map(item => {
              return item.id === selectedCuestionario.codCuestionario ? 
              {...item, nombreCuestionario: nombreCuestionario, cuestionario: cuestionario.cuestionario, predeterminado: cuestionario.predeterminado} : 
              isDefault ? {...item, predeterminado: 'n'} : item
          })
        });
      }

      setFeedback({
        isOpen: true,
        ...response,
     });
    } else {
      // Creating new questionnaire
      const cuestionariosPredeterminados = cuestionarios
        .filter(cuestionario => cuestionario.predeterminado === 's');
      if (!isValidCuestionario(cuestionariosPredeterminados)) {
        return;
      }

      const cuestionario = {
        codProyecto: id,
        nombreCuestionario: nombreCuestionario,
        cuestionario: JSON.stringify(preguntas),
        predeterminado: isDefault ? 's' : 'n'
      }

      const response = await ProyectoService.saveCuestionario(cuestionario, token);
      if (response.realizado) {
        const cuestionariosActualizados =  [...cuestionarios, response.cuestionario];
        if (isDefault) {
          cuestionariosActualizados.forEach(cuestionarioActualizado => {
            if (cuestionarioActualizado.codCuestionario !==  response.cuestionario.codCuestionario) {
              cuestionarioActualizado.predeterminado = 'n'
            }
          })
        }
        
        setCuestionarios(cuestionariosActualizados);
        setSelectedCuestionario(response.cuestionario);
      }

      setFeedback({
        isOpen: true,
        ...response,
     });
    }
  };

  const SaveButton = () => {
    if (preguntas.length !== 0) {
      return <input type="button" 
        className="uc-btn btn-cta" 
        style={{ maxWidth: "250px" }} 
        value="Guardar" onClick={() => updateCuestionario()} 
      />;
    }

    return "";
  };

  const selectCuestionario = (e) => {
    setFeedback({ isOpen: false });
    setSelectedCuestionarioOpcion(e);

    const selectedCuestionario = cuestionarios.find((s) => s.codCuestionario === e.value);
    setSelectedCuestionario(selectedCuestionario);
  };

  const nuevoCuestionario = (e) => {
    setIsDefault(cuestionarios.length === 0);
    setSelectedCuestionario(null);
    agregarPregunta(e, true);
  };

  const agregarPregunta = (e, resetPreguntas = false) => {
    const nuevaPregunta = {
      id: new Date().getTime(),
      tipoPregunta: "abierta",
      state: false,
      enunciado: "",
      opciones: [],
      respuestaReq: false,
    };
    
    if (resetPreguntas) {
      setPreguntas([nuevaPregunta])
    } else {
      let option = e.target.id.split("|");
      let index = preguntas.findIndex((preg) => preg.id == option[0]) + 1;
      setPreguntas((preguntas) => [...preguntas.slice(0, index), nuevaPregunta, ...preguntas.slice(index)]);
    }
  };

  const eliminarPregunta = (e) => {
    setPreguntas(preguntas.filter((preg) => preg.id != curQuiz));
  };

  const handleValuePregunta = (e) => {
    let option = e.target.id.split("|");
    let index = preguntas.findIndex((preg) => preg.id == option[0]);
    preguntas[index].enunciado = e.target.value;
    let newArray = [...preguntas];
    setPreguntas(newArray);
  };

  const newAlternativaExcluyente = (e) => {
    let option = e.target.id.split("|");
    let index = preguntas.findIndex((preg) => preg.id == option[0]);
    preguntas[index].opciones.push({ alternativa: "" });
    let newArray = [...preguntas];
    setPreguntas(newArray);
  };

  const eliminarAlternativaExcluyente = (e) => {
    let option = e.target.id.split("|");
    let index = preguntas.findIndex((preg) => preg.id == option[0]);

    if (preguntas[index].opciones.length > 1) {
      preguntas[index].opciones.pop();
      let newArray = [...preguntas];
      setPreguntas(newArray);
    }
  };

  const handleValueAlternativaExcluyente = (e) => {
    let option = e.target.id.split("|");
    let index = preguntas.findIndex((preg) => preg.id == option[0]);
    preguntas[index].opciones[option[2]].alternativa = e.target.value;
    let newArray = [...preguntas];
    setPreguntas(newArray);
  };

  const TipoPregunta = ({ tipoPregunta, index, id }) => {
    if (tipoPregunta === "abierta") {
      return <textarea className="uc-input-style" value="Espacio para la respuesta del voluntario" disabled={true}></textarea>;
    } else if (tipoPregunta === "alternativa" || tipoPregunta === "seleccionMultiple") {
      const options = preguntas[index].opciones.map((opc, index) => (
        <div className="d-flex" key={index}>
          <div className="col-lg-auto my-auto">
            <span className="">{String.fromCharCode(index + 97)}.</span>
          </div>

          <div className="col-lg-10">
            <input type="text" id={id + "|alternativaExcluyente|" + index} onBlur={handleValueAlternativaExcluyente} onMouseLeave={handleValueAlternativaExcluyente} defaultValue={opc.alternativa} placeholder="Escriba aquí la alternativa" className="uc-input-style no-background-primary" style={{ fontWeight: "normal" }} />
          </div>
          <div className="col-lg-auto m-auto">
            <i className="uc-icon" onClick={newAlternativaExcluyente} id={id}>
              add_circle_outline
            </i>
            <i className="uc-icon" onClick={eliminarAlternativaExcluyente} id={id + "|alternativaExcluyente|" + index + "|delete"}>
              delete_outline
            </i>
          </div>
        </div>
      ));
      return <div>{options}</div>;
    } else if (tipoPregunta === "subirArchivo") {
      return <input name="example" className="uc-input-style" type="file" disabled={true} style={{ width: "350px" }} />;
    } else if (tipoPregunta === "fecha") {
      return (
        <div className="row">
          <div className="col-md-4">
            <input type="date" className="uc-input-style" disabled style={{ width: "350px" }} />
          </div>
        </div>
      );
    } else if (tipoPregunta === "numerica") {
      return (
        <div className="row">
          <div className="col-md-4">
            <input name="example-number" type="number" className="uc-input-style" placeholder="Campo de tipo numérico" disabled={true} style={{ width: "350px" }} />
          </div>
        </div>
      );
    }
  };

  const Cuestionario = ({ preguntas, curQuiz }) => {
    const questionary = preguntas.map((cues, index) => {
      let curState = false;
      if (curQuiz === cues.id) {
        curState = true;
      }
      return <Pregunta cues={cues} key={index} handleTipoRes={handleTipoRes} index={index} curState={curState} tipoPregunta={preguntas[index].tipoPregunta} agregarPregunta={agregarPregunta} setPreguntas={setPreguntas} />;
    });

    return <div>{questionary}</div>;
  };

  const handlePreguntaReq = (id, e) => {
    preguntas.map((pregunta) => (pregunta.id === id ? (pregunta.respuestaReq = e.target.checked) : "brp"));
  };

  const Pregunta = ({ handleTipoRes, cues, tipoPregunta, agregarPregunta, index, curState }) => {
    const cuesContext = useContext(CuestionarioContext);
    return (
      <div className={curState ? "card-border--top uc-card mb-4 px-8" : "card-border--top-inactive uc-card uc-card mb-4 px-8"} key={cues.id} onMouseOver={() => setCurQuiz(cues.id, cuesContext.preguntas)}>
        <div className="uc-card_body">
          <div className="row d-flex align-items-center pb-2">
            <div className="col-lg-auto">
              <div className="uc-step">
                <div className="uc-step_head">
                  <div className="uc-step-default">{index + 1}</div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 px-0">
              <h4>
                <input style={{ border: "1px solid" }} id={cues.id + "|enunciado"} type="text" className="uc-input-style no-background-primary px-2" defaultValue={cues.enunciado} onBlur={handleValuePregunta} onMouseLeave={handleValuePregunta} placeholder="Escriba aquí la pregunta" />
              </h4>
            </div>
            <div className="col-lg-4 px">
              <select name="select-tipo" key={cues.id} type="text" className="uc-input-style" onChange={handleTipoRes} defaultValue={cues.id + "|" + tipoPregunta}>
                <option value={cues.id + "|abierta"}>Pregunta Abierta</option>
                <option value={cues.id + "|alternativa"}>Alternativas Excluyentes</option>
                <option value={cues.id + "|seleccionMultiple"}>Selección Multiple</option>
                <option value={cues.id + "|subirArchivo"}>Subir Archivo</option>
                <option value={cues.id + "|fecha"}>Fecha</option>
                <option value={cues.id + "|numerica"}>Numérica</option>
              </select>
            </div>
          </div>
          <div className="row align-items-center d-flex py-2">
            <div className="col-lg-8 p-4">
              <TipoPregunta id={cues.id} tipoPregunta={cues.tipoPregunta} opciones={cues.opciones} index={index} />
            </div>
            <div className="col-lg-4 p-4"></div>
            <div className="col-lg-12 pb-4">
              <hr className="uc-hr" />
            </div>

            <div className="col-lg-12 justify-content-end d-flex">
              <i className="uc-icon my-auto mx-2" id={cues.id + "|addNew"} onClick={agregarPregunta}>
                add_circle_outline
              </i>
              <i className="uc-icon my-auto mx-2" id={cues.id + "|deleteNew"} onClick={eliminarPregunta}>
                delete_outline
              </i>
              <span className="px-4">|</span>
              <div className="form-check form-switch mx-2">
                <input name="is-requerida" id={"checkbox-" + cues.id} onChange={(e) => handlePreguntaReq(cues.id, e)} defaultChecked={cues.respuestaReq} type="checkbox" className="form-check-input" />
                <label className="form-check-label my-auto" htmlFor="is-requerida">
                  Obligatoria
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CuestionarioContext = React.createContext({});

  useEffect(() => {
    fetchCuestionarios();
  }, []);

  useEffect(() => {
    if (cuestionarios) {
      const cuestionarioOpciones = cuestionarios.map((option) => ({ value: option.codCuestionario, label: option.nombreCuestionario })); 
      console.log(cuestionarioOpciones);
      setCuestionarioOpciones(cuestionarioOpciones);
    }
  }, [cuestionarios]);

  useEffect(() => {
    if (selectedCuestionario) {
      setPreguntas(JSON.parse(selectedCuestionario.cuestionario));
      setNombreCuestionario(selectedCuestionario.nombreCuestionario);
      setIsDefault(selectedCuestionario.predeterminado === 's');

      const selectedCuestionarioOpciones = cuestionarioOpciones.find(cuestionarioOpcion => cuestionarioOpcion.value === selectedCuestionario.codCuestionario);
      setSelectedCuestionarioOpcion(selectedCuestionarioOpciones)
    } else {
      setNombreCuestionario("");
      setIsDefault(false);
      setSelectedCuestionarioOpcion(null);
    }
    
  }, [selectedCuestionario]);

  return (
    <ProyectosContainer>
      <div className="container-fluid">
        <SubTitle value="Cuestionario" />
        {
          cuestionarioOpciones.length !== 0 &&
          <div className="row uc-form-group" style={{ zIndex: 1000 }}>
              <div className="col-lg-12 mx-auto">
                <h4>Seleccionar cuestionario</h4>
                <SelectUC
                    name="cuestionarios"
                    options={cuestionarioOpciones}
                    onChange={(e) => selectCuestionario(e)}
                    placeholder="Seleccionar..."
                    value={selectedCuestionarioOpcion}
                />
              </div>
          </div>
        }
        <div className="row justify-content-between mb-24 align-items-center">
          <div className="col-md-auto">
            <ButtonUC className="uc-btn btn-primary " value="Nuevo cuestionario" onClick={nuevoCuestionario}>
              Nuevo Cuestionario
            </ButtonUC>
          </div>
          <div className="col-md-auto">
            <SaveButton />
          </div>
          <div className="col-md-auto">
            { preguntas.length !== 0 &&
              <div className="col-md-auto">
                <div className="form-check form-switch ml-4">
                  <input 
                    id="visible" 
                    name="visible"
                    type="checkbox" 
                    className="form-check-input"
                    checked={isDefault}
                    onChange={(e) => setIsDefault(e.target.checked)}
                  />
                  <label className="form-check-label my-auto" htmlFor="visible">
                  Predeterminado
                  </label>
                </div>
              </div>
            } 
        </div>
        </div>
        <div className="row mb-24 align-items-center">
          {
            preguntas.length !== 0 && 
            <div className="col-md-12">
              <input
                  id="nombre"
                  name="nombre"
                  type="text"
                  className="uc-input-style"
                  placeholder="Nombre del cuestionario"
                  value={nombreCuestionario}
                  onChange={(val) => setNombreCuestionario(val.target.value)}
              />
            </div>
          }
        </div>
        <CuestionarioContext.Provider value={{ useContext, preguntas, curQuiz, setCurQuiz }}>
          <Cuestionario preguntas={preguntas} curQuiz={curQuiz} />
        </CuestionarioContext.Provider>
        <div className="col-lg-12 d-flex justify-content-around py-4">
          <SaveButton />
        </div>
      </div>
    </ProyectosContainer>
  );
}

export default Cuestionario;
