import React, { useState } from 'react';
import ReportesService from '../../../services/ReportesService';
import AppContext from '../../../AppContext';
import SelectUC from '../../ui/SelectUC';
import ButtonUC from '../../ui/ButtonUC';

import { useForm, Controller } from 'react-hook-form';
import ResReporte from './ResReporte';

export default function Reportes() {
   const token = React.useContext(AppContext);
   const currentYear = new Date().getFullYear();

   const [state, setState] = React.useState({
      fechaDesde: 2010,
      fechaHasta: [],
      defHasta: {},
      proyectos: false,
      subProyectos: false,
   });
   const [reportes, setReportes] = React.useState({});
   const [onReport, setOnReport] = React.useState(false);

   // const validationSchema = formVoluntarioSchema;
   const {
      register,
      handleSubmit,
      control,
      setValue,
      reset,
      formState: { errors },
   } = useForm({
      mode: 'onBlur',
      // resolver: yupResolver(validationSchema),
      //    defaultValues: defaults
   });
   const onSubmit = async (data) => {
      try {
         data.beginYear = startYear;
         data.endYear = endYear;
         data.listProyectos = project;
         data.listSubProyectos = subProjects;
         console.log('datos', data);
         setLoading(true);
         const res = await ReportesService.getReporte(data, token);
         console.log('respuesta', res);

         res.porAreaEstudio = res.porAreaEstudio || [];
         res.porEstadoPago = res.porEstadoPago || [];
         res.porEstamento = res.porEstamento || [];
         res.porGenero = res.porGenero || [];
         res.porNacionalidad = res.porNacionalidad || [];
         res.porUC = res.porUC || [];
         setReportes(res);
         setOnReport(true);
         setLoading(false);
      } catch (err) {
         alert('Ha ocurrido algo');
         console.log(err);
      }
   };

   const anios = () => {
      let years = [];

      for (let i = currentYear; i >= 2010; i--) {
         years.push({ value: i, label: i });
      }
      return years;
   };

   const selectedYear = (e) => {
      setState({
         ...state,
         fechaHasta: [],
         defHasta: { value: '', label: '' },
      });
      let date = new Date().getFullYear();
      let years = [];

      for (let i = e.value + 1; i <= date; i++) {
         years.push({ value: i, label: i });
      }
      console.log('hasta', years);
      setState({
         ...state,
         fechaDesde: e.value,
         fechaHasta: years,
      });
   };

   const isCas = [
      { label: 'No Uc', value: 0 },
      { label: 'UC', value: 1 },
   ];

   const isChilean = [
      { label: 'Chileno', value: 0 },
      { label: 'Extranjero', value: 1 },
   ];

   const [startYear, setStartYear] = useState(currentYear - 1);
   const [endYear, setEndYear] = useState(currentYear);
   const [projects, setProjects] = useState([]);
   const [project, setProject] = useState([]);
   const [subProjects, setSubProject] = useState([]);

   const [loading, setLoading] = useState(false);
   React.useEffect(() => {
      const years = {
         begin: startYear,
         end: endYear,
      };
      ReportesService.findByYears(years, token).then((r) => {
         setProjects(r);
      });
   }, [startYear, endYear]);

   React.useEffect(() => {
      const subP = [];
      project.forEach((p) => {
         ReportesService.getProyecto(p.value, token).then((r) => {
            r?.respuesta?.forEach((el) => {
               subP.push({ value: el.id, label: el.nombre });
            });
         });
      });
      setSubProject(subP);
   }, [project]);

   if (loading) return <>Cargando datos...</>;
   if (!onReport) {
      return (
         <form onSubmit={handleSubmit(onSubmit)}>
            <h1 className="mb-24">Reportes</h1>
            <div className="row">
               <div className="col-md-6" style={{ zIndex: 110 }}>
                  <Controller
                     name="beginYear"
                     control={control}
                     render={({ field }) => (
                        <SelectUC
                           label="Desde"
                           defaults={{ value: startYear, label: startYear }}
                           options={anios()}
                           {...field}
                           onChange={(val) => {
                              setStartYear(val.value);
                           }}
                        />
                     )}
                  />
               </div>
               <div className="col-md-6" style={{ zIndex: 98 }}>
                  <Controller
                     name="endYear"
                     control={control}
                     render={({ field }) => (
                        <SelectUC
                           label="Hasta"
                           defaults={{ value: endYear, label: endYear }}
                           options={anios()}
                           {...field}
                           onChange={(val) => {
                              setEndYear(val.value);
                           }}
                        />
                     )}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-md-6">
                  <Controller
                     name="listProyectos"
                     control={control}
                     render={({ field }) => (
                        <SelectUC
                           label="Proyectos"
                           options={projects}
                           isMulti
                           {...field}
                           onChange={(val) => {
                              setProject(val);
                           }}
                        />
                     )}
                  />
               </div>
               <div className="col-md-6">
                  <Controller
                     name="listSubProyectos"
                     control={control}
                     render={({ field }) => <SelectUC label="Sub-Proyectos" options={subProjects} isMulti {...field} />}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-md-6">
                  <Controller
                     name="listEtiquetas"
                     control={control}
                     render={({ field }) => <SelectUC label="Etiquetas" dominio="etiquetas" isMulti {...field} />}
                  />
               </div>
               <div className="col-md-6">
                  <Controller
                     name="listGeneros"
                     control={control}
                     render={({ field }) => <SelectUC label="Géneros" dominio="genero" isMulti {...field} />}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-md-6">
                  <Controller
                     name="listUC"
                     control={control}
                     render={({ field }) => <SelectUC label="UC o no UC" options={isCas} isMulti {...field} />}
                  />
               </div>
               <div className="col-md-6">
                  <Controller
                     name="listNacionalidad"
                     control={control}
                     render={({ field }) => <SelectUC label="Nacionalidad" options={isChilean} isMulti {...field} />}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-md-6">
                  <Controller
                     name="listEstadoPago"
                     control={control}
                     render={({ field }) => <SelectUC label="Estado Pago" dominio="estados_pago" isMulti {...field} />}
                  />
               </div>
               <div className="col-md-6">
                  <Controller
                     name="listEstamentos"
                     control={control}
                     render={({ field }) => <SelectUC label="Categorías" dominio="categorias" isMulti {...field} />}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-md-6">
                  <Controller
                     name="listAreaEstudio"
                     control={control}
                     render={({ field }) => <SelectUC label="Área de estudio" dominio="areas_estudio" isMulti {...field} />}
                  />
               </div>
               <div className="col-md-6 mt-32">
                  <ButtonUC
                     type="submit"
                     className="uc-btn btn-cta"
                     // onClick={findProyectos}
                  >
                     Continuar
                  </ButtonUC>
               </div>
            </div>
         </form>
      );
   } else {
      return (
         <div>
            <ResReporte reportes={reportes} />
         </div>
      );
   }
}
