import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const savePerfil = (data, token) => {
   return axios
      .post(`${API_URL}perfil`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getPerfiles = (token) => {
   return axios
      .get(`${API_URL}perfiles`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getPerfil = (id, token) => {
   return axios
      .get(`${API_URL}getPerfil/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getUserPerfil = (id, token) => {
   return axios
      .get(`${API_URL}getPerfilUser/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data.body)
      .catch((err) => err.response.data);
};

const updatePerfilUsuario = (data, token) => {
   return axios
      .patch(`${API_URL}updatePerfilUsuario/`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const permisoHandler = (user, modulo) => {
   let permiso = false;
   user.permisos.forEach((p) => {
      if (p.funcion === modulo && p.permiso) {
         permiso = true;
      }
   });
   if (!permiso) {
      //window.history.pushState({}, document.title, "/denegado" );
      // window.location.href = process.env.REACT_APP_FRONT_URL + "denegado";
   }
   return permiso;
};

const permisosHandler = (user) => {
   const permisos = {
      voluntario: permisoHandler(user, 'Voluntarios'),
      proyecto: permisoHandler(user, 'Proyectos'),
      reporte: permisoHandler(user, 'Reportes'),
      dominios: permisoHandler(user, 'Dominios'),
      perfil: permisoHandler(user, 'Perfil'),
      exportarDatos: permisoHandler(user, 'Exportar datos'),
   };

   return permisos;
};

const deletePerfil = (data, token) => {
   return axios
      .delete(`${API_URL}deletePerfil/`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         data: data,
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const PerfilService = {
   savePerfil,
   getPerfiles,
   getPerfil,
   permisoHandler,
   permisosHandler,
   getUserPerfil,
   updatePerfilUsuario,
   deletePerfil,
};

export default PerfilService;
