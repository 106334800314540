import React from "react";
import SubTitle from "../ui/SubTitle";
import { SelectUC } from "../ui/SelectUC";
import { InputUC } from "../ui/InputUC";

function Filtros(props) {
  return (
    <div className="row d-flex justify-content-center">
      <div className="col-lg-12">
        <SubTitle value="Filtros" />
      </div>

      <div className="col-lg" style={{ zIndex: 100 }}>
        <SelectUC label="Categoría" dominio="categorias" onChange={(e) => props.handleCategoria(e)} isMulti />
      </div>
      <div className="col-lg" style={{ zIndex: 97 }}>
        <SelectUC label="Etiquetas" dominio="etiquetas" onChange={(e) => props.handleEtiquetas(e)} closeMenuOnSelect={false} isMulti />
      </div>
      <div className="col-lg ">
        <InputUC id="ucsearch" type="text" label="Buscar por nombre" className="uc-input-style w-icon search" placeholder="Enter para buscar" onKeyDown={(e) => props.handleSearchString(e)} title="Enter para buscar" />
        <span className="w-icon search"></span>
      </div>
    </div>
  );
}

export default Filtros;
