import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";

function PagoWebPay({ status, onAdmin }) {
  const search = useLocation().search;
  const data = new URLSearchParams(search).get("data");
  let respuesta = onAdmin ? status : JSON.parse(atob(data));

  return (
    <div>
      <div>
        {respuesta.ordenCompra && (
          <div>
            <div>
              <strong>Proyecto:</strong> {respuesta.nombreProyecto}
            </div>
            <div>
              <strong>Subproyecto:</strong> {respuesta.nombreSubproyecto}
            </div>
            <div>
              <strong>Orden de compra:</strong> {respuesta.ordenCompra}
            </div>
            <div>
              <strong>Estado:</strong> {respuesta.estado}
            </div>
            <div>
              <strong>Monto:</strong> ${respuesta.monto}
            </div>
            <div>
              <strong>Tarjeta:</strong> ******{respuesta.tarjeta}
            </div>
            <div>
              <strong>Código de autorización:</strong> {respuesta.codAutorizacion}
            </div>
            <div>
              <strong>Fecha transacción:</strong> {moment(respuesta.fechaTransaccion).format("YYYY-MM-DD")}
            </div>
            <div>
              <strong>Tipo pago:</strong> {respuesta.tipoPago}
            </div>
            <div>
              <strong>Monto cuota:</strong> ${respuesta.cuota} CLP
            </div>
            <div>
              <strong>Número de cuotas:</strong> {respuesta.numCuotas}
            </div>
          </div>
        )}
        {respuesta.code === 0 && <div>The transactions's date has passed max time (7 days) to recover the status</div>}
      </div>
    </div>
  );
}

export default PagoWebPay;
