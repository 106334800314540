import React, { useContext, useEffect, useState } from "react";
import InscripService from "../../services/InscripService";
import SubProyectoService from "../../services/SubProyectosService";

import PagarModal from "./PagarModal";
import UserContext from "../../UserContext";
import AppContext from "../../AppContext";
import moment from "moment";
import "moment/locale/es";

import Mensaje from "./Mensaje";
import MisActividades from "./MisActividades";

function MisProyectosComponent() {
  const user = useContext(UserContext);
  const token = useContext(AppContext);

  const [proyectos, setProyectos] = useState([]);
  const [actividadModal, setActividadModal] = useState(false);
  const [mensajeModal, setMensajeModal] = useState(false);
  const [curPro, setCurPro] = useState({});
  const [curSub, setCurSub] = useState({});
  const [hayProyectos, setHayProyectos] = useState(false);
  const [pagarModal, setPagarModal] = useState(false);
  moment.locale("es");

  const actualizarMisProyectos = () => {
    InscripService.getMisProyectos(user.codUsuario, token)
      .then((res) => {
        if (res.respuesta.length > 0) {
          setHayProyectos(true);
          setProyectos(res.respuesta);
        }
      })
      .catch((err) => console.log(err));
  };

  const mensaje = (p) => {
    setCurSub(p);
    setMensajeModal(true);
  };

  const pagar = async (p) => {
    // const res = await SubProyectoService.get(p.codSubProyecto, token);
    setCurPro(p);
    setPagarModal(true);
  };

  const viewProyectos = (
    <MisActividades
      //activ={p.listActiv}
      actividadModal={actividadModal}
      setActividadModal={setActividadModal}
      codUsuario={user.codUsuario}
      token={token}
      pagar={pagar}
      proyectos={proyectos}
      setProyectos={setProyectos}
      mensaje={mensaje}
      user={user}
      actualizarMisProyectos={actualizarMisProyectos}
    />
  );

  useEffect(() => {
    actualizarMisProyectos();
    InscripService.getNotificaciones(user.codUsuario, token).then((r) => console.log("noti", r));

    // InscripService.getMisProyectos(user.codUsuario, token)
    // .then(res => {setProyectos(res.respuesta); console.log(res)})
    // .catch(err => console.log(err));
  }, []);

  return (
    <div>
      {hayProyectos ? viewProyectos : "No ha postulado a ningún proyecto"}
      {mensajeModal && <Mensaje curSub={curSub} setMensajeModal={setMensajeModal} user={user} token={token} />}
      {pagarModal && <PagarModal setPagarModal={setPagarModal} curPro={curPro} />}
    </div>
  );
}

export default MisProyectosComponent;
