import { Link } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { routeConfig } from '../../routes/BackofficeRoutes';

const UcBreadcrumbs = () => {

    const breadcrumbs = useBreadcrumbs(routeConfig)
    const length = breadcrumbs.length - 1

    return (
        <div className='container-fluid'>
            <ol className="uc-breadcrumb container my-20">
                {breadcrumbs.map(({ match, breadcrumb }, index, breadcrumbs) =>


                    <li className={`uc-breadcrumb_item ${index === length ? 'current' : ''}`} key={match.url}>
                        <Link to={match.url}>
                            {breadcrumb}
                        </Link>
                        {index !== length &&
                            <i className="uc-icon">keyboard_arrow_right</i>
                        }
                    </li>


                )}
            </ol>
        </div>
    )
}

export default UcBreadcrumbs;

