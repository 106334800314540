import React from "react";
import { Link } from "react-router-dom";

function Card({ proyecto, page, imagen }) {
  let styleImg = {
    height: "300px",
    width: "100%",
    objectFit: "cover",
  };
  return (
    <div className="uc-card mx-auto card-height--same">
      <img src={imagen} className="card-img img-fluid" alt="imagen de card" style={styleImg} />
      <div className="uc-card_body">
        <h4>{proyecto.nomproyecto}</h4>
        <div className="uc-text-divider divider-primary my-12" />
        <p className="mb-32">{proyecto.descrip}</p>
        <Link
          to={`${page}/inscripcion/${proyecto.codproyecto}`}
          className="uc-btn btn-primary mt-auto justify-content-between"
          style={{ maxWidth: "100% !important", minWidth: "100px" }}
        >
          Inscríbete
          <i className="uc-icon icon-shape--rounded">arrow_forward</i>
        </Link>
      </div>
    </div>
  );
}

export default Card;
