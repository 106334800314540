import { NavLink, Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UserContext from '../../UserContext';
import { useContext, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

export default function Header() {
   const user = useContext(UserContext);
   const history = useHistory();
   // useEffect(() => {
   //   // us.permisos = JSON.parse(response.data.perfil.permisos);
   //   // const perm = PerfilService.permisosHandler(user);
   //   //console.log(user);
   // }, []);

   const [dropOpen1, setDropOpen1] = useState(false);
   const toggle1 = () => {
      setDropOpen1(!dropOpen1);
   };
   const [dropOpen2, setDropOpen2] = useState(false);
   const toggle2 = () => {
      setDropOpen2(!dropOpen2);
   };
   const [dropOpen3, setDropOpen3] = useState(false);
   const toggle3 = () => {
      setDropOpen3(!dropOpen3);
   };

   return (
      <header className="uc-header">
         <Helmet>
            <script src="https://kit-digital-uc-prod.s3.amazonaws.com/uc-kitdigital/js/uc-kitdigital.js" type="text/javascript" />
         </Helmet>
         {/* Tabs mobile se muestran como Select */}
         <div className="uc-card card-bg--gray card-radius--none card-border--none d-block d-lg-none mb-32">
            <div className="uc-card_body">
               <label htmlFor="tabSelect">
                  <strong>Navegación...</strong>
               </label>
               <select
                  name="tabSelect"
                  id="tabSelect"
                  className="uc-input-style"
                  data-tabselect
                  onChange={(val) => {
                     let link = '';
                     switch (val.target.value) {
                        case 'tab-01':
                           link = '/proyectos';
                           break;
                        case 'tab-02':
                           link = '/catalogo/1';
                           break;
                        case 'tab-03':
                           link = '/mis-proyectos';
                           break;
                        case 'tab-04':
                           link = '/jefaturas';
                           break;
                        case 'tab-05':
                           link = `/historial?usr=${user.codUsuario}`;
                           break;
                        case 'tab-06':
                           link = '/reportes';
                           break;
                        case 'tab-07':
                           link = '/reportes-pagos';
                           break;
                        case 'tab-07b':
                           link = '/reportes-voluntarios';
                           break;
                        case 'tab-08':
                           link = '/perfiles';
                           break;
                        case 'tab-09':
                           link = '/dominios';
                           break;
                        case 'tab-10':
                           link = '/voluntarios';
                           break;
                        default:
                           break;
                     }
                     history.push(link);
                  }}
               >
                  {user.permiso.proyecto && <option value="tab-01">Gestionar Proyectos</option>}
                  <option value="tab-02">Catálogo</option>
                  <option value="tab-03">Mis proyectos</option>
                  <option value="tab-04">Jefaturas</option>
                  <option value="tab-05">Mi Historial</option>
                  {user.permiso.reporte && <option value="tab-06">Reporte General</option>}
                  {user.permiso.reporte && <option value="tab-07">Reporte de Pagos</option>}
                  {user.permiso.reporte && <option value="tab-07b">Reporte de Voluntarios</option>}
                  {user.permiso.perfil && <option value="tab-08">Administrar Perfiles</option>}
                  {user.permiso.dominios && <option value="tab-09">Administrar Dominios</option>}
                  {user.permiso.voluntario && <option value="tab-10">Administrar Voluntarios</option>}
               </select>
            </div>
         </div>
         <nav className="uc-navbar">
            {/* Menú para versión Escritorio */}
            <div className="container d-none d-lg-block">
               <div className="row align-items-center">
                  <div className="col-lg-3 col-xl-2">
                     <Link to="/">
                        <img
                           src="https://kit-digital-uc-prod.s3.amazonaws.com/assets/logo-uc-color.svg"
                           alt="Logo de Facultad"
                           className="img-fluid"
                        />
                     </Link>
                  </div>
                  <div className="col-lg-8 col-xl-9">
                     <div className="h2 text-font--serif text-color--blue mt-24">ACUTIS</div>
                     <div className="text-color--gray p-size--lg">Pastoral UC</div>
                  </div>
               </div>
               {/* Menú Backoffice */}
               <ul className="uc-navbar_nav">
                  <li className="nav-item" style={{ zIndex: 999 }}>
                     <Dropdown toggle={toggle1} isOpen={dropOpen1}>
                        <DropdownToggle data-toggle="dropdown" nav className="uc-btn btn-inline dropbtn">
                           <p style={{ color: '#173F8A' }}>
                              Proyectos<i className="uc-icon">arrow_drop_down</i>
                           </p>
                        </DropdownToggle>
                        <DropdownMenu end>
                           <div className="uc-dropdown_list-item">
                              {user.permiso.proyecto && (
                                 <NavLink to="/proyectos" className="uc-btn btn-inline" onClick={toggle1}>
                                    Gestionar Proyectos
                                 </NavLink>
                              )}
                           </div>
                           <div className="uc-dropdown_list-item">
                              <NavLink to="/catalogo/1" className="uc-btn btn-inline" onClick={toggle1}>
                                 Catálogo
                              </NavLink>
                           </div>
                           <div className="uc-dropdown_list-item">
                              <NavLink to="/mis-proyectos" className="uc-btn btn-inline" onClick={toggle1}>
                                 Mis proyectos
                              </NavLink>
                           </div>
                           <div className="uc-dropdown_list-item">
                              <NavLink to="/jefaturas" className="uc-btn btn-inline" onClick={toggle1}>
                                 Jefaturas
                              </NavLink>
                           </div>
                           <div className="uc-dropdown_list-item">
                              <NavLink to={`/historial?usr=${user.codUsuario}`} className="uc-btn btn-inline">
                                 Mi Historial
                              </NavLink>
                           </div>
                        </DropdownMenu>
                     </Dropdown>
                  </li>
                  <li className="nav-item uc-navbar-dropdown">
                     {user.permiso.reporte && (
                        <Dropdown toggle={toggle2} isOpen={dropOpen2}>
                           <DropdownToggle data-toggle="dropdown" nav className="uc-btn btn-inline dropbtn">
                              <p style={{ color: '#173F8A' }}>
                                 Reportes<i className="uc-icon">arrow_drop_down</i>
                              </p>
                           </DropdownToggle>
                           <DropdownMenu end>
                              <div className="uc-dropdown_list-item">
                                 <NavLink to="/reportes" className="uc-btn btn-inline" onClick={toggle2}>
                                    General
                                 </NavLink>
                              </div>
                              <div className="uc-dropdown_list-item">
                                 <NavLink to="/reportes-pagos" className="uc-btn btn-inline" onClick={toggle2}>
                                    Pagos
                                 </NavLink>
                              </div>
                              <div className="uc-dropdown_list-item">
                                 <NavLink to="/reportes-voluntarios" className="uc-btn btn-inline" onClick={toggle2}>
                                    Voluntarios
                                 </NavLink>
                              </div>
                           </DropdownMenu>
                        </Dropdown>
                     )}
                  </li>
                  {(!!user.permiso.perfil || !!user.permiso.dominio || !!user.permiso.voluntario) && (
                     <li>
                        <Dropdown toggle={toggle3} isOpen={dropOpen3}>
                           <DropdownToggle data-toggle="dropdown" nav className="uc-btn btn-inline dropbtn">
                              <p style={{ color: '#173F8A' }}>
                                 Administración<i className="uc-icon">arrow_drop_down</i>
                              </p>
                           </DropdownToggle>
                           <DropdownMenu end>
                              <div className="uc-dropdown_list-item">
                                 {user.permiso.perfil && (
                                    <NavLink to="/perfiles" className="uc-btn btn-inline" style={{ color: '#173F8A' }} onClick={toggle3}>
                                       Perfiles
                                    </NavLink>
                                 )}
                              </div>
                              <div className="uc-dropdown_list-item">
                                 {user.permiso.dominios && (
                                    <NavLink to="/dominios" className="uc-btn btn-inline" style={{ color: '#173F8A' }} onClick={toggle3}>
                                       Dominios
                                    </NavLink>
                                 )}
                              </div>
                              <div className="uc-dropdown_list-item">
                                 {user.permiso.voluntario && (
                                    <NavLink to="/voluntarios" className="uc-btn btn-inline" style={{ color: '#173F8A' }} onClick={toggle3}>
                                       Voluntarios
                                    </NavLink>
                                 )}
                              </div>
                           </DropdownMenu>
                        </Dropdown>
                     </li>
                  )}
               </ul>
               {/* Menú Voluntarios */}
               {/* <ul className="uc-navbar_nav">
                        <li className="nav-item">
                            <NavLink to="/catalogo" className="uc-btn btn-inline">
                                Catálogo de proyectos
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/mis-proyectos" className="uc-btn btn-inline">
                                Mis Proyectos
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/historico" className="uc-btn btn-inline">
                                Histórico
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/nosotros" className="uc-btn btn-inline">
                                Nosotros
                            </NavLink>
                        </li>
                    </ul> */}
            </div>
         </nav>
      </header>
   );
}
