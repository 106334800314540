import 'bootstrap/dist/css/bootstrap.min.css';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from './../../services/AuthService';
function RecuperarContrasena() {
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [confPassword, setConfPassword] = useState('');
   const [valid, setValid] = useState(false);
   const search = useLocation().search;
   const tokenUrl = new URLSearchParams(search).get('tokenRecContra');
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      if (tokenUrl) {
         try {
            const tokenData = jwt_decode(tokenUrl);
            var current_time = Date.now() / 1000;
            if (tokenData.exp > current_time) {
               console.log('Válido');
               setValid(true);
            } else {
               alert('Caducó la recuperación de contraseña');
            }
         } catch (err) {
            alert('Datos inválidos');
         }
      }
   }, []);

   const sendEmail = async () => {
      try {
         setLoading(true);
         await AuthService.sendMail(email.toLowerCase()).then((r) => {
            alert(r.data.respuesta);
            setLoading(false);
         });
      } catch (err) {
         console.log(err.response?.data?.respuesta);
         alert(err?.response?.data?.respuesta || 'Puede que el usuario no exista, comuniquese con el administrador del servicio');
         setLoading(false);
      }
   };

   const sendNewPass = async () => {
      const newPass = {
         token: tokenUrl,
         newPasswd: password,
         repeatPasswd: confPassword,
      };
      console.log(newPass);
      const response = await AuthService.sendNewPass(newPass);
      console.log('ASDF', response);
      if (response.errors) {
         alert(response.errors.repeatPasswd || response.errors.EqualPasswords || response.errors.repeatPasswd);
         return;
      }
      alert(response.respuesta || response.data?.respuesta);
      if (response.data) {
         window.location.href = process.env.REACT_APP_FRONT_URL;
      }
   };

   return (
      <div className="row justify-content-center">
         <div className="uc-card m-24 px-20 p-24 login-card" style={{ width: '600px' }}>
            <div className="uc-card_header">Recuperar contraseña</div>
            {!valid && (
               <div className="uc-form-group mt-24">
                  <label htmlFor="username">Ingresa tu correo</label>
                  <input
                     id="Correo"
                     type="text"
                     className="uc-input-style"
                     placeholder="voluntario@uc.cl"
                     name="username"
                     onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  />
                  <div className="d-flex justify-content-center py-12">
                     <button className="uc-btn btn-cta uppercase" type="submit" onClick={sendEmail} disabled={loading}>
                        {loading && <i color="warning" type="grow"></i>}
                        {!loading && <span>Enviar</span>}
                        {loading && <span>Enviando</span>}
                     </button>
                  </div>
               </div>
            )}
            {valid && (
               <div className="uc-form-group mt-24">
                  <label htmlFor="username">Ingresa tu nueva contraseña</label>
                  <input
                     id="Password"
                     type="password"
                     className="uc-input-style mb-24"
                     placeholder="************"
                     name="username"
                     onChange={(e) => setPassword(e.target.value)}
                  />
                  <label htmlFor="username">Repite tu nueva contraseña</label>
                  <input
                     id="repPassword"
                     type="password"
                     className="uc-input-style"
                     placeholder="************"
                     name="username"
                     onChange={(e) => setConfPassword(e.target.value)}
                  />
                  <div className="d-flex justify-content-center py-12">
                     <button className="uc-btn btn-cta uppercase" type="submit" onClick={sendNewPass}>
                        Enviar
                     </button>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
}

export default RecuperarContrasena;
