import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';

import ProyectosComponent from '../components/backoffice/proyectos/ProyectosComponent';

import Voluntarios from '../components/backoffice/voluntarios/VoluntariosComponent';
import CreateVoluntario from '../components/backoffice/voluntarios/CreateVoluntario';
import EditVoluntario from '../components/backoffice/voluntarios/EditVoluntario';
import InscripcionComponent from '../components/catalogo/InscripcionComponent';
import HistorialComponent from '../components/misproyectos/HistorialComponent';

import Reportes from '../components/backoffice/reportes/Reportes';
import Dominios from '../components/backoffice/dominios/DominiosComponent';

import Inicio from '../components/backoffice/Inicio';
import CloneProyecto from '../components/backoffice/proyectos/CloneProyecto';
import EditProyecto from '../components/backoffice/proyectos/EditProyecto';
import CreateProyecto from '../components/backoffice/proyectos/CreateProyecto';
import MisProyectosComponent from '../components/misproyectos/MisProyectosComponent';
import CatalogoComponent from '../components/catalogo/CatalogoComponent';
import ListaVoluntarios from '../components/backoffice/proyectos/verVoluntarios/ListaVoluntarios';
import VerVoluntario from '../components/backoffice/proyectos/verVoluntarios/VerVoluntario';
import PerfilComponent from '../components/backoffice/perfil/PerfilComponent';
import PagoWebPay from '../components/misproyectos/PagoWebPay';
import Denegado from '../components/Auth/Denegado';
import Notificaciones from '../components/backoffice/proyectos/verVoluntarios/Notificaciones';
import ReportesPagos from '../components/backoffice/reportes/ReportesPagos';
import RportesVoluntarios from '../components/backoffice/reportes/ReportesVoluntarios';
import MisJefaturas from '../components/misproyectos/MisJefaturas';

export default function BackofficeRoutes() {
   return (
      <div style={{ paddingBottom: '260px' }}>
         <div className="container px-20 p-24 my-20" style={{ minHeight: '50vh' }}>
            <Switch>
               <Route exact path="/" component={Inicio} name="Inicio" />
               <Route exact path={'/mi-cuenta'} component={EditVoluntario} />

               <Route exact path="/proyectos/" component={ProyectosComponent} />
               <Route exact path="/proyectos/crear/" component={CreateProyecto} />

               <Route exact path="/proyectos/editar/:idPro" component={EditProyecto} />
               <Route exact path="/proyectos/editar/:idPro?vol" component={EditProyecto} />
               <Route exact path="/proyectos/editar/:idPro/sub/:id/inscripciones" component={ListaVoluntarios} />
               <Route exact path="/proyectos/editar/:idPro/inscripciones" component={ListaVoluntarios} />
               <Route exact path="/proyectos/editar/:idPro/sub/:idSub/inscrito/:id" component={VerVoluntario} />
               <Route exact path="/proyectos/clonar/:idPro" component={CloneProyecto} />
               <Route exact path="/notificaciones" component={Notificaciones} />

               <Route exact path="/voluntarios" component={Voluntarios} />
               <Route exact path="/voluntarios/inscribir-voluntario" component={CreateVoluntario} />
               <Route exact path="/voluntarios/editar-voluntario/:id" component={EditVoluntario} />
               <Route exact path="/voluntarios/historial/:id/:nombre" component={HistorialComponent} />

               <Route exact path="/catalogo/:page" component={CatalogoComponent} />
               <Route exact path="/catalogo/:page/inscripcion/:codproyecto" component={InscripcionComponent} />

               <Route exact path="/mis-proyectos" component={MisProyectosComponent} />
               <Route exact path="/historial" component={HistorialComponent} />
               <Route exact path="/jefaturas" component={MisJefaturas} />
               <Route exact path="/pagoWebPay" component={PagoWebPay} />

               <Route exact path="/reportes" component={Reportes} />
               <Route exact path="/reportes-pagos" component={ReportesPagos} />
               <Route exact path="/reportes-voluntarios" component={RportesVoluntarios} />
               <Route exact path="/dominios" component={Dominios} />
               <Route exact path="/perfiles" component={PerfilComponent} />

               <Route exact path="/denegado" component={Denegado} />

               <Route exact path="*">
                  <Redirect to="/" />
               </Route>
            </Switch>
         </div>
      </div>
   );
}

export const routeConfig = [
   {
      path: '/',
      breadcrumb: 'Inicio',
      Component: Inicio,
   },

   // CRUMBS PROYECTOS
   {
      path: '/proyectos/',
      breadcrumb: 'Proyectos',
      Component: { ProyectosComponent },
   },
   {
      path: '/proyectos/crear/',
      breadcrumb: 'Crear nuevo proyecto',
      Component: { CreateProyecto },
   },
   {
      path: '/proyectos/editar',
      breadcrumb: null,
      Component: { EditProyecto },
   },
   {
      path: '/proyectos/editar/:id',
      breadcrumb: 'Editar proyecto',
      Component: { EditProyecto },
   },
   {
      path: '/proyectos/editar/:idPro/sub',
      breadcrumb: null,
      Component: { EditProyecto },
   },
   {
      path: '/proyectos/editar/:idPro/sub/:idSub',
      breadcrumb: null,
      Component: { EditProyecto },
   },
   {
      path: '/proyectos/editar/:idPro/sub/:idSub/inscripciones/',
      breadcrumb: 'Inscritos en subproyecto',
      Component: { ListaVoluntarios },
   },
   {
      path: '/proyectos/editar/:idPro/inscripciones/',
      breadcrumb: 'Inscritos en proyecto',
      Component: { ListaVoluntarios },
   },
   {
      path: '/proyectos/editar/:idPro/sub/:idSub/inscrito/',
      breadcrumb: null,
      Component: { VerVoluntario },
   },
   {
      path: '/proyectos/editar/:idPro/sub/:idSub/inscrito/:id',
      breadcrumb: 'Ficha inscrito',
      Component: { VerVoluntario },
   },

   // CRUMBS VOLUNTARIOS
   {
      path: '/voluntarios',
      breadcrumb: '',
      component: { Voluntarios },
   },
   {
      path: '/voluntarios/inscribir-voluntario',
      breadcrumb: 'Inscribir voluntario',
      component: { CreateVoluntario },
   },
   {
      path: '/voluntarios/editar-voluntario/:id',
      breadcrumb: null,
      component: { EditVoluntario },
   },
   {
      path: '/voluntarios/editar-voluntario/',
      breadcrumb: 'Editar voluntario',
      component: { EditVoluntario },
   },

   // CRUMBS CATALOGO
   {
      path: '/catalogo',
      breadcrumb: null,
      component: { CatalogoComponent },
      id: 1,
   },
   {
      path: '/catalogo/1',
      breadcrumb: 'Catálogo de proyectos',
      component: { CatalogoComponent },
      id: 1,
   },
   {
      path: '/catalogo/:page',
      breadcrumb: null,
      component: { CatalogoComponent },
      id: 1,
   },
   {
      path: '/catalogo/:page/inscripcion/:id',
      breadcrumb: 'Inscribir proyecto',
      component: { CatalogoComponent },
      id: 1,
   },
   {
      path: '/catalogo/:page/inscripcion/',
      breadcrumb: null,
      component: { CatalogoComponent },
      id: 1,
   },
   {
      path: '/catalogo/:page',
      breadcrumb: null,
      component: { CatalogoComponent },
   },

   // CRUMBS MIS PROYECTOS
   {
      path: '/mis-proyectos/',
      breadcrumb: 'Mis proyectos',
      component: { CatalogoComponent },
   },
   {
      path: '/voluntarios/historial/:id',
      breadcrumb: null,
      Component: { HistorialComponent },
   },

   // CRUMBS REPORTES
   {
      path: '/reportes',
      breadcrumb: '',
      component: { Reportes },
   },

   // CRUMBS DOMINIOS
   {
      path: '/dominios',
      breadcrumb: '',
      component: { Dominios },
   },
];
