import * as Yup from "yup";

const formVoluntarioSchema = Yup.object().shape({
  nombres: Yup.string().required("Este campo es requerido").trim().max(100, "Este campo no puede superar los 100 caracteres"),
  apaterno: Yup.string().required("Este campo es requerido").trim().max(100, "Este campo no puede superar los 100 caracteres"),
  amaterno: Yup.string().required("Este campo es requerido").trim().max(100, "Este campo no puede superar los 100 caracteres"),
  extranjero: Yup.object().required("Este campo es requerido"),
  isPasaporte: Yup.boolean(),
  rut: Yup.string()
    .nullable()
    .when("isPasaporte", { is: false, then: Yup.string().required("Este campo es requerido") }),
  pasaporte: Yup.string()
    .nullable()
    .when("isPasaporte", { is: true, then: Yup.string().required("Este campo es requerido") }),
  nacimiento: Yup.string().required("Este campo es requerido"),
  genero: Yup.object().required("Este campo es requerido"),
  region: Yup.object().required("Este campo es requerido"),
  comuna: Yup.object().required("Este campo es requerido"),
  telefono: Yup.number().required("Este campo es requerido").typeError("Este campo debe ser numerico").positive("Valores negativos no admitidos"),
  correo: Yup.string().required("Este campo es requerido").email("Formato de correo invalido").trim(),
  is_cas: Yup.bool(),
  editMode: Yup.bool(),
  contrasena: Yup.string()
    // .when('is_cas', { is: false, then: Yup.string().required('Este campo es requerido').min(8, 'La contrasena debe contener al menos 8 caracteres').max(15, 'La contrasena no puede superar los 15 caracteres') })
    .when("editMode", { is: false, then: Yup.string().required("Este campo es requerido").min(8, "La contraseña debe contener al menos 8 caracteres").max(15, "La contraseña no puede superar los 15 caracteres") }),
  confirmarcontrasena: Yup.string()
    .oneOf([Yup.ref("contrasena"), null], "Las contraseñas no coinciden")
    .when("editMode", {
      is: false,
      then: Yup.string()
        .required("Este campo es requerido")
        .oneOf([Yup.ref("contrasena"), null], "Las contraseñas no coinciden"),
    }),
  // .when('is_cas', { is: false, then: Yup.string().required('Este campo es requerido').oneOf([Yup.ref('contrasena'), null], 'Las contrasenas no coinciden'), }),
  categoria: Yup.object().required("Este campo es requerido").typeError("Este campo es requerido"),
  // areaestudio: Yup.object().required("Este campo es requerido").typeError("Este campo es requerido"),
  // institucion: Yup.object().required("Este campo es requerido").typeError("Este campo es requerido"),
  // otrainstitucion: Yup.string().required("Este campo es requerido").typeError("Este campo es requerido"),
  //  ingreso: Yup.number().required("Este campo es requerido").typeError("Este campo es requerido").positive("Valores negativos no admitidos"),
  newsletter: Yup.bool(),

  condiciones: Yup.bool().oneOf([true], "Debe aceptar los términos y condiciones"),
});

export default formVoluntarioSchema;
