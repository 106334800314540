import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../../AppContext";
import SelectUC from "./../../../ui/SelectUC";
import ButtonUC from "../../../ui/ButtonUC";
import ModalUC from "../../../ui/ModalUC";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { validateFieldsNatively } from "@hookform/resolvers";
import InscripService from "../../../../services/InscripService";
import InscripcionComponent from "../../../catalogo/InscripcionComponent";
function EditarInscripcion({ subproyectos, inscrip }) {
  const validationSchema = Yup.object().shape({
    codSubProyecto: Yup.array().typeError("Debe contener al menos una categoria"),
    codEstadoPago: Yup.array().typeError("Debe contener al menos una categoria"),
    estado: Yup.array().typeError("Debe contener al menos una categoria"),
  });
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    //  resolver: yupResolver(validationSchema),
    defaultValues: inscrip,
  });

  const token = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(0);
  const [formData, setFormData] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const editInscrip = async (data) => {
    setFormData(data);
    setConfirmModal(true);
  };
  const sendInscripcion = async () => {
    const data = formData;
    console.log("data", data);
    setConfirmModal(false);
    setLoading(true);
    const reqData = {
      codUsuarioInscrip: data.codUsuario.value,
      oldCodSubProyecto: inscrip.codSubProyecto.value,
      newCodSubProyecto: data.codSubProyecto.value,
      codEstadoPago: data.codEstadoPago.value,
      codUsuarioPagador: data.codUsuario.value,
      codEstadoInscrip: data.estado.value,
    };
    try {
      const res = await InscripService.updateInscripSubProyecto(reqData, token);
      if (res.realizado) {
        inscrip.codEstadoPago = data.codEstadoPago;
        inscrip.codSubProyecto = data.codSubProyecto;
        inscrip.codUsuario = data.codUsuario;
        inscrip.codUsuarioModif = data.codUsuarioModif;
        inscrip.codUsuarioPagador = data.codUsuarioPagador;
        inscrip.estado = data.estado;
        setMessage(1);
      } else setMessage(2);
    } catch (e) {
      setMessage(2);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("inscrip", inscrip);
    reset(inscrip);
    setLoading(false);
  }, [inscrip]);

  if (loading) return <>Actualizando datos...</>;
  return (
    <div>
      {confirmModal && (
        <ModalUC title={`Actualizar Voluntario`} setModal={setConfirmModal} setAlertType="success" style={{ maxWidth: "550px", zIndex: 200 }}>
          <div className="mb-24 p-24">
            <h4>¿Está seguro que desea modificar esta información? Se enviará un correo al voluntario indicando este cambio.</h4>
          </div>
          <div className="row">
            <div className="col-md-4">
              <button
                className="uc-btn btn-primary"
                onClick={() => {
                  sendInscripcion();
                }}
              >
                Actualizar Voluntario
              </button>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <button
                className="uc-btn btn-primary"
                onClick={() => {
                  setConfirmModal(false);
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </ModalUC>
      )}
      <form onSubmit={handleSubmit(editInscrip)}>
        <div className="row">
          <div className="col-lg-4">
            <Controller
              control={control}
              name="codSubProyecto"
              render={({ field }) => (
                <SelectUC
                  // name='subproyecto'
                  label="Subproyecto actual"
                  options={subproyectos}
                  placeholder="Seleccionar..."
                  {...field}
                />
              )}
            />
          </div>
          <div className="col-lg-4">
            <Controller
              control={control}
              name="codEstadoPago"
              render={({ field }) => (
                <SelectUC
                  label="Estado pago"
                  // style={{ zIndex: 148 }}
                  dominio="estados_pago"
                  {...field}
                />
              )}
            />
          </div>
          <div className="col-md-4">
            <Controller control={control} name="estado" render={({ field }) => <SelectUC label="Estado voluntario" dominio="estados_inscrip" {...field} />} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4"></div>
          <div className="col-lg-4">
            <ButtonUC children="Actualizar" type="submit" />
          </div>
        </div>
      </form>
      <div className="mt-4">
        {message === 1 && (
          <div className="uc-alert success mb-12">
            <div className="uc-alert_content">
              <i className="uc-icon icon-size--sm">check_circle</i>
              <span className="p p-size--sm bold ml-8">Información actualizada correctamente</span>
            </div>
            <div className="uc-alert_close">
              <i className="uc-icon icon-size--sm" onClick={() => setMessage(0)}>
                close
              </i>
            </div>
          </div>
        )}
        {message === 2 && (
          <div className="uc-alert error mb-12">
            <div className="uc-alert_content">
              <i className="uc-icon icon-size--sm">cancel</i>
              <span className="p p-size--sm bold ml-8">Error, no se ha podido actualizar la información, vuelve a intentarlo mas tarde</span>
            </div>
            <div className="uc-alert_close">
              <i className="uc-icon icon-size--sm" onClick={() => setMessage(0)}>
                close
              </i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditarInscripcion;
