import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import SubTitle from "../../../ui/SubTitle";
import InputUC from "../../../ui/InputUC";
import SelectUC from "../../../ui/SelectUC";
import { yupResolver } from "@hookform/resolvers/yup";
import restriccionesSchema from "../esquemas/restriccionesSchema";

function Restricciones({ onSubmit, defaults }) {
  const validationSchema = restriccionesSchema;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: defaults,
  });

  const handleMaxIntegrantes = (value) => {
    console.log(value);
    setValue("hasMaxIntegrantes", value);
    setValue("maxIntegrantes", undefined);
    clearErrors("maxIntegrantes");
    if (!value) {
      handleAreasEstudio(false);
      handleMaxEstudio(false);
      handleMaxGenero(false);
      handleEdadMin(false);
      handleEdadMax(false);
    }
  };
  const handleAreasEstudio = (value) => {
    setValue("hasAreasEstudio", value);
    setValue("idAreasEstudio", []);
    clearErrors("idAreasEstudio");
  };

  const handleMaxEstudio = (value) => {
    setValue("hasMaxEstudio", value);
    setValue("maxEstudio", undefined);
    clearErrors("maxEstudio");
  };

  const handleMaxGenero = (value) => {
    setValue("hasMaxGenero", value);
    setValue("maxGenero", undefined);
    clearErrors("maxGenero");
  };

  const handleEdadMin = (value) => {
    setValue("hasMinEdad", value);
    setValue("minEdad", undefined);
    clearErrors("minEdad");
  };

  const handleEdadMax = (value) => {
    setValue("hasMaxEdad", value);
    setValue("maxEdad", undefined);
    clearErrors("maxEdad");
  };

  // useEffect(() => {

  // }, []);

  useEffect(() => {
    reset(defaults);
    console.log("defaults", defaults);
  }, [defaults]);

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit(onSubmit)}>
        <SubTitle value="Restricciones generales" />
        <div className="row d-flex align-items-center">
          <div className="col-lg-12">
            <div className="row d-flex align-items-center py-4">
              <div className="col-lg-12">
                <div className="form-check pb-2">
                  <input className="form-check-input" type="checkbox" name="confir" id="confir" {...register("confir")} />
                  <label className="form-check-label" htmlFor="confir">
                    Todos los inscritos quedan en lista de espera y luego manualmente se les asigna subproyecto
                  </label>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-check pb-2">
                  <input className="form-check-input" type="checkbox" name="usuarioUC" id="usuarioUC" {...register("usuarioUC")} />
                  <label className="form-check-label" htmlFor="usuarioUC">
                    Permitir sólo voluntarios UC
                  </label>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <div className="form-check pb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="hasMaxIntegrantes"
                    id="hasMaxIntegrantes"
                    {...register("hasMaxIntegrantes")}
                    onChange={(v) => {
                      handleMaxIntegrantes(v.target.checked);
                    }}
                  />
                  <label className="form-check-label" htmlFor="hasMaxIntegrantes">
                    Número de vacantes por subproyecto
                  </label>
                </div>
              </div>

              <div className="col-lg-6">
                <InputUC name="maxIntegrantes" errores={errors.maxIntegrantes?.message} label="Ingresar máximo de vacantes" style={{ maxWidth: "150px" }} type="number" {...register("maxIntegrantes")} placeholder="Ingresar cantidad" disabled={!getValues("hasMaxIntegrantes")} />
              </div>
            </div>
          </div>
        </div>
        <SubTitle value="Limitaciones especificas" />
        <div style={{ display: !getValues("hasMaxIntegrantes") ? "block" : "none" }}>Necesitas limitar el numero de vacantes para habilitar las limitaciones especificas</div>
        <div style={{ display: getValues("hasMaxIntegrantes") ? "block" : "none" }}>
          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <div className="form-check pb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="hasAreasEstudio"
                  id="hasAreasEstudio"
                  {...register("hasAreasEstudio")}
                  onChange={(v) => {
                    handleAreasEstudio(v.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="hasAreasEstudio">
                  Limitar áreas de estudios permitidas
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <Controller
                name="idAreasEstudio"
                control={control}
                render={({ field }) => {
                  return <SelectUC dominio="areas_estudio" label="Areas permitidas" closeMenuOnSelect={false} isMulti errores={errors.idAreasEstudio?.message} isdisabled={!getValues("hasAreasEstudio")} placeholder="Seleccionar areas de estudio..." {...field} />;
                }}
              />
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <div className="form-check pb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="hasMaxEstudio"
                  id="hasMaxEstudio"
                  {...register("hasMaxEstudio")}
                  onChange={(v) => {
                    handleMaxEstudio(v.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="hasMaxEstudio">
                  Limitar porcentaje de voluntarios según área de estudio
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <InputUC name="maxEstudio" errores={errors.maxEstudio?.message} label="Especificar porcentaje" placeholder="Especificar porcentaje" style={{ maxWidth: "150px" }} disabled={!getValues("hasMaxEstudio")} {...register("maxEstudio")} />
            </div>
          </div>

          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <div className="form-check pb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="hasMaxGenero"
                  id="hasMaxGenero"
                  {...register("hasMaxGenero")}
                  onChange={(v) => {
                    handleMaxGenero(v.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="hasMaxGenero">
                  Limitar porcentaje de voluntarios según género
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <InputUC name="maxGenero" errores={errors.maxGenero?.message} label="Especificar porcentaje" placeholder="Especificar porcentaje" style={{ maxWidth: "150px" }} disabled={!getValues("hasMaxGenero")} {...register("maxGenero")} />
            </div>
          </div>

          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <div className="form-check pb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="hasMinEdad"
                  id="hasMinEdad"
                  {...register("hasMinEdad")}
                  onChange={(v) => {
                    handleEdadMin(v.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="hasMinEdad">
                  Limitar por edad mínima
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <InputUC name="minEdad" errores={errors.minEdad?.message} label="Edad minima requerida" placeholder="Ingresar años" style={{ maxWidth: "150px" }} disabled={!getValues("hasMinEdad")} {...register("minEdad")} />
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <div className="form-check pb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="hasMaxEdad"
                  id="hasMaxEdad"
                  {...register("hasMaxEdad")}
                  onChange={(v) => {
                    handleEdadMax(v.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="hasMaxEdad">
                  Limitar por edad máxima
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <InputUC name="maxEdad" errores={errors.maxEdad?.message} label="Edad maxima requerida" placeholder="Ingresar años" style={{ maxWidth: "150px" }} disabled={!getValues("hasMaxEdad")} {...register("maxEdad")} />
            </div>
          </div>
        </div>
        {/* <SubTitle value='Fechas' /> */}
        <div className="col-lg-12 d-flex justify-content-around py-4">
          <input type="submit" className="uc-btn btn-cta" style={{ maxWidth: "250px" }} value="Guardar" />
        </div>
      </form>
    </div>
  );
}

export default Restricciones;
