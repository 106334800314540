import React, { useState, useEffect } from "react";

import BackofficeRoutes from "./routes/BackofficeRoutes";
import VoluntariosRoutes from "./routes/VoluntariosRoutes";
import { useLocation } from "react-router-dom";
import Header from "./components/ui/Header";
import HeaderVoluntario from "./components/ui/HeaderVoluntario";
import UcBreadcrumbs from "./components/ui/Breadcrumbs";
import Footer from "./components/ui/Footer";
import Topbar from "./components/ui/Topbar";
import jwt_decode from "jwt-decode";
import UserService from "./services/UserService";
import "./assets/bootstrap/css/bootstrap.min.css";
import AuthRoutes from "./routes/AuthRoutes";
import AppContext from "./AppContext";
import UserContext from "./UserContext";
import PerfilService from "./services/PerfilService";

function App() {
  const [token, setToken] = useState({});

  const search = useLocation().search;
  const tokenUrl = new URLSearchParams(search).get("token");
  const [user, setUser] = useState({});
  const [auth, setAuth] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const tokenString = localStorage.getItem("token");

    if (tokenString != null) {
      saveToken(tokenString);
    } else {
      if (tokenUrl != null) {
        saveToken(tokenUrl);
        window.history.pushState({}, document.title, "/");
      }
    }
    setLoad(true);
  }, [auth]);

  const saveToken = (userToken) => {
    const userString = localStorage.getItem("voluntario");
    localStorage.clear();
    let tokenData;
    try {
      tokenData = jwt_decode(userToken);
      var current_time = Date.now() / 1000;
      if (tokenData.exp > current_time) {
        localStorage.setItem("token", userToken);
        setToken(userToken);

        if (userString == null) {
          UserService.fetchUser(tokenData.sub, userToken)
            .then((response) => {
              let us = response.data.respuesta;
              us.permisos = JSON.parse(response.data.perfil.permisos);
              us.permiso = PerfilService.permisosHandler(us);
              console.log("Auth", us);
              localStorage.setItem("voluntario", JSON.stringify(us));
              setUser(us);
              setAuth(true);
            })
            // .then(setAuth(true))
            .catch((error) => console.log(error));
        } else {
          setUser(JSON.parse(userString));
          localStorage.setItem("voluntario", userString);
          setAuth(true);
        }
      } else {
        alert("sesión expirada");
      }
    } catch (err) {
      alert("error");
      console.log(err);
    }
  };

  function checkValidToken() {
    var current_time = Date.now() / 1000;
    if (token.exp < current_time) {
      alert("sesión expirada");
      localStorage.clear();
      setAuth(false);
    }
  }
  if (!auth) {
    clearInterval(checkValidToken);
    return !load ? "loading..." : <AuthRoutes saveToken={saveToken} />;
  } else {
    setInterval(checkValidToken, 60000);
  }

  return !load && auth ? (
    "loading..."
  ) : (
    <div>
      <AppContext.Provider value={token}>
        <UserContext.Provider value={user}>
          {user.codPerfil !== 2 && (
            <>
              <Topbar setAuth={setAuth} user={user.user} isLoged />
              <Header />
              <UcBreadcrumbs />
              <BackofficeRoutes />
            </>
          )}
          {user.codPerfil === 2 && (
            <>
              <Topbar setAuth={setAuth} user={user.user} isLoged />
              <HeaderVoluntario />
              <VoluntariosRoutes />
            </>
          )}
          <Footer />
        </UserContext.Provider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
