import React, { useContext } from "react";
import { useParams, useLocation } from "react-router";
import AppContext from "../../AppContext";
import UserContext from "../../UserContext";
import Historial from "../backoffice/proyectos/verVoluntarios/Historial";

export default function HistorialComponent() {
  const token = useContext(AppContext);
  const user = useContext(UserContext);
  const { id, nombre } = useParams();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const usr = query.get("usr");

  return (
    <div>
      <Historial codUser={usr ? user.codUsuario : id} token={token} nombre={nombre} />
    </div>
  );
}
