import React from "react";
import { emailPastoral } from "../../properties";

function Inicio() {
  return (
    <div className="container px-20 p-24 my-28">
      <h1>Bienvenido a la plataforma de Voluntariados UC!</h1>
      <div className="uc-text-divider divider-primary my-12"></div>
      <div className="row container justify-content-center" style={{ maxWidth: "800px" }}>
        <p className="lead ">Ha ingresado correctamente al sistema de gestión.</p>
        <hr className="my-4" />
        <p className="lead">
          A continuación podrá acceder a todas las funcionalidades administrativas para actividades y voluntarios. Si tiene alguna duda, por favor conctáctenos al correo{" "}
          <a href={"mailto:" + emailPastoral} className="link-inline">
            {emailPastoral}
          </a>{" "}
          para que podamos revisar su situación.
        </p>
      </div>
    </div>
  );
}

export default Inicio;
