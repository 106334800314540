import React from 'react'

import SubTitle from '../ui/SubTitle'
import Card from './Card'

function Resultados({ catalogo, page }) {
    return (
        <div className='row'>
            <SubTitle value='Resultados' />
            {catalogo.length !== 0 &&
                <p className='p-size--sm pb-2'>{catalogo.length} {catalogo.length < 2 ? ` coincidencia` : 'coincidencias'}
                </p>}

            <div className='row'>
            {catalogo && catalogo.map(proyecto =>
                <div className='col-lg-4 mb-32'
                    key={proyecto.codproyecto}
                >
                    <Card
                        proyecto={proyecto}
                        page={page}
                        imagen={proyecto.logobase64 ? proyecto.logobase64 : '/catalogo_thumbnail.png'}
                    />
                </div>

            )}
            </div>
            {catalogo.length < 1 && <h2>Sin resultados</h2>}
        </div>
    )
}

export default Resultados
