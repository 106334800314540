import React from "react";

function ResCuestionario({ cuestionario, setCuestionario, edit }) {
  const handleAnswer = (r) => {
    let idRes = r.target.id.split("|");
    let indexRes = cuestionario.findIndex((res) => res.id == idRes[0]);
    cuestionario[indexRes].respuesta = r.target.value;
    let aux = [...cuestionario];
    setCuestionario(aux);
  };

  const getBase64 = (r) => {
    let idRes = r.target.id.split("|");
    let indexRes = cuestionario.findIndex((res) => res.id == idRes[0]);
    cuestionario[indexRes].respuesta = r.target.files[0].name;
    var file = r.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cuestionario[indexRes].file = reader.result;
    };
    let aux = [...cuestionario];
    setCuestionario(aux);
  };

  const handleMultipleAnswer = (r) => {
    let action = r.target.checked;
    let idRes = r.target.id.split("|");
    let indexRes = cuestionario.findIndex((res) => res.id == idRes[0]);
    //cuestionario[indexRes].respuesta = r.target.value;
    if (cuestionario[indexRes].tipoPregunta === "alternativa") {
      cuestionario[indexRes].opciones.forEach((e) => (e.checked = false));
      cuestionario[indexRes].opciones[idRes[2]].checked = true;
    } else {
      cuestionario[indexRes].opciones.forEach((e) => {
        if (cuestionario[indexRes].opciones[idRes[2]].alternativa == e.alternativa) {
          e.checked = action;
        }
      });
    }
    let aux = [...cuestionario];
    setCuestionario(aux);
  };
  const deleteFile = (index) => {
    console.log(cuestionario[index]);
    cuestionario[index].respuesta = null;
    cuestionario[index].file = null;
    let aux = [...cuestionario];
    setCuestionario(aux);
  };

  const TipoPregunta = ({ tipoPregunta, index, id, respuesta, file }) => {
    if (tipoPregunta === "abierta") {
      return <textarea className="uc-input-style my-2" id={id + "|abierta"} placeholder="Escriba aquí su respuesta" onBlur={handleAnswer} defaultValue={respuesta ? respuesta : ""} readOnly={edit}></textarea>;
    } else if (tipoPregunta === "alternativa" || tipoPregunta === "seleccionMultiple") {
      const options = cuestionario[index].opciones.map((opc, index) => (
        <div className="d-flex" key={index}>
          <div className="col-lg-auto my-auto">{String.fromCharCode(index + 97)}.</div>
          <div className="col-lg-12 my-2">
            <input type={tipoPregunta === "alternativa" ? "radio" : "checkbox"} name={id + "|" + tipoPregunta} id={id + "|alternativaExcluyente|" + index} style={{ fontWeight: "normal" }} onClick={handleMultipleAnswer} value={opc.alternativa} defaultChecked={opc.checked} disabled={edit} /> {opc.alternativa}
          </div>
        </div>
      ));
      return <div className="my-2">{options}</div>;
    } else if (tipoPregunta === "subirArchivo") {
      if (respuesta) {
        return !edit ? (
          <div>
            {respuesta}
            <i className="uc-icon" onClick={() => deleteFile(index)}>
              delete
            </i>
          </div>
        ) : (
          <div>
            <a download={respuesta} href={file}>
              {respuesta}
            </a>
          </div>
        );
      } else {
        return <input name="example" className="uc-input-style mb-4" type="file" style={{ width: "350px" }} id={id + "|subirArchivo"} onChange={getBase64} readOnly={edit} />;
      }
    } else if (tipoPregunta === "fecha") {
      return <input type="date" className="uc-input-style mb-4" style={{ width: "350px" }} onBlur={handleAnswer} id={id + "|fecha"} defaultValue={respuesta ? respuesta : ""} readOnly={edit} />;
    } else if (tipoPregunta === "numerica") {
      return <input name="example-number" type="number" className="uc-input-style" placeholder="Campo de tipo numerico" style={{ width: "350px" }} onBlur={handleAnswer} id={id + "|numerica"} defaultValue={respuesta ? respuesta : ""} readOnly={edit} />;
    }
  };

  const cues = cuestionario.map((c, index) => (
    <div>
      <label>
        {index + 1}. {c.enunciado} {c.respuestaReq ? "*" : ""}
      </label>
      <TipoPregunta tipoPregunta={c.tipoPregunta} index={index} id={c.id} respuesta={c.respuesta} file={c.file} />
    </div>
  ));

  return cues;
}

export default ResCuestionario;
