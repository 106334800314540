import React from "react";
import Table from "../../ui/Table";
import SubTitle from "../../ui/SubTitle";
import TitleUC from "../../ui/TitleUC";
import { Button } from "reactstrap";
import * as XLSX from "xlsx";

function ResReporte({ reportes }) {
  const generateExcel = () => {
    const workbook = XLSX.utils.book_new();

    let sheet1 = XLSX.utils.table_to_sheet(document.getElementById("table_resumen_general"));
    XLSX.utils.book_append_sheet(workbook, sheet1, "Resumen general");

    sheet1 = XLSX.utils.table_to_sheet(document.getElementById("table_generos"));
    XLSX.utils.book_append_sheet(workbook, sheet1, "Total géneros");

    sheet1 = XLSX.utils.table_to_sheet(document.getElementById("table_pagos"));
    XLSX.utils.book_append_sheet(workbook, sheet1, "Estado de pago");

    sheet1 = XLSX.utils.table_to_sheet(document.getElementById("table_categorias"));
    XLSX.utils.book_append_sheet(workbook, sheet1, "Categorías");

    sheet1 = XLSX.utils.table_to_sheet(document.getElementById("table_nacionalidad"));
    XLSX.utils.book_append_sheet(workbook, sheet1, "Nacionalidad");

    sheet1 = XLSX.utils.table_to_sheet(document.getElementById("table_universidad"));
    XLSX.utils.book_append_sheet(workbook, sheet1, "Universidad");

    sheet1 = XLSX.utils.table_to_sheet(document.getElementById("table_areas_estudio"));
    XLSX.utils.book_append_sheet(workbook, sheet1, "Área de estudio");

    XLSX.writeFile(workbook, "Reporte.xlsx");
  };
  return (
    <div>
      <TitleUC value="Reportes" />
      <button className="uc-btn btn-cta" onClick={generateExcel}>
        Exportar a Excel
      </button>
      <SubTitle value="Resumen general" />
      {/* <div className="row mb-24">
        <div className="col-md-3">
          <strong>Total de proyectos</strong>
          <div>{reportes.totalProyectos ? reportes.totalProyectos : "No hay proyectos"}</div>
        </div>
        <div className="col-md-3">
          <strong>Total de subproyectos</strong>
          <div>{reportes.totalSubproyectos ? reportes.totalSubproyectos : "No hay subproyectos"}</div>
        </div>
        <div className="col-md-3">
          <strong>Total Inscritos</strong>
          <div>{reportes.totalInscritos ? reportes.totalInscritos : "No hay inscritos"}</div>
        </div>
        <div className="col-md-3">
          <strong>Total en lista de espera</strong>
          <div>{reportes.totalEspera ? reportes.totalEspera : "No hay espera"}</div>
        </div>
      </div> */}
      <div className="row mb-24">
        <Table
          id="table_resumen_general"
          columns={[
            { Header: "Total de proyectos", accessor: "tp" },
            { Header: "Total de subproyectos", accessor: "tsp" },
            { Header: "Total Inscritos", accessor: "ti" },
            { Header: "Total en lista de espera", accessor: "tle" },
          ]}
          data={[
            {
              tp: reportes.totalProyectos ? reportes.totalProyectos : "No hay proyectos",
              tsp: reportes.totalSubproyectos ? reportes.totalSubproyectos : "No hay subproyectos",
              ti: reportes.totalInscritos ? reportes.totalInscritos : "No hay inscritos",
              tle: reportes.totalEspera ? reportes.totalEspera : "No hay espera",
            },
          ]}
          pagination={false}
        />
      </div>
      <SubTitle value="Total géneros" />
      <div className="row mb-24">
        <Table
          id="table_generos"
          columns={[
            { Header: "Género", accessor: "nombre" },
            { Header: "Cantidad", accessor: "cantidad" },
            { Header: "Porcentaje", accessor: "porcentaje" },
          ]}
          data={reportes.porGenero}
          pagination={false}
        />
      </div>
      <SubTitle value="Estado de pago" />
      <div className="row mb-24">
        <Table
          id="table_pagos"
          columns={[
            { Header: "Estado", accessor: "nombre" },
            { Header: "Cantidad", accessor: "cantidad" },
            { Header: "Porcentaje", accessor: "porcentaje" },
          ]}
          data={reportes.porEstadoPago}
          pagination={false}
        />
      </div>

      <SubTitle value="Categorías" />
      <div className="row mb-24">
        <Table
          id="table_categorias"
          columns={[
            { Header: "Categoría", accessor: "nombre" },
            { Header: "Cantidad", accessor: "cantidad" },
            { Header: "Porcentaje", accessor: "porcentaje" },
          ]}
          data={reportes.porEstamento}
          pagination={false}
        />
      </div>
      <SubTitle value="Nacionalidad" />
      <div className="row mb-24">
        <Table
          id="table_nacionalidad"
          columns={[
            { Header: "Nacionalidad", accessor: "nombre" },
            { Header: "Cantidad", accessor: "cantidad" },
            { Header: "Porcentaje", accessor: "porcentaje" },
          ]}
          data={reportes.porNacionalidad}
          pagination={false}
        />
      </div>
      <SubTitle value="Universidad" />
      <div className="row mb-24">
        <Table
          id="table_universidad"
          columns={[
            { Header: "UC", accessor: "nombre" },
            { Header: "Cantidad", accessor: "cantidad" },
            { Header: "Porcentaje", accessor: "porcentaje" },
          ]}
          data={reportes.porUC}
          pagination={false}
        />
      </div>
      <SubTitle value="Área de estudio" />
      <div className="row mb-24">
        <Table
          id="table_areas_estudio"
          columns={[
            { Header: "Área", accessor: "nombre" },
            { Header: "Cantidad", accessor: "cantidad" },
            { Header: "Porcentaje", accessor: "porcentaje" },
          ]}
          data={reportes.porAreaEstudio}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default ResReporte;
