import React from "react";
import AppContext from "../../../AppContext";
import ReportesService from "../../../services/ReportesService";
import Table from "../../ui/Table";
import InputUc from "../../ui/InputUC";
import ButtonUC from "../../ui/ButtonUC";
import moment from "moment";

function ReportesPagos() {
  const token = React.useContext(AppContext);

  const [reportesPago, setReportesPago] = React.useState([]);
  const [curProyecto, setCurProyecto] = React.useState({});
  const [selectedProyecto, setSelectedProyecto] = React.useState(false);
  const [listProyect, setListProyect] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const buscarProyecto = async (e) => {
    let busqueda = e.target.value;

    if (busqueda.length > 2) {
      const res = await ReportesService.findProyecto(busqueda, token);
      setListProyect(res.respuesta);
    }
  };

  const selectProyecto = (p) => {
    let suma = 0;
    ReportesService.getReportePagos(p.value, token).then((r) => {
      setReportesPago(r);
      r.forEach((p) => {
        if (p.estado === "Autorizado") {
          suma += p.monto;
        }
      });
      setTotal(suma);
      setSelectedProyecto(true);
      setCurProyecto(p);
    });
  };

  const volver = () => {
    setListProyect([]);
    setSelectedProyecto(false);
  };

  const list = listProyect.map((p) => (
    <div key={p.value}>
      <a href="#" onClick={() => selectProyecto(p)}>
        ver
      </a>{" "}
      - <strong>{p.label}</strong>
    </div>
  ));

  const columns = React.useMemo(
    () => [
      {
        Header: "Orden Compra",
        accessor: "orden_compra",
      },
      {
        Header: "Nombre",
        accessor: "nom_usuario",
      },
      {
        Header: "Apellido",
        accessor: "apellido_paterno",
      },
      {
        Header: "Rut/Pasaporte",
        accessor: "rut",
      },
      {
        Header: "DV",
        accessor: "dv",
      },
      {
        Header: "Proyecto",
        accessor: "nom_proyecto",
      },
      {
        Header: "Subproyecto",
        accessor: "nom_sub_proyecto",
      },
      {
        Header: "Monto",
        accessor: "monto",
      },
      {
        Header: "Estado",
        accessor: "estado",
      },
      {
        Header: "Fecha",
        accessor: "fecha",
        Cell: (props) => {
          const fecha = props.row.values;

          return <span>{moment(fecha).format("YYYY-MM-DD")}</span>;
        },
      },
    ],
    []
  );
  // React.useEffect(() =>{
  //     let suma = 0;
  //     ReportesService.getReportePagos(81, token).then(r =>{
  //         setReportesPago(r);
  //         r.forEach(p => {
  //             if(p.estado == "Autorizado"){
  //                 suma += p.monto
  //             }
  //         })
  //         setTotal(suma);
  //     })
  // }, [])
  return (
    <>
      <div>
        {!selectedProyecto && (
          <div>
            <h1 className="mb-24">Buscar proyecto</h1>
            <InputUc onChange={buscarProyecto} placeholder="Ingrese el nombre del proyecto" />
            {list}
          </div>
        )}
      </div>
      {selectedProyecto && (
        <div>
          <h1 className="mt-24 mb-24">{curProyecto.label}</h1>
          <p>Total recaudado: ${total}</p>
          <ButtonUC onClick={volver}>Volver</ButtonUC>

          <Table columns={columns} data={reportesPago} />
        </div>
      )}
    </>
  );
}

export default ReportesPagos;
