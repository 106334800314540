import React from "react";
import WebPayService from "../../../../services/WebPayService";

import AppContext from "../../../../AppContext";
import UserContext from "../../../../UserContext";
import PagoWebPay from "../../../misproyectos/PagoWebPay";
import Table from "../../../ui/Table";
import ModalUC from "../../../ui/ModalUC";

import moment from "moment";
function Pagos({ codSub, codUser }) {
  const user = React.useContext(UserContext);
  const token = React.useContext(AppContext);

  const [pagos, setPagos] = React.useState([]);
  const [status, setStatus] = React.useState({});
  const [detallePagoModal, setDetallePagoModal] = React.useState(false);
  const [current, setCurTransaccion] = React.useState({});

  const toState = (p) => {
    console.log(p);
    WebPayService.getStatus(p.id, token).then((r) => setStatus(r));
  };

  const watchTransaction = (row) => {
    console.log(row);
    setDetallePagoModal(true);
  };
  React.useEffect(() => {
    WebPayService.getPagos(codUser, codSub, token).then((r) => {
      setPagos(r);
      console.log(r);
    });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Orden Compra",
        accessor: "id",
      },
      {
        Header: "Código autorización",
        accessor: "codAutoriz",
      },
      {
        Header: "Monto",
        accessor: "monto",
        Cell: (props) => {
          const monto = props.row.values.monto;
          return <span>${monto}</span>;
        },
      },
      {
        Header: "Estado",
        accessor: "estado",
        Cell: (props) => {
          const estado = props.row.values.estado;
          return <span title="Editar">{estado === 0 ? "Fallado" : "Autorizado"}</span>;
        },
      },
      {
        Header: "Fecha",
        accessor: "fechaTransaccion",
        Cell: (props) => {
          const fecha = moment(props.row.values.fechaTransaccion).format("DD MMMM YYYY");
          return <span>{fecha}</span>;
        },
      },
      {
        Header: "Cuota",
        accessor: "cuota",
        Cell: (props) => {
          const cuota = props.row.values.cuota;
          return <span>${cuota}</span>;
        },
      },
      {
        Header: "N° cuotas",
        accessor: "numCuotas",
      },
      {
        Header: "Tipo de pago",
        accessor: "tipoPago",
      },
    ],
    []
  );

  return (
    <div className="row">
      <strong>Lista de transacciones</strong>
      <Table columns={columns} data={pagos} />
    </div>
  );
}

export default Pagos;
