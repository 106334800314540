import React from "react";

export const ButtonUC = ({ children, onClick, ...props }) => {
  return (
    <button className="uc-btn btn-primary" style={{ maxWidth: "250px" }} onClick={onClick} {...props}>
      {children}
    </button>
  );
};
export default ButtonUC;
