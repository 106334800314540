import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';

import InscripcionComponent from '../components/catalogo/InscripcionComponent';

import Inicio from '../components/backoffice/Inicio';
import MisProyectosComponent from '../components/misproyectos/MisProyectosComponent';
import CatalogoComponent from '../components/catalogo/CatalogoComponent';
import HistorialComponent from '../components/misproyectos/HistorialComponent';
import EditVoluntario from '../components/backoffice/voluntarios/EditVoluntario';
import MisJefaturas from '../components/misproyectos/MisJefaturas';
import ListaVoluntarios from '../components/backoffice/proyectos/verVoluntarios/ListaVoluntarios';
import VerVoluntario from '../components/backoffice/proyectos/verVoluntarios/VerVoluntario';
import EditProyecto from '../components/backoffice/proyectos/EditProyecto';
import Notificaciones from '../components/backoffice/proyectos/verVoluntarios/Notificaciones';

import PagoWebPay from '../components/misproyectos/PagoWebPay';

export default function BackofficeRoutes() {
   return (
      <div style={{ paddingBottom: '260px' }}>
         <div className="container px-20 p-24 my-20" style={{ minHeight: '50vh' }}>
            <Switch>
               {/* <Route exact path="/" component={Inicio} name="Inicio" /> */}
               <Route exact path={'/mi-cuenta'} component={EditVoluntario} />

               <Route exact path="/catalogo/:page" component={CatalogoComponent} />
               <Route exact path="/catalogo/:page/inscripcion/:codproyecto" component={InscripcionComponent} />

               <Route exact path="/mis-proyectos" component={MisProyectosComponent} />
               <Route exact path="/jefaturas" component={MisJefaturas} />
               <Route exact path="/pagoWebPay" component={PagoWebPay} />

               <Route exact path="/proyectos/editar/:idPro" component={EditProyecto} />
               <Route exact path="/proyectos/editar/:idPro/sub/:id/inscripciones" component={ListaVoluntarios} />
               <Route exact path="/proyectos/editar/:idPro/sub/:idSub/inscrito/:id" component={VerVoluntario} />

               <Route exact path="/historial" component={HistorialComponent} />

               <Route exact path="/notificaciones" component={Notificaciones} />

               <Route exact path="*">
                  <Redirect to="/catalogo/1" />
               </Route>
            </Switch>
         </div>
      </div>
   );
}
