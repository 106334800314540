import React, { useState, useEffect, forwardRef } from "react";
import Select from "react-select";
import DominiosService from "../../services/DominiosService";

export const SelectUC = forwardRef((props, ref) => {
  const [options, setOptions] = useState([]);
  const dominio = props.dominio;
  useEffect(() => {
    if (dominio) {
      DominiosService.fetchDominiosByNombre(dominio)
        .then((response) => {
          const res = response.map((option) => ({ value: option.id, label: option.nomValor }));
          if (dominio === "comunas") res.sort((a, b) => a.label.localeCompare(b.label));
          setOptions(res);
        })
        .catch((err) => console.log(err));
    } else {
      setOptions(props.options);
    }
  }, []);

  return (
    <div
      className="uc-form-group"
      // style={{ zIndex: 1000 }}
    >
      {!props.noLabel && <label htmlFor={props.name}>{props.label}</label>}
      <Select ref={ref} options={options} defaultValue={props.defaults} isDisabled={props.isdisabled} className="react-select" placeholder="Seleccionar..." theme={(theme) => ({ ...theme, spacing: { ...theme.spacing, baseUnit: 6 } })} {...props} />
      <p className="p-size--sm p-2 form-error">{props.errores}</p>
    </div>
  );
});
export default SelectUC;
