import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import InputUC from "../../ui/InputUC";
import * as XLSX from "xlsx";
import VoluntariosService from "../../../services/VoluntariosService";

export default function ModalCargaMasiva({ setModal, setMessage, setAlertType, token }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [valores, setvalores] = useState([]);
  const [dis, setDis] = useState(true);
  const [terminoCarga, setTerminoCarga] = useState(false);
  const [resultado, setResultado] = useState({});

  function actualizar() {
    // document.location.reload();
  }

  const submitHandler = async (event) => {
    try {
      if (valores[0].rut || valores[0].pasaporte) {
        const response = await VoluntariosService.cargaMasiva(valores, token);
        console.log(response);
        response.realizado === "s" ? setAlertType("success") : setAlertType("error");
        setMessage(response.respuesta);
        setDis(true);
        // setModal(false);
        setResultado(response);
        setTerminoCarga(true);
      } else {
        alert("Formato excel no válido");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        if (ws.A1) {
          ws.A1.v = ws.A1.h = ws.A1.w = "rut";
          ws.A1.r = "<t>rut</t>";
        }
        const data = XLSX.utils.sheet_to_json(ws);
        console.log(data);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setvalores(d);
    });
  };

  return (
    <div className="uc-modal">
      <div className="uc-modal_content uc-message warning" style={{ maxWidth: "600px" }}>
        <span className="uc-message_close-button" onClick={() => setModal(false)}>
          <i className="uc-icon">close</i>
        </span>
        <div className="uc-message_body">
          <h2 className="mb-24">
            <i className="uc-icon warning-icon p-4">info</i>Carga masiva de voluntarios
          </h2>
          <hr className="uc-hr my-32" />
          <div className="row justify-content-center">
            {!terminoCarga && (
              <div className="uc-form-group">
                <InputUC
                  type="file"
                  name="valor"
                  accept=".XLS,.xlsx,.CSV"
                  label="Importar excel"
                  errores={errors.valor?.message}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    readExcel(file);
                    setDis(false);
                  }}
                />
              </div>
            )}
            {terminoCarga && (
              <ul>
                {resultado.correctos.map((r) => (
                  <li>
                    {r} <i className="uc-icon">done</i>
                  </li>
                ))}
                {resultado.errores.map((err) => (
                  <li>
                    {err}
                    <i className="uc-icon" style={{ color: "red" }}>
                      clear
                    </i>
                  </li>
                ))}
              </ul>
            )}

            <div className="uc-form-group row mx-auto">
              <button className="uc-btn btn-cta" onClick={submitHandler} disabled={dis}>
                Cargar
              </button>
            </div>

            <div className="mt-4">
              <a href="/carga_usuarios.xlsx" className="uc-btn btn-primary">
                Descargar plantilla de ejemplo
                <i className="uc-icon">download</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
