import React, { useState, useEffect, useMemo, useContext } from "react";
import DominiosService from "../../../services/DominiosService";
import PerfilService from "../../../services/PerfilService";

import CreateDominio from "./CreateDominio";
import EditDominio from "./EditDominio";
import DeleteDominio from "./DeleteDominio";

import Table from "../../ui/Table";
import { Alerts } from "../../ui/Alerts";
import { ButtonUC } from "../../ui/ButtonUC";
import { TitleUC } from "../../ui/TitleUC";

import AppContext from "../../../AppContext";
import UserContext from "../../../UserContext";
import Denegado from "../../Auth/Denegado";

function DominiosComponent() {
  const token = useContext(AppContext);
  const user = useContext(UserContext);

  const [dominios, setDominios] = useState([]);
  const [currentString, setCurrentString] = useState("");
  const [currentId, setCurrentId] = useState();
  const [dominio, setDominio] = useState([]);

  const [permiso, setPermiso] = useState(false);
  const [currentValor, setCurrentValue] = useState({});

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  useEffect(() => {
    setPermiso(PerfilService.permisoHandler(user, "Dominios"));
    DominiosService.fetchAllDominios(token)
      .then((response) => setDominios(response))
      .catch((error) => console.log(error));
  }, []);

  function changeCurrent(currentString, idDom) {
    setCurrentString(currentString);
    setCurrentId(idDom);

    DominiosService.fetchDominiosByNombre(currentString)
      .then((response) => {
        setDominio(response);
      })
      .catch((error) => console.log(error));
  }

  const editHandler = (rowValues) => {
    setCurrentValue(rowValues);
    setEditModal(true);
  };

  const addHandler = () => {
    setAddModal(true);
  };
  const deleteHandler = (rowValues) => {
    rowValues != null ? setCurrentValue(rowValues) : setCurrentValue({});
    setDeleteModal(true);
  };
  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        accessor: "actions",
        Cell: (props) => {
          const rowValues = props.row.values;
          return (
            rowValues.protegido === "n" && (
              <div>
                <span onClick={() => editHandler(rowValues)} title="Editar">
                  <i className="uc-icon icon-shape--rounded mx-2">edit</i>
                </span>

                <span onClick={() => deleteHandler(rowValues)} title="Eliminar">
                  <i className="uc-icon icon-shape--rounded mx-2">delete</i>
                </span>
              </div>
            )
          );
        },
      },
      {
        Header: "codDominio",
        accessor: "codDominio",
      },
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "Valor",
        accessor: "nomValor",
      },
      {
        Header: "text",
        accessor: "text",
      },
      {
        Header: "Descripción",
        accessor: "descrip",
      },
      {
        Header: "opc",
        accessor: "opc",
      },

      {
        Header: "Oculto",
        accessor: "oculto",
        Cell: (props) => {
          return props.value === "s" ? "Sí" : "No";
        },
      },
      {
        Header: "Protegido",
        accessor: "protegido",
        Cell: (props) => {
          return props.value === "s" ? "Sí" : "No";
        },
      },
      {
        Header: "Última actualización",
        accessor: "fechaAtualiz",
      },
    ],
    []
  );
  const listDominios = dominios.map((dominio) => (
    <option key={dominio.id} value={dominio.id + "|" + dominio.columna}>
      {dominio.nombre}
    </option>
  ));
  const initialState = {
    hiddenColumns: ["descrip", "codDominio", "opc", "text", "id"],
  };
  function changeDominio(e) {
    let data = e.target.value.split("|");
    let idDom = data[0];
    let domString = data[1];
    changeCurrent(domString, idDom);
  }
  return !permiso ? (
    <Denegado />
  ) : (
    <div className="container-fluid">
      <TitleUC value="Mantenedor de Dominios" />
      <div className="row">
        <div className="col">{message && <Alerts message={message} setMessage={setMessage} alertType={alertType} />}</div>
      </div>

      <div className="d-flex justify-content-between row">
        <div className="col-lg-4 py-2 ">
          <select className="uc-input-style" style={{ maxWidth: "150px" }} onChange={(e) => changeDominio(e)}>
            <option value="" hidden>
              Seleccionar...
            </option>
            {listDominios}
          </select>
        </div>
      </div>

      <div className="d-flex justify-content-between row py-2">
        <div className="col-lg-4 py-2">
          <ButtonUC onClick={addHandler} disabled={!currentString ? true : false}>
            Ingresar nuevo valor
          </ButtonUC>
        </div>
        <div className="col-lg-4 py-2 ">{/* <CtaButton text='Guardar orden de preguntas' /> */}</div>
        <div className="col-lg-4 py-2 ">
          <input id="ucsearch" type="text" className="uc-input-style w-icon search " placeholder="Buscar por" />
        </div>
      </div>
      {currentString && <Table data={dominio} columns={columns} initialState={initialState} />}
      {!currentString && (
        <div className="table-responsive py-4">
          <table className="uc-table container">
            <thead>
              <tr>
                <th>Acciones</th>
                <th>ID</th>
                <th>Valor</th>
                <th>Oculto</th>
                <th>Protegido</th>
                <th>Última actualización</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <span className="uc-subtitle">Selecciona dominio a editar</span>
              </tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
      )}
      {addModal && <CreateDominio setModal={setAddModal} currentId={currentId} setCurrentId={setCurrentId} setMessage={setMessage} changeCurrent={changeCurrent} currentString={currentString} setAlertType={setAlertType} token={token} />}
      {editModal && <EditDominio setModal={setEditModal} setMessage={setMessage} setCurrentId={setCurrentId} style={{ maxWidth: "250px" }} dominio={dominio} changeCurrent={changeCurrent} currentId={currentId} currentValor={currentValor} setAlertType={setAlertType} currentString={currentString} token={token} />}
      {deleteModal && <DeleteDominio currentId={currentId} style={{ maxWidth: "250px" }} setModal={setDeleteModal} currentValor={currentValor} changeCurrent={changeCurrent} currentString={currentString} setMessage={setMessage} setAlertType={setAlertType} token={token} />}
    </div>
  );
}

export default DominiosComponent;
