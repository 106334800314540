import axios from 'axios';

const API_USER = process.env.REACT_APP_BACKEND_URL + 'usuario_email/';

const fetchUser = async (email, token) => {
   return await axios.get(API_USER + email, {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   });
};

const UserService = {
   fetchUser,
};

export default UserService;
