import { Box } from "@material-ui/core";
import { NavLink, Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import UserContext from "../../UserContext";
import React, { useContext } from "react";
export default function HeaderVoluntario() {
  const user = useContext(UserContext);
  const history = useHistory();
  return (
    <header className="uc-header">
      <Helmet>
        <script src="https://kit-digital-uc-prod.s3.amazonaws.com/uc-kitdigital/js/uc-kitdigital.js" type="text/javascript" />
      </Helmet>
      {/* Tabs mobile se muestran como Select */}
      <div className="uc-card card-bg--gray card-radius--none card-border--none d-block d-lg-none mb-32">
        <div className="uc-card_body">
          <label htmlFor="tabSelect">
            <strong>Navegación...</strong>
          </label>
          <select
            name="tabSelect"
            id="tabSelect"
            className="uc-input-style"
            data-tabselect
            onChange={(val) => {
              let link = "";
              switch (val.target.value) {
                case "tab-01":
                  link = "/catalogo/1";
                  break;
                case "tab-02":
                  link = "/mis-proyectos";
                  break;
                case "tab-03":
                  link = `/historial?usr=${user.codUsuario}`;
                  break;
                case "tab-04":
                  link = "/jefaturas";
                  break;
                default:
                  break;
              }
              history.push(link);
            }}
          >
            <option value="tab-01">Catálogo</option>
            <option value="tab-02">Mis proyectos</option>
            <option value="tab-03">Historial</option>
            <option value="tab-04">Jefaturas</option>
          </select>
        </div>
      </div>
      <nav className="uc-navbar">
        {/* Menú para versión Escritorio */}
        <div className="container d-none d-lg-block">
          <div className="row align-items-center">
            <div className="col-lg-3 col-xl-2">
              <Link to="/">
                <img src="https://kit-digital-uc-prod.s3.amazonaws.com/assets/logo-uc-color.svg" alt="Logo de Facultad" className="img-fluid" />
              </Link>
            </div>
            <div className="col-lg-8 col-xl-9">
              <div className="h2 text-font--serif text-color--blue mt-24">Portal Voluntarios</div>
              <div className="text-color--gray p-size--lg">Pastoral UC</div>
            </div>
          </div>
          {/* Menú Backoffice */}
          <ul className="uc-navbar_nav">
            <li className="nav-item">
              <NavLink to="/catalogo/1" className="uc-btn btn-inline">
                Catalogo
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/mis-proyectos" className="uc-btn btn-inline">
                Mis proyectos
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`/historial?usr=${user.codUsuario}`} className="uc-btn btn-inline">
                Historial
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/jefaturas" className="uc-btn btn-inline">
                Jefaturas
              </NavLink>
            </li>
          </ul>
          {/* Menú Voluntarios */}
          {/* <ul className="uc-navbar_nav">
                        <li className="nav-item">
                            <NavLink to="/catalogo" className="uc-btn btn-inline">
                                Catálogo de proyectos
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/mis-proyectos" className="uc-btn btn-inline">
                                Mis Proyectos
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/historico" className="uc-btn btn-inline">
                                Histórico
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/nosotros" className="uc-btn btn-inline">
                                Nosotros
                            </NavLink>
                        </li>
                    </ul> */}
        </div>
      </nav>
    </header>
  );
}
