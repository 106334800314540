import React, { useState } from "react";
import { Button, UncontrolledCollapse } from "reactstrap";
import moment from "moment";
import "moment/locale/es";
import ICalendarLink from "react-icalendar-link";
import Table from "../ui/Table";
import InscripService from "../../services/InscripService";
import Confirm from "./../ui/Confirm";
import ModalUC from "./../ui/ModalUC";

function MisActividades({ activ, actividadModal, setActividadModal, codUsuario, token, soloActiv, actualizarMisProyectos, mensaje, proyectos, user, pagar }) {
  const [curActiv, setCurActiv] = useState({});
  const [curSub, setCurSub] = useState();
  const [confirmModal, setStateModal] = useState(false);
  const [currentProject, setCurrentProject] = useState({});

  const verActividad = (act, p) => {
    setActividadModal(true);
    setCurActiv(act);
    setCurSub(p.codSubProyecto);
  };

  const darBaja = async () => {
    const res = await InscripService.darBaja(codUsuario, curActiv.codActiv, token);
    setActividadModal(false);
    alert(res.respuesta);
    actualizarMisProyectos();
    enviarBitacora("Se dio de baja de la actividad", 61);
  };

  const darBajaProyecto = async (p) => {
    const res = await InscripService.deleteInscripcion(codUsuario, p.codSubProyecto, token);
    actualizarMisProyectos();
    alert(res.respuesta);
  };

  const accept = () => {
    setStateModal(false);
    darBajaProyecto(currentProject);
  };

  const cancel = () => {
    setStateModal(false);
  };

  const inscribirActividad = async () => {
    const inscrip = {
      codUsuario: codUsuario,
      codActiv: curActiv.codActiv,
      estadoInscrip: "inscrito",
    };

    const res = await InscripService.inscriptActiv(inscrip, token);
    setActividadModal(false);
    alert(res.respuesta);
    // InscripService.getMisProyectos(codUsuario, token).then(res => setProyectos(res.respuesta))
    actualizarMisProyectos();
    enviarBitacora("Se inscribió a la actividad", 59);
  };

  const enviarBitacora = async (mensaje, tipo) => {
    const newMensaje = {
      codUsuarioAfecta: codUsuario,
      codUsuarioAccion: user.codUsuario,
      codSubproyecto: curSub,
      tipo: tipo,
      visto: "n",
      coment: `Realizado por : ${user.nom} ${user.apellPat} - ${mensaje} '${curActiv.nomActividad}'`,
    };

    const res = await InscripService.enviarMensaje(newMensaje, token);
  };

  const abrirConfirmar = (p) => {
    setStateModal(true);
    setCurrentProject(p);
    console.log("Proyecto", p);
  };

  // const pagar = (p) => {
  //     console.log(p)
  // }

  const MisActiv = ({ p }) => {
    let a = p.listActiv.map((act, i) => (
      <tr key={act.codActiv + "_" + i}>
        <td>
          <p>{act.nomActividad}</p>
        </td>
        <td>{moment(act.inicio).format("DD MMMM YYYY")}</td>
        <td>{moment(act.termino).format("DD MMMM YYYY")}</td>
        <td>{act.estadoInscrip}</td>
        <td>
          <i className="uc-icon" title="Ver Detalle" onClick={() => verActividad(act, p)}>
            description
          </i>

          <ICalendarLink
            filename="calendario.ics"
            event={{
              title: p.nomProyecto + " - " + act.nomActividad,
              description: act.descripcion,
              startTime: act.inicio,
              endTime: act.termino,
              location: act.ubicacion,
              url: "https://pastoral.uc.cl/",
            }}
          >
            <i className="uc-icon" style={{ fontSize: "24px" }} title="Agregar a Calendario">
              event
            </i>
          </ICalendarLink>
        </td>
      </tr>
    ));
    if (a.length === 0) {
      a = "No hay actividades";
    }
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Inicio</th>
              <th scope="col">Término</th>
              <th scope="col">Estado</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          {a !== "No hay actividades" && a}
        </table>
        {a === "No hay actividades" && a}
      </>
    );
  };

  return (
    <div>
      {!soloActiv &&
        proyectos.map(
          (p) =>
            p.estadoInscrip != "De Baja" && (
              <div className="uc-card container mb-3" key={p.codSubProyecto}>
                <div className="uc-card-body container mt-16 mb-11">
                  <div className="row">
                    <div className="col-lg-3">
                      <h3>{p.nomProyecto}</h3>
                      <div className="container mt-8 mb-16">
                        {/* <i className='uc-icon' onClick={() => mensaje()}>description</i> */}
                        <i className="uc-icon" title="Enviar mensaje" onClick={() => mensaje(p)}>
                          question_answer
                        </i>
                        {p.minMonto !== null && (
                          <i className="uc-icon" title="Pago de inscripción" onClick={() => pagar(p)}>
                            shopping_cart
                          </i>
                        )}
                        <i className="uc-icon" title="Dejar proyecto" onClick={() => abrirConfirmar(p)}>
                          delete
                        </i>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="h4 ">Subproyecto</div>
                      <div>{p.nomSubProyecto}</div>
                    </div>
                    <div className="col-lg-2">
                      <div className="h4 ">Estado</div>
                      <div>{p.estadoProyecto}</div>
                    </div>

                    <div className="col-lg-2">
                      <div>
                        <div className="h4 ">Inscripción</div>
                        <div>{p.estadoInscrip}</div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <Button color="outline-primary" id={`toggle${p.codSubProyecto}`}>
                        Ver actividades
                        {/* <i className="uc-icon icon-shape--rounded">keyboard_arrow_down</i> */}
                      </Button>
                    </div>
                  </div>
                </div>
                <div>
                  <UncontrolledCollapse toggler={`toggle${p.codSubProyecto}`}>
                    <MisActiv p={p} />
                  </UncontrolledCollapse>
                </div>
              </div>
            )
        )}
      {soloActiv && proyectos.map((p) => <MisActiv p={p} />)}
      {actividadModal && (
        <ModalUC title={curActiv.nomActividad} setModal={setActividadModal} setAlertType="success" style={{ maxWidth: "550px", zIndex: 200 }}>
          <div className="uc-form-group">
            <div>
              <label>Descripción</label>
              <p>{curActiv.descripcion}</p>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label>Inicio</label>
                <p>{moment(curActiv.inicio).format("DD MMMM YYYY hh:mm")}</p>
              </div>
              <div className="col-md-6">
                <label>Término</label>
                <p>{moment(curActiv.termino).format("DD MMMM YYYY hh:mm")}</p>
              </div>
            </div>
            {/* <div>
                        <label>Ubicación</label>
                        <p>{curActiv.ubicacion}</p>
                    </div> */}
          </div>
          <div className="row">
            <div className="col-md-4">
              {curActiv.estadoInscrip === "Disponible" && (
                <a className="uc-btn btn-cta" onClick={inscribirActividad}>
                  Inscribir
                </a>
              )}
            </div>
            {/* <div className='col-md-4'>
                        <a className="uc-btn btn-primary" >Descarga cita</a>
                    </div> */}
            <div className="col-md-4">
              {curActiv.estadoInscrip === "Inscrito" && (
                <a className="uc-btn btn-cta" onClick={darBaja}>
                  Dar de baja
                </a>
              )}
            </div>
          </div>
        </ModalUC>
      )}
      {confirmModal && <Confirm setStateModal={setStateModal} accept={accept} cancel={cancel} texto="te quieres dar de baja del proyecto" subTexto="Una vez que te des de baja no te vas a poder volver a inscribir, esta acción solo la puede deshacer un administrador o jefatura del proyecto" />}
    </div>
  );
}

export default MisActividades;
