import React from 'react'
import DominiosService from '../../../services/DominiosService';

export default function ModalConfirm({ setModal, currentValor, setMessage, setAlertType, changeCurrent, currentString, currentId, token}) {

    const deleteHandler = async () => {
        const response = await DominiosService.remove(currentValor.id, token)
        response.realizado === true ? setAlertType('success') : setAlertType('error')
        setMessage(response.respuesta)
        changeCurrent(currentString, currentId);
        setModal(false)
    }

    return (
        <div className='uc-modal'>
            <div className="uc-modal_content uc-message error" style={{ maxWidth: '650px' }}>
                <span className="uc-message_close-button" onClick={() => setModal(false)}><i className="uc-icon">close</i></span>
                <div className="uc-message_body">
                    <h2 className="mb-24"><i className="uc-icon warning-icon p-4">warning</i> ¿Estás seguro de esta acción?</h2>
                    <hr className="uc-hr my-32" />
                    <div className='d-flex justify-content-between'>
                        <button className="uc-btn btn-cta" onClick={() => deleteHandler({ currentValor })}>Eliminar Valor</button>
                        <button className="uc-btn btn-primary" onClick={() => setModal(false)}>Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
