/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TitleUC from "../../../ui/TitleUC";
import { useParams } from "react-router";

const Navbar = ({ id, ...props }) => {
  //const { id } = useParams()
  return (
    <>
      <div>
        <TitleUC value={id ? "Editar proyecto" : "Crear Proyecto"} />
        {/* Tabs mobile se muestran como Select */}
        <div className="d-flex justify-content-between">
          <div className="uc-card card-bg--gray card-radius--none card-border--none d-block d-lg-none mb-32">
            <div className="uc-card_body">
              <label htmlFor="tabSelect">{/* <strong>Seleccione tab</strong> */}</label>
              <select
                name="tabSelect"
                id="tabSelect"
                className="uc-input-style"
                data-tabselect
                onChange={(val) => {
                  let link = 0;
                  switch (val.target.value) {
                    case "tab-01":
                      link = 1;
                      break;
                    case "tab-02":
                      link = 2;
                      break;
                    case "tab-03":
                      link = 3;
                      break;
                    case "tab-04":
                      link = 4;
                      break;
                    case "tab-05":
                      link = 5;
                      break;
                    case "tab-06":
                      link = 6;
                      break;
                    default:
                      break;
                  }
                  props.setStep(link);
                }}
              >
                <option value="tab-01">Información General</option>
                <option value="tab-02">Restricciones</option>
                <option value="tab-03">Cuestionario</option>
                <option value="tab-04">Subproyectos</option>
                <option value="tab-05">Jefaturas</option>
                <option value="tab-06">Lista Voluntarios</option>
              </select>
            </div>
          </div>
          {/* Tabs en desktop se muestran como botones */}
          <ul className="uc-tabs d-none d-lg-flex">
            <li className="uc-tabs_item">
              <a className={`uc-tabs_item-link nav-button ${props.step === 1 ? "active" : ""} ${id ? "text-black" : ""}`} onClick={() => props.setStep(1)}>
                Información General
              </a>
            </li>
            <li className="uc-tabs_item">
              <a className={`uc-tabs_item-link nav-button ${props.step === 2 ? "active" : ""} ${id ? "text-black" : ""}`} disabled={id ? false : true} onClick={() => (id ? props.setStep(2) : console.log("xd"))} title={id ? "Ir a restricciones" : "No disponible. Debe crear el proyecto"}>
                Restricciones
              </a>
            </li>
            <li className="uc-tabs_item">
              <a className={`uc-tabs_item-link nav-button ${props.step === 3 ? "active" : ""} ${id ? "text-black" : ""}`} disabled={id ? false : true} onClick={() => (id ? props.setStep(3) : console.log("xd"))} title={id ? "Ir a cuestionario" : "No disponible. Debe crear el proyecto"}>
                Cuestionario
              </a>
            </li>
            <li className="uc-tabs_item">
              <a className={`uc-tabs_item-link nav-button ${props.step === 4 ? "active" : ""} ${id ? "text-black" : ""}`} disabled={id ? false : true} onClick={() => (id ? props.setStep(4) : console.log("xd"))} title={id ? "Ir a subproyectos" : "No disponible. Debe crear el proyecto"}>
                Subproyectos
              </a>
            </li>
            <li className="uc-tabs_item">
              <a className={`uc-tabs_item-link nav-button ${props.step === 5 ? "active" : ""} ${id ? "text-black" : ""}`} disabled={id ? false : true} onClick={() => (id ? props.setStep(5) : console.log("xd"))} title={id ? "Ir a Jefaturas" : "No disponible. Debe crear el proyecto"}>
                Jefaturas
              </a>
            </li>
            <li className="uc-tabs_item">
              <a className={`uc-tabs_item-link nav-button ${props.step === 6 ? "active" : ""} ${id ? "text-black" : ""}`} disabled={id ? false : true} onClick={() => (id ? props.setStep(6) : console.log("xd"))} title={id ? "Ir a Voluntarios" : "No disponible. Debe crear el proyecto"}>
                Lista Voluntarios
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
