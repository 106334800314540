import React from "react";
import ModalUC from "./ModalUC";

function Confirm({ setStateModal, accept, cancel, texto, subTexto }) {
  const [state, setState] = React.useState(false);
  const onConfirm = (text) => {
    setState(!state);
  };
  return (
    <ModalUC title="Confirmar" setModal={setStateModal} setAlertType="success" style={{ maxWidth: "250px", zIndex: 200 }}>
      <div className="row">
        <div className="col-md-12">
          <p>¿Estás seguro que {texto}?</p>
        </div>
        <div className="col-md-12">
          <p>{subTexto}</p>
        </div>
        <div className="col-md-12"></div>
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <a href="#" className="uc-btn btn-primary" onClick={() => accept()}>
            Aceptar
          </a>
        </div>
        <div className="col-md-4">
          <a href="#" className="uc-btn btn-primary" onClick={() => cancel()}>
            Cancelar
          </a>
        </div>
      </div>
    </ModalUC>
  );
}
export default Confirm;
