import React, { useState } from "react";
import { emailPastoral } from "../../properties";

import Login from "./Login";

export default function LoginComponent({ saveToken }) {
  const [isCas, setIsCas] = useState(true);
  const casLoginHandler = (mode) => {
    setIsCas(mode);
  };
  const tokenString = localStorage.getItem("token");
  console.log(tokenString);
  return (
    <div className="row justify-content-center">
      <div className="uc-card m-24 px-20 p-24 login-card" style={{ width: "600px" }}>
        <div className="uc-card_header">
          <h3>Iniciar Sesión</h3>
          <div className="uc-tabpanel" data-tabpanel>
            {/* Tabs mobile se muestran como Select */}
            <div className="uc-card card-bg--gray card-radius--none card-border--none d-block d-lg-none mb-32">
              <div className="uc-card_body">
                <label htmlFor="tabSelect">{/* <strong>Seleccione tab</strong> */}</label>
                <select
                  name="tabSelect"
                  id="tabSelect"
                  className="uc-input-style"
                  data-tabselect
                  onChange={(val) => {
                    let link = true;
                    switch (val.target.value) {
                      case "tab-01":
                        link = true;
                        break;
                      case "tab-02":
                        link = false;
                        break;

                      default:
                        break;
                    }
                    casLoginHandler(link);
                  }}
                >
                  <option value="tab-01">Credenciales UC</option>
                  <option value="tab-02">Correo y contraseña</option>
                </select>
              </div>
            </div>
            {/* Tabs en desktop se muestran como botones */}
            <ul className="uc-tabs d-none d-lg-flex">
              <li className="uc-tabs_item">
                <a className={`uc-tabs_item-link ${isCas ? "active" : ""}`} onClick={() => casLoginHandler(true)}>
                  Correo UC
                </a>
              </li>
              <li className="uc-tabs_item">
                <a className={`uc-tabs_item-link ${!isCas ? "active" : ""}`} onClick={() => casLoginHandler(false)}>
                  Correo no UC
                </a>
              </li>
            </ul>
            <div className="uc-tab-body">
              <div className="uc-card_body login">
                {isCas && (
                  <div style={{ minHeight: "235px" }}>
                    <div className="uc-form-group">
                      <label>Abrir enlace</label>
                      <div className="row justify-content-center">
                        <div className="col-lg-12">
                          <a className="uc-btn btn-primary" href={process.env.REACT_APP_BACKEND_URL + `loginCAS`}>
                            Inicio sesión Correo UC
                            <i className="uc-icon">launch</i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="uc-form-group">
                      <label>Cerrar sesión UC</label>
                      <div className="row justify-content-center">
                        <div className="col-lg-12 py-12 text-center">
                          <a href={process.env.REACT_APP_BACKEND_URL + `logoutCAS`} target="" className="uc-btn btn-primary">
                            Cerrar sesión UC
                            <i className="uc-icon">launch</i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!isCas && <Login saveToken={saveToken} />}
                <div className="row justify-content-center">
                  <span className="p-size--sm">
                    Si tienes problemas para ingresar contáctanos a <a href={"mailto:" + emailPastoral}>{emailPastoral}</a>
                  </span>
                  {/* <p className="p-size--sm">Por razones de seguridad, ¡por favor cierre su sesión y su navegador web cuando haya terminado de acceder a los servicios que requieren autenticación!</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
