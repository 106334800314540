import React, { useContext } from 'react';
import AuthService from '../../services/AuthService';
import UserContext from '../../UserContext';

export default function Logout({ setAuth }) {
   const user = useContext(UserContext);
   const handleLogout = (e) => {
      AuthService.logout({ setAuth, user });
   };

   return (
      <a href="#header" className="text-size--sm" onClick={handleLogout}>
         Cerrar Sesión
      </a>
   );
}
