import axios from 'axios';

const API_JEFATURAS = process.env.REACT_APP_BACKEND_URL + 'cargo';
const API_DELETECARGO = process.env.REACT_APP_BACKEND_URL + 'jefatura';
const API_ROL = process.env.REACT_APP_BACKEND_URL + 'rol';
const API_SUB = process.env.REACT_APP_BACKEND_URL + 'todas-jefaturas';

const createCargo = (data, token) => {
   return axios
      .post(`${API_JEFATURAS}`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getCargos = (id, token) => {
   return axios
      .get(`${API_JEFATURAS}s/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const deleteCargo = (token, id) => {
   return axios
      .delete(`${API_DELETECARGO}/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const createRol = (data, token) => {
   return axios
      .post(`${API_ROL}`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getRol = (id, token) => {
   return axios
      .get(`${API_ROL}es/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const deleteRol = (token, id) => {
   return axios
      .delete(`${API_ROL}/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getSubProyectos = (idUser, token) => {
   return axios
      .get(`${API_SUB}/${idUser}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getHistorial = (codUser, token) => {
   return axios
      .get(`${API_DELETECARGO}/historial/${codUser}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => console.log(err.response.data));
};
const JefaturasService = {
   createCargo,
   getCargos,
   deleteCargo,
   createRol,
   getRol,
   deleteRol,
   getSubProyectos,
   getHistorial,
};

export default JefaturasService;
