import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";

import CatalogoService from "../../services/CatalogoService";

import Filtros from "./Filtros";
import Resultados from "./Resultados";
import Paginador from "./Paginador";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { TitleUC } from "../ui/TitleUC";

function CatalogoComponent() {
  const { page } = useParams();

  const token = useContext(AppContext);
  //const usr = useContext(AppContext).user;

  const [catalogo, setCatalogo] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [paginas, setPaginas] = useState([]);
  const [paginador, setPaginador] = useState([]);
  const [resPorPagina, setResPorPagina] = useState(6);
  const [categoria, setCategoria] = useState([]);
  const [currentPage, setCurrentPage] = useState(parseInt(page));
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(true);
  const handleResPorPagina = (res) => {
    setResPorPagina(res.value);
  };

  const handleSearchString = (e) => {
    if (e.key === "Enter") {
      setSearchString(e.target.value);
      setLoading(true);
    }
  };

  const handleCategoria = (data) => {
    setCategoria(data);
    setLoading(true);
  };

  const handleEtiquetas = (data) => {
    setEtiquetas(data);
    setLoading(true);
  };

  const handleCurrentPage = (current) => {
    setCurrentPage(current);
    setLoading(true);
  };

  const fetchCatalogo = async () => {
    const config = {
      codcategoria: categoria,
      elempag: resPorPagina,
      pagenum: currentPage,
      codetiq: etiquetas,
      searchterms: searchString,
    };
    console.log(config);
    try {
      const response = await CatalogoService.fetchCatalogo(config, token);
      setCatalogo(response.respuesta);
      setPaginador(response.paginador);
      setPaginas(response.max_paginas);
      setCurrentPage(response.pagina);
      console.log(response.respuesta);
    } catch (err) {
      setCatalogo([]);
    }
  };

  // const fetchPaginador = async () => {
  //   const config = {
  //     codcategoria: categoria,
  //     elempag: resPorPagina,
  //     pagenum: currentPage,
  //     codetiq: etiquetas,
  //     searchterms: searchString,
  //   };
  //   const totalPages = await CatalogoService.fetchPaginador(config, token);
  //   setPaginas(totalPages);
  // };

  useEffect(() => {
    fetchCatalogo().then(() => setLoading(false));
    // fetchPaginador().then(() => console.log("B"));
  }, [searchString, etiquetas, categoria, resPorPagina, currentPage]);

  return (
    <div className="container-fluid">
      <TitleUC value="Catálogo de proyectos" />
      <Filtros handleSearchString={handleSearchString} handleCategoria={handleCategoria} handleEtiquetas={handleEtiquetas} />
      {!loading ? (
        <>
          <Resultados catalogo={catalogo} page={page} />
          <Paginador handleResPorPagina={handleResPorPagina} resPorPagina={resPorPagina} handleCurrentPage={handleCurrentPage} paginas={paginas} paginador={paginador} currentPage={currentPage} page={page} />
        </>
      ) : (
        <>Cargando datos...</>
      )}
    </div>
  );
}

export default CatalogoComponent;
