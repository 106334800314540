import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL + 'auth/authenticate';
const API_CONFIRM = process.env.REACT_APP_BACKEND_URL + 'confirm';
const API = process.env.REACT_APP_BACKEND_URL;

const login = async (credentials) => {
   const response = await axios.post(API_URL, credentials, { withCredentials: true });
   return response;
};

const logout = ({ setAuth, user }) => {
   localStorage.clear();
   setAuth(false);
   setTimeout(() => {
      if (user.usuarioCAS == 's') {
         window.location.href = process.env.REACT_APP_BACKEND_URL + 'logoutCAS';
      } else {
         window.location.href = process.env.REACT_APP_FRONT_URL;
      }
   }, 1000);

   //window.history.pushState({}, document.title, "/" );
};

const confirm = async (token) => {
   const response = await axios.get(API_CONFIRM, {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   });
   return response;
};

const sendMail = (email) => {
   return axios.get(`${API}usuario/passwd/request?email=${email}`);
};

const sendNewPass = (data) => {
   return axios
      .post(`${API}usuario/passwd/reset`, data)
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const setAuthHeader = () => {};

const AuthService = {
   login,
   logout,
   confirm,
   setAuthHeader,
   sendMail,
   sendNewPass,
};

export default AuthService;
