import React, { useState, useEffect, useRef } from "react";

import ButtonUC from "../../../ui/ButtonUC";
import { SubTitle } from "../../../ui/SubTitle";
import { InputUC } from "../../../ui/InputUC";
import { SelectUC } from "../../../ui/SelectUC";
import ModalUC from "../../../ui/ModalUC";

import Compress from "compress.js";
import informacionSchema from "../esquemas/informacionSchema";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";

function InformacionGeneral({ id, defaults, onSubmit, setStep, ...props }) {
  const [pagoRequerido, setPagoRequerido] = useState(props.edit ? props.requierePago : true);
  const [informacion, setInformacion] = useState({});
  const [imagenModal, setImagenModal] = useState(false);
  const imageRef = useRef();

  const validationSchema = informacionSchema;
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: defaults,
  });

  const handleRequierePago = (value) => {
    console.log(value);
    setValue("pagoRequerido", value);
    setValue("aporteminimo", null);
    setValue("aportesugerido", null);
    clearErrors(["aporteminimo", "aportesugerido"]);
    setPagoRequerido(value);
  };

  const toBase64 = async (e) => {
    let file = e.target.files[0];
    if (file.size / 1024 > 500) {
      alert("La imagen debe pesar menos de 500 kb y esta pesa " + Math.round(file.size / 1024) + " kb.");
    }
    const compress = new Compress();
    const imgResize = await compress.compress([file], {
      size: 0.5,
      quality: 0.75,
    });
    props.setImagen(imgResize[0].prefix + imgResize[0].data);
    setImagenModal(true);
    // let reader = new FileReader()
    // reader.readAsDataURL(file)
    // reader.onload = () => props.setImagen(reader.result);
    // setImagenModal(true)
  };

  useEffect(() => {
    setInformacion(defaults);
  }, [defaults]);
  useEffect(() => {
    if (props.edit) {
      setPagoRequerido(defaults.aporteminimo != null ? true : false);
    }
  }, [defaults]);
  useEffect(() => {
    reset(defaults);
  }, [defaults]);

  return (
    <div className="container-fluid">
      <SubTitle value="Información general" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg">
            <InputUC
              name="nom"
              errores={errors.nom?.message}
              label="Nombre del Proyecto *"
              type="text"
              {...register("nom")}
              placeholder="Nombre del Proyecto"
            />
          </div>
          <div className="col-lg" style={{ zIndex: 100 }}>
            <Controller
              control={control}
              name="idEstado"
              render={({ field }) => <SelectUC label="Estado inicial" dominio="estados_actividades" errores={errors.idEstado?.message} {...field} />}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg">
            <div className="uc-form-group">
              <label htmlFor="descripcion">Descripción</label>
              <textarea name="descripcion" className="uc-input-style" type="textarea" maxLength={250} {...register("descripcion")} />
              <p className="p-size--sm p-2 form-error">{errors.descripcion?.message}</p>
            </div>
          </div>
        </div>
        <SubTitle value="Fechas" />
        <div className="row">
          <div className="col-lg">
            <InputUC
              name="fechaInscripIni"
              errores={errors.fechaInscripIni?.message}
              label="Inicio de postulación *"
              type="date"
              {...register("fechaInscripIni")}
              // onClick={e => setValue('fechaInscripIni', moment().format('YYYY-MM-DD'))}
            />
          </div>

          <div className="col-lg">
            <InputUC
              name="fechaInscripTerm"
              errores={errors.fechaInscripTerm?.message}
              label="Fin de postulación *"
              type="date"
              {...register("fechaInscripTerm")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg">
            <InputUC name="fechaIni" errores={errors.fechaIni?.message} label="Inicio del proyecto *" type="date" {...register("fechaIni")} />
          </div>

          <div className="col-lg">
            <InputUC name="fechaTerm" errores={errors.fechaTerm?.message} label="Fin del proyecto *" type="date" {...register("fechaTerm")} />
          </div>
        </div>

        <SubTitle value="Pago de inscripción" />
        <div className="row">
          <div className="col-lg">
            <div className="form-check pb-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="pagoRequerido"
                id="reqPago"
                checked={pagoRequerido}
                {...register("pagoRequerido")}
                onClick={() => handleRequierePago(!pagoRequerido)}
              />
              <label className="form-check-label" htmlFor="newsletter">
                Requiere pago
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg">
            <InputUC
              name="aporteminimo"
              errores={errors.aporteminimo?.message}
              label="Aporte mínimo"
              type="number"
              defaultValue={informacion.minMonto}
              {...register("aporteminimo")}
              placeholder="Aporte mínimo"
              disabled={!pagoRequerido}
            />
          </div>

          <div className="col-lg">
            <InputUC
              name="aportesugerido"
              defaultValue={informacion.montoPreferido}
              errores={errors.aportesugerido?.message}
              label="Aporte sugerido"
              type="number"
              {...register("aportesugerido")}
              placeholder="Aporte sugerido"
              disabled={!pagoRequerido}
            />
          </div>
        </div>
        <SubTitle value="Información Adicional" />
        <div className="row">
          <div className="col-lg" style={{ zIndex: 99 }}>
            <Controller
              control={control}
              name="categorias"
              render={({ field }) => (
                <SelectUC
                  label="Categorías *"
                  dominio="categorias"
                  closeMenuOnSelect={false}
                  isMulti
                  errores={errors.categorias?.message}
                  {...field}
                />
              )}
            />
          </div>
          <div className="col-lg" style={{ zIndex: 98 }}>
            <Controller
              control={control}
              name="etiquetas"
              render={({ field }) => (
                <SelectUC label="Etiquetas *" dominio="etiquetas" closeMenuOnSelect={false} isMulti errores={errors.etiquetas?.message} {...field} />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 uc-form-group">
            <label>Cargar imagen o logotipo</label>

            <input
              name="base64"
              accept="image/png, .jpeg, .jpg, image/gif"
              type="file"
              data-max-size="512000"
              className="uc-input-style"
              {...register("base64")}
              onChange={toBase64}
              ref={imageRef}
            />
            {props.imagen !== null && (
              <div className="text-right mt-auto">
                <Link to="#" className="p-size--sm" onClick={() => setImagenModal(!imagenModal)}>
                  Ver/Ocultar imagen actual
                </Link>
                {imagenModal && (
                  <div className="uc-card mx-auto card-height--same mt-2" style={{ border: "1px solid #333333" }}>
                    <img
                      src={props.imagen}
                      className="card-img img-fluid"
                      alt="imagen de card"
                      style={{ height: "300px", width: "100%", objectFit: "cover" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="col-lg-4">
            <InputUC
              name="urlProyecto"
              errores={errors.urlProyecto?.message}
              label="Enlace al proyecto"
              placeholder="https://pastoral.uc.cl/"
              {...register("urlProyecto")}
              type="url"
            />
          </div>
          <div className="col-lg-4">
            <InputUC
              name="urlInstagram"
              errores={errors.urlInstagram?.message}
              label="Instagram"
              placeholder="https://instagram.com/usuario"
              {...register("urlInstagram")}
              type="url"
            />
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-12 justify-content-around py-4 d-flex">
            <ButtonUC type="submit" className="uc-btn btn-cta" style={{ maxWidth: "250px" }}>
              {id ? "Guardar" : "Crear proyecto"}
            </ButtonUC>
          </div>
        </div>
      </form>
    </div>
  );
}

export default InformacionGeneral;
