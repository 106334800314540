import React from "react";
import { useTable, usePagination, useSortBy } from "react-table";

function Table({ data, columns, initialState, pagination = true, id }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    rows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive py-4">
        <table className="uc-table table container" id={id} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ minWidth: "50px" }}>
                    {column.render("Header")}

                    <span>{column.isSorted ? column.isSortedDesc ? <i className="uc-icon text-white">arrow_drop_up</i> : <i className="uc-icon text-white">arrow_drop_down</i> : ""}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {pagination
              ? page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })
              : rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
      {pagination && (
        <div className="row d-flex">
          <nav className="uc-pagination">
            <button className="uc-pagination_prev mr-12" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {<i className="uc-icon">skip_previous</i>}
            </button>{" "}
            <button className="uc-pagination_prev mr-12" onClick={() => previousPage()} disabled={!canPreviousPage}>
              <i className="uc-icon">keyboard_arrow_left</i>
            </button>{" "}
            <span>
              Página{" "}
              <strong>
                {pageIndex + 1} de {pageOptions.length}
              </strong>{" "}
            </span>
            <button className="uc-pagination_next ml-12" onClick={() => nextPage()} disabled={!canNextPage}>
              <i className="uc-icon">keyboard_arrow_right</i>
            </button>{" "}
            <button className="uc-pagination_next ml-12" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {<i className="uc-icon">skip_next</i>}
            </button>{" "}
            {/* <span>
                    | Ir a la página :{" "}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const pageNumber = e.target.value
                                ? Number(e.target.value) - 1
                                : 0;
                            gotoPage(pageNumber);
                        }}
                        style={{ width: "50px" }}
                    />
                </span>{" "} */}
          </nav>
          <div className="justify-content-end d-flex">
            <div className="uc-form-group">
              <label>Resultados por página</label>
              <select className="uc-input-style" style={{ maxWidth: "100px" }} value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                {[10, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Table;
