import axios from 'axios';
const API = process.env.REACT_APP_BACKEND_URL;

const sendMonto = (data, token) => {
   return axios
      .post(`${API}webpay`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getPagos = (user, codSub, token) => {
   return axios
      .get(`${API}getPagos/${user}/${codSub}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getStatus = (codTrans, token) => {
   return axios
      .get(`${API}statusPago/${codTrans}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};
const WebPayService = {
   sendMonto,
   getPagos,
   getStatus,
};

export default WebPayService;
