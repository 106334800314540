import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import SubTitle from "../ui/SubTitle";
import ModalUC from "../ui/ModalUC";
import UserContext from "../../UserContext";
import AppContext from "../../AppContext";
import WebPayService from "../../services/WebPayService";

function PagarModal({ setPagarModal, curPro }) {
  const user = useContext(UserContext);
  const token = useContext(AppContext);

  const [ir, setIr] = useState(false);
  const [resWebPay, setResWebPay] = useState({});

  const MontoApagar = () => {
    const [monto, setMonto] = useState(curPro.minMonto);
    const [otroVisible, setOtroVisible] = useState(false);

    const handleMultipleAnswer = (e) => {
      setMonto(e.target.value);
      if (e.target.id !== "otroMonto") {
        if (e.target.id !== "otro") {
          setOtroVisible(false);
        } else {
          setOtroVisible(true);
        }
      }
    };
    const onBlur = (e, b) => {
      if (e.target.value < curPro.minMonto) setMonto(curPro.minMonto);
    };

    const confirmarPago = async () => {
      console.log("curPro", curPro);
      const data = {
        codProyecto: curPro.codProyecto,
        codSubProyecto: curPro.codSubProyecto,
        codUsuario: user.codUsuario,
        monto: monto.toString(),
        estado: 0,
      };
      if (monto === undefined) {
        alert("Debe seleccionar un monto");
        return false;
      }
      console.log("DATA", data);
      const res = await WebPayService.sendMonto(data, token);
      setResWebPay({ ...res, ...data });
      setIr(true);
    };
    return (
      <div>
        <SubTitle value="Seleccione monto" />
        <div className="col-lg-12 my-2 uc-form-group">
          <input type="radio" defaultChecked name="monto" id="minimo" style={{ fontWeight: "normal" }} onClick={handleMultipleAnswer} value={curPro.minMonto} /> <label for="minimo">Aporte mínimo</label>: $ {curPro.minMonto}
        </div>
        <div className="col-lg-12 my-2 uc-form-group">
          <input type="radio" name="monto" id="otro" style={{ fontWeight: "normal" }} onClick={handleMultipleAnswer} value={curPro.montoPref} /> <label for="otro">Otro</label> (Sugerido: $ {curPro.montoPref})
        </div>
        {otroVisible && (
          <div className="col-lg-6 my-2 uc-form-group">
            <label>Ingrese monto: </label>
            <input type="number" className="uc-input-style" min={curPro.minMonto} value={monto} id="otroMonto" onChange={handleMultipleAnswer} onBlur={onBlur} />
          </div>
        )}
        <center>
          {/* <button onClick={confirmarPago} className='uc-btn btn-primary'>Confirmar pago</button> */}
          <div className="uc-form-group">
            <Link onClick={confirmarPago} className="uc-btn btn-featured justify-content-between">
              Ir a pagar<i className="uc-icon mx-4">launch</i>
            </Link>
          </div>
        </center>
      </div>
    );
  };

  const ConfirmaPago = () => {
    const pagoRef = React.useRef(null);

    React.useEffect(() => {
      pagoRef?.current?.submit();
      console.log("hola");
    }, []);

    return (
      <div>
        <h4 className="mb-24">{"El monto a pagar es $" + resWebPay.monto} </h4>
        <h4 className="mb-24">Redirigiendo a WebPay</h4>
        <form method="post" action={resWebPay.url} id="formPagoWebPay" ref={pagoRef} style={{ display: "none" }}>
          <input type="hidden" name="token_ws" value={resWebPay.token} />
          <center>
            <input id="pagarbtn" type="submit" value="Ir a Webpay" className="uc-btn btn-cta" />
          </center>
        </form>
      </div>
    );
  };

  return (
    <ModalUC title="Pago de inscripción" setModal={setPagarModal} setAlertType="success" style={{ maxWidth: "250px", zIndex: 200 }}>
      {!ir && <MontoApagar />}
      {ir && <ConfirmaPago />}
    </ModalUC>
  );
}

export default PagarModal;
