import React, { useRef, useState } from 'react';
import ResCuestionario from './ResCuestionario';
import moment from 'moment';

import { useForm } from 'react-hook-form';

import TitleUC from '../ui/TitleUC';
import SubTitle from '../ui/SubTitle';
import SelectUC from '../ui/SelectUC';
import AlertUC from '../ui/AlertUC';
import { Link } from 'react-router-dom';

function FormInscripcion({
   proyecto,
   etiquetas,
   cuestionario,
   setCuestionario,
   onSubmit,
   subproyectos,
   selectSubproyecto,
   feedback,
   setFeedback,
   selectedSub,
}) {
   const {
      register,
      handleSubmit,
      control,
      formState: { errors },
   } = useForm({ mode: 'onBlur' });
   moment.locale('es');

   return (
      <div className="container-fluid">
         <TitleUC value={proyecto.nom} />
         <div className="row mb-32 ">
            <div className="col-lg-5">
               <div className="uc-card card-type--event ">
                  <img src={proyecto.urlLogo ? proyecto.urlLogo : '/catalogo_thumbnail.png'} className="card-img mb-4" alt="imagen de card" />
                  <div className="uc-card_body">
                     <div className="mb-20">
                        {etiquetas.map((etiqueta, key) => (
                           <Link to="#" key={key} className="uc-tag mx-2">
                              {etiqueta.label}
                           </Link>
                        ))}
                     </div>
                     <div>
                        <span className="uc-subtitle mt-20 mb-16">{proyecto.nom}</span>
                        <h4>{proyecto.descripcion}</h4>
                     </div>
                     <div className="uc-card_event--content">
                        <div className="date  mt-3">
                           <b>Fechas de inscripción</b> <br />
                           Desde el {moment(proyecto.fechaInscripIni).format('DD MMMM YYYY')} <br />
                           Hasta el {moment(proyecto.fechaInscripTerm).format('DD MMMM YYYY')}
                        </div>
                        <div className="date  mt-3">
                           <b> Fechas del proyecto</b> <br />
                           Desde el {moment(proyecto.fechaIni).format('DD MMMM YYYY')} <br />
                           Hasta el {moment(proyecto.fechaTerm).format('DD MMMM YYYY')}
                        </div>
                        <div className="mt-5">
                           <i className="uc-icon mr-1" style={{ color: '#333', fontSize: '16px' }}>
                              local_activity
                           </i>
                           <b>Costo de Inscripción</b> <br />
                           <div className="ml-20">
                              {proyecto.reqPago ? (
                                 <>
                                    Mínimo: ${proyecto.aporteminimo.toLocaleString()} <br />
                                    Sugerido: ${proyecto.aportesugerido.toLocaleString()}
                                 </>
                              ) : (
                                 <>Sin Costo</>
                              )}
                           </div>
                        </div>
                     </div>
                     {proyecto.urlProyecto && (
                        <div className="text-right mt-auto">
                           <a className="uc-btn btn-inline" href={proyecto.urlProyecto} target="blank" style={{ justifyContent: 'right' }}>
                              Mas información
                              <i className="uc-icon">launch</i>
                           </a>
                        </div>
                     )}
                     {proyecto.urlInstagram && (
                        <div className="text-right mt-auto">
                           <a className="uc-btn btn-inline mr-1" href={proyecto.urlInstagram} target="blank" style={{ justifyContent: 'right' }}>
                              Instagram
                              <i className="uc-icon">launch</i>
                           </a>
                        </div>
                     )}
                  </div>
               </div>
            </div>
            <div className="col-lg-7">
               <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                     <div className="col-lg-12 mx-auto">
                        <SubTitle value="Seleccionar subproyecto a inscribir" />
                        <SelectUC
                           name="subproyecto"
                           label="Seleccionar"
                           options={subproyectos}
                           onChange={(e) => selectSubproyecto(e.value)}
                           placeholder="Seleccionar..."
                           style={{ zIndex: 1010 }}
                        />
                     </div>
                  </div>
                  {/* {proyecto.reqPago &&
                            <div className='row'>
                                <div className='col-lg-12 mx-auto'>
                                    <SubTitle value='Pagos' />
                                    <div className='uc-form-group'>
                                        <label>Aporte minimo</label>: $ {proyecto.aporteminimo}
                                    </div>
                                    <div className='uc-form-group'>
                                        <label>Aporte sugerido</label> : $ {proyecto.aportesugerido}
                                    </div>
                                    <div className='uc-form-group'>
                                        <Link to="#" className="uc-btn btn-featured justify-content-between">Ir a pagar<i className="uc-icon mx-4">launch</i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        } */}

                  {cuestionario.length > 0 && (
                     <div className="row">
                        <div className="col-lg-12">
                           <div className="uc-form-group">
                              <SubTitle value="Cuestionario de proyecto" />
                              <ResCuestionario cuestionario={cuestionario} setCuestionario={setCuestionario} edit={false} />
                           </div>
                        </div>
                     </div>
                  )}
                  <input type="submit" className="uc-btn btn-cta mx-auto" value="Enviar inscripción" disabled={selectedSub === null} />
               </form>
               <div className="row">
                  <div className="col-lg-12 my-4">
                     {feedback.isOpen && (
                        <AlertUC
                           feedback={feedback}
                           setFeedback={setFeedback}
                           // postMsg=' Redirigiendo...'
                        />
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default FormInscripcion;
