import React, { useContext, useEffect, useState } from "react";
import Informacion from "./formularios/Informacion";
import AppContext from "../../../AppContext";
import { useParams, useHistory } from "react-router";
import ProyectosContainer from "./ProyectosContainer";
import Navbar from "./ui/Navbar";
import ProyectoService from "../../../services/ProyectoService";
import moment from "moment";
import AlertUC from "../../ui/AlertUC";
import SubTitle from "../../ui/SubTitle";

function CloneProyecto() {
  const history = useHistory();
  const token = useContext(AppContext);

  const [step, setStep] = useState(1);
  const [imagen, setImagen] = useState();
  const [informacion, setInformacion] = useState({});
  const [restricciones, setRestricciones] = useState(false);
  const [cuestionarios, setCuestionarios] = useState(false);
  const [subproyectos, setSubproyectos] = useState(false);
  const { idPro } = useParams();
  const [feedback, setFeedback] = useState({
    isOpen: false,
    realizado: false,
    id: null,
    respuesta: "",
    timestamp: "",
    errors: [],
  });
  const fetchInformacion = async () => {
    try {
      const response = await ProyectoService.fetchById(idPro, token);
      const formattedValues = {
        //   id: id,
        nom: response.nom,
        // idEstado: response.idEstado,
        descripcion: response.descripcion,
        fechaInscripIni: response.fechaInscripIni,
        fechaInscripTerm: response.fechaInscripTerm,
        fechaIni: response.fechaIni,
        fechaTerm: response.fechaTerm,
        // reqPago: response.reqPago,
        // aporteminimo: response.aporteminimo,
        // aportesugerido: response.aportesugerido,
        urlProyecto: response.urlProyecto,
        urlInstagram: response.urlInstagram,
        categorias: response.categorias,
        etiquetas: response.etiquetas,
      };
      setImagen(response.urlLogo);
      setInformacion(formattedValues);
    } catch (err) {
      console.log(err);
    }
  };

  const updateInformacion = async (data) => {
    const inscripIni = data.fechaInscripIni;
    const inscripTerm = data.fechaInscripTerm;
    const inicio = data.fechaIni;
    const termino = data.fechaTerm;
    const formattedValues = {
      //id: id,
      nom: data.nom,
      idEstado: data.idEstado,
      descripcion: data.descripcion,
      fechaInscripIni: moment(inscripIni).format("YYYY-MM-DD"),
      fechaInscripTerm: moment(inscripTerm).format("YYYY-MM-DD"),
      fechaIni: moment(inicio).format("YYYY-MM-DD"),
      fechaTerm: moment(termino).format("YYYY-MM-DD"),
      aporteminimo: data.aporteminimo,
      aportesugerido: data.aportesugerido,
      categorias: data.categorias,
      etiquetas: data.etiquetas,
      urlLogo: imagen,
      urlProyecto: data.urlProyecto,
      urlInstagram: data.urlInstagram,

      codUsuarioModif: 1,
      cloneId: idPro,
      clonarRestricciones: restricciones,
      clonarCuestionarios: cuestionarios,
      clonarSubproyectos: subproyectos,
    };

    try {
      console.log(formattedValues);
      const response = await ProyectoService.cloneProject(formattedValues, idPro, token);
      console.log(response);
      setFeedback({
        isOpen: true,
        ...response,
      });
      setTimeout(() => history.push(`/proyectos/editar/${response.id}?sub=true`), 2000);
    } catch (err) {
      console.log(err);
    }
    fetchInformacion();
  };

  useEffect(() => {
    fetchInformacion();
  }, []);

  return (
    <ProyectosContainer>
      <Navbar
        step={step}
        setStep={setStep}
        //  id={id}
      />
      {step === 1 && (
        <>
          <div className="container-fluid">
            <SubTitle value="Selecciona lo que deseas Clonar" />
            <div className="mb-2">
              <span onClick={() => setRestricciones(!restricciones)}>
                <input type="checkbox" checked={restricciones} onChange={(v) => setRestricciones(v.target.checked)} /> Restricciones
              </span>
            </div>
            <div className="mb-2">
              <span onClick={() => setCuestionarios(!cuestionarios)}>
                <input type="checkbox" checked={cuestionarios} onChange={(v) => setCuestionarios(v.target.checked)} /> Cuestionarios
              </span>
            </div>
            <div className="mb-2">
              <span onClick={() => setSubproyectos(!subproyectos)}>
                <input type="checkbox" checked={subproyectos} onChange={(v) => setSubproyectos(v.target.checked)} /> Subproyectos
              </span>
            </div>
          </div>
          <Informacion id={idPro} defaults={informacion} onSubmit={updateInformacion} imagen={imagen} setImagen={setImagen} />
        </>
      )}
      {feedback.isOpen && <AlertUC feedback={feedback} setFeedback={setFeedback} />}
    </ProyectosContainer>
  );
}

export default CloneProyecto;
