import * as Yup from "yup";
import moment from "moment";

const date = moment().format("l");

const informacionGeneralSchema = Yup.object().shape({
  nom: Yup.string().trim().max(255, "Este campo no puede superar los 255 caracteres.").required("Este campo es requerido."),
  idEstado: Yup.object().typeError("Este campo es requerido").required("Este campo es requerido."),
  descripcion: Yup.string().trim().max(255, "Este campo no puede superar los 255 caracteres."),
  fechaInscripIni: Yup.date().typeError("Este campo es requerido").required("Este campo es requerido"),
  fechaInscripTerm: Yup.date().typeError("Este campo es requerido").min(Yup.ref("fechaInscripIni"), "El fin de la postulación debe ser igual o posterior al inicio de la inscripción").required("Este campo es requerido"),
  fechaIni: Yup.date().typeError("Este campo es requerido").min(Yup.ref("fechaInscripTerm"), "El inicio del proyecto debe ser igual o posterior al fin de la postulación").required("Este campo es requerido"),
  fechaTerm: Yup.date().typeError("Este campo es requerido").min(Yup.ref("fechaIni"), "El fin del proyecto debe ser igual o posterior a su fecha de inicio").required("Este campo es requerido"),
  pagoRequerido: Yup.boolean(),
  aporteminimo: Yup.number()
    .nullable()
    .when("pagoRequerido", {
      is: true,
      then: Yup.number().typeError("Este campo debe ser un valor numérico").integer("Este campo debe ser un número entero").positive("Valores negativos no admitidos").required("Este campo es requerido"),
    }),
  aportesugerido: Yup.number()
    .nullable()
    .when("pagoRequerido", {
      is: true,
      then: Yup.number().typeError("Este campo debe ser un valor numérico").integer("Este campo debe ser un número entero").positive("Valores negativos no admitidos").required("Este campo es requerido"),
    }),
  categorias: Yup.array().typeError("Debe contener al menos una categoría").min(1, "Debe contener al menos una categoría"),
  etiquetas: Yup.array().typeError("Debe contener al menos una etiqueta").min(1, "Debe contener al menos una categoría"),
  urlLogo: Yup.mixed().nullable(),
  urlProyecto: Yup.string().trim().nullable().url("Debe ingresar una URL valida"),
  urlInstagram: Yup.string().trim().nullable().url("Debe ingresar una URL valida"),
});

export default informacionGeneralSchema;
