import axios from 'axios';

const API_USUARIOS = process.env.REACT_APP_BACKEND_URL + 'usuarios';

const fetchAll = (token) => {
   return axios
      .get(`${API_USUARIOS}/all`, {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data.respuesta)
      .catch((err) => console.log(err.response));
};

const fetchById = (id, token) => {
   return axios
      .get(`${API_USUARIOS}/back/${id}`, {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data.respuesta)
      .catch((err) => err.response.data);
};

const newUser = (data) => {
   return axios
      .post(API_USUARIOS, data, { withCredentials: true })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const create = (data, token) => {
   return axios
      .post(API_USUARIOS + '/save_or_update', data, {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const update = (data, token) => {
   return axios
      .post(API_USUARIOS + '/save_or_update', data, {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const remove = (id, token) => {
   return axios
      .delete(`${API_USUARIOS}/${id}`, {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const updateCorreos = (data, token) => {
   return axios
      .post(`${API_USUARIOS}/update_news/`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const updateActivo = (data, token) => {
   return axios
      .post(`${API_USUARIOS}/update_activ/`, data, {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const findVoluntario = (data, token) => {
   return axios.get(`${API_USUARIOS}/buscar_usuario/${data}`, { headers: { Authorization: `Bearer ${token}` } });
};

const cargaMasiva = (data, token) => {
   return axios
      .post(`${API_USUARIOS}/carga_masiva/`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const VoluntariosService = {
   fetchAll,
   create,
   fetchById,
   remove,
   updateCorreos,
   updateActivo,
   findVoluntario,
   update,
   cargaMasiva,
   newUser,
};

export default VoluntariosService;
