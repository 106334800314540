import * as Yup from 'yup';
import moment from 'moment';

const date = moment().format('l')

const NuevaActividadSchema =
    Yup.object().shape({
        nombre: Yup.string().trim().max(255, 'Este campo no puede superar los 255 caracteres.').required('Este campo es requerido.'),
        fechaIni: Yup.date()
            .typeError('Este campo es requerido')
          //  .min(date, 'El inicio de la actividad debe ser igual o posterior a hoy')
            .required('Este campo es requerido'),
        horaInicio : Yup.string().required('Este campo es requerido'),
        fechaTerm: Yup.date()
               // .min(Yup.ref('FechaIni'), 'El termino de la actividad debe ser igual o posterior a la fecha de inicio')
                .required('Este campo es requerido'),
        horaTermino: Yup.string().required('Este campo es requerido'),
        descripcion : Yup.string().required('Este campo es requerido'),
      
        
    })

export default NuevaActividadSchema