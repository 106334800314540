import axios from 'axios';

const API_PROYECTO = process.env.REACT_APP_BACKEND_URL + 'proyecto';
const API_PROYECTOS = process.env.REACT_APP_BACKEND_URL + 'proyectos';

const create = (data, token) => {
   return axios
      .post(`${API_PROYECTO}`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const fetchAll = (token, estado, busqueda) => {
   return axios
      .get(`${API_PROYECTOS}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         params: { estado: estado, busqueda: busqueda },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const fetchAllWithUser = (token) => {
   return axios
      .get(`${API_PROYECTOS}/withUsers`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const fetchById = (id, token) => {
   return axios
      .get(`${API_PROYECTO}/info_general/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data.respuesta)
      .catch((err) => err.response);
};

const updateInformacion = (data, id, token) => {
   return axios
      .post(`${API_PROYECTO}/`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const cloneProject = (data, id, token) => {
   return axios
      .post(`${API_PROYECTO}/clone`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const fetchRestricciones = (id, token) => {
   return axios
      .get(`${API_PROYECTO}/restriccion/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const updateRestricciones = (data, id, token) => {
   return axios
      .post(`${API_PROYECTO}/restriccion/${id}`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const fetchCuestionarios = (id, token, showAssociates = false) => {
   return axios
      .get(`${API_PROYECTO}/cuestionario/${id}?mostrar_asociados=${showAssociates}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const saveCuestionario = (data, token) => {
   return axios
      .post(`${API_PROYECTO}/cuestionario`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const updateCuestionario = (data, id, token) => {
   return axios
      .patch(`${API_PROYECTO}/cuestionario/${id}`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const cancelProyecto = (id, token) => {
   return axios
      .get(`${API_PROYECTO}/cancel/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};
// proyecto/cancel/{id_proyecto}

const ProyectoService = {
   fetchAll,
   fetchById,
   updateInformacion,

   fetchRestricciones,
   updateRestricciones,

   fetchCuestionarios,
   saveCuestionario,
   updateCuestionario,

   create,
   cancelProyecto,
   cloneProject,
   fetchAllWithUser,
};

export default ProyectoService;
