import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import JefaturasService from "../../services/JefaturasService";
import UserContext from "../../UserContext";
import Subproyectos from "../backoffice/proyectos/formularios/Subproyectos";
import { Button, UncontrolledCollapse } from "reactstrap";

function MisJefaturas(){
    const user = useContext(UserContext);
    const token = useContext(AppContext);
    const [jefaturas, setJefaturas] = useState([])

    const refreshMisJefaturas = async response => {
      const jef = await JefaturasService.getSubProyectos(user.codUsuario, token);
      setJefaturas(jef);
      alert(response.respuesta)
    }
    useEffect(() =>{
        JefaturasService.getSubProyectos(user.codUsuario, token).then(r => setJefaturas(r));
    }, [])

    const ListJefaturas = () => (jefaturas.map( j => (
        <div >
            <div className="uc-collapse uc-card">
                <a href="#" className="uc-collapse_heading" id={`toggle${j.codProyecto}`}>
                    
                        
                        <h4><h3 className="mb-12">{j.nomProyecto}</h3>{j.nomJefatura}</h4>
                    
                    <span className="uc-btn btn-inline ml-auto">
                        Ver más
                        <i className="uc-icon icon-shape--rounded">keyboard_arrow_down</i>
                    </span>
                </a>
                
                    <div className="uc-colapse_body">
                        <UncontrolledCollapse toggler={`toggle${j.codProyecto}`}>

                            <Subproyectos 
                            onJefaturas 
                            listSubproyectos={j.subProyectos} 
                            nivel={j.codDominioValor}
                            codProyecto={j.codProyecto}
                            refreshMisJefaturas={refreshMisJefaturas}
                            />
                        </UncontrolledCollapse>
                    </div>
                
            </div>
            {/* <h3>{j.nomProyecto}</h3>
            <h4>{j.nomJefatura}</h4>
            <Subproyectos 
                onJefaturas 
                listSubproyectos={j.subProyectos} 
                nivel={j.codDominioValor}
                codProyecto={j.codProyecto}
                refreshMisJefaturas={refreshMisJefaturas}
                /> */}
        </div>
    )))
    return(<div className="uc-accordion" data-accordion>{jefaturas.length === 0 ? "No tienes jefaturas asignadas" : <ListJefaturas />}</div>)
}

export default MisJefaturas;