import React, { useEffect, useState } from "react";

import { Redirect, useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { format } from "react-rut";
import VoluntariosService from "../../../services/VoluntariosService";

import Formulario from "./Formulario";
import AlertUC from "../../ui/AlertUC";

function CreateVoluntarioCas() {
  const history = useHistory();

  const [usrCas, setUsrCas] = useState({});
  const [dis, setDis] = useState(false);
  const search = useLocation().search;
  const userCas = new URLSearchParams(search).get("usrdt");
  const [feedback, setFeedback] = useState({
    isOpen: false,
    realizado: false,
    id: null,
    respuesta: "",
    timestamp: "",
    errors: [],
  });
  useEffect(() => {
    if (userCas != null) {
      setDis(true);
      let jsonFormat = JSON.parse(atob(userCas));
      jsonFormat.rut = format(jsonFormat.rut);
      setUsrCas(jsonFormat);
    }
  }, []);
  const submitHandler = async (data) => {
    console.log("receivedData", data);
    const newVoluntario = {
      //captcha
      captcha: data.captcha,

      //data personal
      nombres: data.nombres,
      apaterno: data.apaterno,
      amaterno: data.amaterno,
      rut: userCas ? usrCas.rut : data.rut,
      pasaporte: data.pasaporte,
      nacimiento: data.nacimiento,
      extranjero: data.extranjero.value,
      isPasaporte: data.isPasaporte,
      idgenero: data.genero.value,
      uid: data.uid,

      //data contacto
      telefono: data.telefono,
      correo: data.correo?.toLowerCase(),
      contrasena: userCas ? "P@st0Rra||" : data.contrasena,
      confirmarcontrasena: userCas ? "P@st0Rra||" : data.confirmarcontrasena,
      newsletter: data.newsletter,

      //data academica
      idcategoria: data.categoria?.value,
      idareaestudio: data.areaestudio?.value,
      idinstitucion: data.institucion?.value,
      otrainstitucion: data.otrainstitucion,
      idfacultad: null,
      ingreso: data.ingreso,

      region: data.region.value,
      comuna: data.comuna.value,

      //acepta terminos y condiciones
      condiciones: data.condiciones,

      //data de back
      idperfil: 2,
      idusuariomodif: null,
      activado: true,
      plazoadm: null,
      esCas: userCas?.uid ? "s" : "n",
      usuarioCAS: dis,
    };
    console.log("CreateVoluntarioCas.js", newVoluntario);
    const response = await VoluntariosService.newUser(newVoluntario);
    let postMsg = "";
    if (response.errors) {
      response.respuesta = response.errors.UniqueEmail || response.errors.PasswordParity || response.errors.ChileanRut;
    }

    if (response.respuesta === "Usuario creado satisfactoriamente") {
      postMsg = ". Redirigiendo...";
      setTimeout(() => history.push("/"), 2000);
    }

    setFeedback({
      isOpen: true,
      ...response,
      postMsg,
    });
  };

  return (
    <div>
      <Formulario onSubmit={submitHandler} defaults={usrCas} dis={dis} submitText="Registrar voluntario" useCaptcha />
      {feedback.isOpen && <AlertUC feedback={feedback} setFeedback={setFeedback} postMsg={feedback.postMsg} />}
    </div>
  );
}

export default CreateVoluntarioCas;
