import axios from 'axios';

const API_DOMINIOS = process.env.REACT_APP_BACKEND_URL + 'dominios';
const API_DOMVALOR = process.env.REACT_APP_BACKEND_URL + 'dominiovalor';

const fetchAllDominios = (token) => {
   return axios
      .get(API_DOMINIOS)
      .then((response) => response.data.respuesta)
      .catch((err) => console.log(err.response.data));
};

const fetchDominiosByNombre = (dominio) => {
   return axios
      .get(`${API_DOMINIOS}/${dominio}`)
      .then((response) => response.data.respuesta)
      .catch((err) => console.log(err.response.data));
};

const create = (data, token) => {
   return axios
      .post(`${API_DOMVALOR}`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => console.log(err.response.data));
};

const remove = (id, token) => {
   return axios
      .delete(`${API_DOMVALOR}/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => console.log(err.response.data));
};

const findByNombre = async (nombre) => {
   return await axios.get(`/dominios?nombre=${nombre}`);
};

const fetchSelected = async (dominio, option) => {
   const response = await DominiosService.fetchDominiosByNombre(dominio);
   const selected = response.filter((response) => (response.id === option ? response : ''));

   return {
      value: selected[0] ? selected[0].id : '',
      label: selected[0] ? selected[0].nomValor : '',
   };
};

const DominiosService = {
   fetchAllDominios,
   fetchDominiosByNombre,
   create,
   remove,
   findByNombre,
   fetchSelected,
};

export default DominiosService;
