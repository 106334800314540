import React, { useContext, useState } from "react";
import { useHistory } from "react-router";

import VoluntariosService from "../../../services/VoluntariosService";

import Formulario from "./Formulario";
import AlertUC from "../../ui/AlertUC";
import AppContext from "../../../AppContext";

function CreateVoluntario() {
  const history = useHistory();
  const token = useContext(AppContext);
  const [feedback, setFeedback] = useState({
    isOpen: false,
    realizado: false,
    id: null,
    respuesta: "",
    timestamp: "",
    errors: [],
  });
  const [usr, setUsr] = useState({
    correo: "",
    rut: "",
  });

  const [dis, setDis] = useState(false);
  const submitHandler = async (data) => {
    const newVoluntario = {
      //captcha
      captcha: data.captcha,

      //data personal
      nombres: data.nombres,
      apaterno: data.apaterno,
      amaterno: data.amaterno,
      rut: data.rut,
      pasaporte: data.pasaporte,
      nacimiento: data.nacimiento,
      extranjero: data.extranjero.value,
      idgenero: data.genero.value,
      isPasaporte: data.isPasaporte,

      //data contacto
      telefono: data.telefono,
      correo: data.correo?.toLowerCase(),
      contrasena: data.contrasena,
      confirmarcontrasena: data.confirmarcontrasena,
      newsletter: data.newsletter,

      //data academica
      idcategoria: data.categoria?.value,
      idareaestudio: data.areaestudio?.value,
      idinstitucion: data.institucion?.value,
      otrainstitucion: data.otrainstitucion,
      idfacultad: null,
      ingreso: data.ingreso,

      region: data.region.value,
      comuna: data.comuna.value,

      //acepta terminos y condiciones
      condiciones: data.condiciones,

      //data de back
      idperfil: 2,
      idusuariomodif: 1,
      activado: true,
      plazoadm: null,
      esCas: "n",
      usuarioCAS: dis,
    };
    console.log("CreateVoluntario.js", newVoluntario);
    const response = await VoluntariosService.create(newVoluntario, token);
    let postMsg = "";
    if (response.errors) {
      response.respuesta = response.errors.UniqueEmail || response.errors.PasswordParity || response.errors.ChileanRut;
    } else if (response.respuesta === "Usuario creado satisfactoriamente") {
      postMsg = ". Redirigiendo...";
      setTimeout(() => history.push("/voluntarios"), 2000);
    }
    setFeedback({
      isOpen: true,
      ...response,
      postMsg,
    });
  };

  return (
    <div>
      <Formulario onSubmit={submitHandler} defaults={usr} dis={dis} submitText="Registrar voluntario" />
      {feedback.isOpen && <AlertUC feedback={feedback} setFeedback={setFeedback} postMsg={feedback.postMsg} />}
    </div>
  );
}

export default CreateVoluntario;
