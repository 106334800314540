import React from "react";

function Modal({ children, setModal, icon, type, title, width, ...props }) {
  return (
    <div className="uc-modal pt-4">
      <div className={`uc-modal_content uc-message ${type}`} style={width ? { maxWidth: width } : {}}>
        <span className="uc-message_close-button" onClick={() => setModal(false)}>
          <i className="uc-icon">close</i>
        </span>
        <div className="uc-message_body">
          <h2 className="mb-24">
            <i className="uc-icon warning-icon p-4 ">info</i>
            {title}
          </h2>
          <hr className="uc-hr my-32" />
          <div className="row justify-content-center ">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
