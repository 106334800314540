import axios from 'axios';
const API_SUBPROYECTO = process.env.REACT_APP_BACKEND_URL + 'subproyecto';
const API_ACTIV = process.env.REACT_APP_BACKEND_URL + 'activ';
const API_LISTA = process.env.REACT_APP_BACKEND_URL + 'lista_espera';

const create = (data, token) => {
   return axios
      .post(`${API_SUBPROYECTO}`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const get = (id,token, hideNotVisible = false) => {
   return axios
      .get(`${API_SUBPROYECTO}s/list/${id}?ocultar_no_visibles=${hideNotVisible}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data.respuesta)
      .catch((err) => err.response.data);
};

const deleteSub = (id, token) => {
   return axios
      .delete(`${API_SUBPROYECTO}/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const createActiv = (data, token) => {
   return axios
      .post(`${API_ACTIV}`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};
const deleteAct = (id, token) => {
   return axios
      .delete(`${API_ACTIV}/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};
const getActividad = (subProyecto, token) => {
   return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}ins/act/${subProyecto}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response);
};

const getLista = (idPro, idSub, loadQuestionnaireAnswers, token) => {
   return axios
      .get(`${API_LISTA}/subproyecto/${idPro}?cod_sub_proyecto=${idSub}&cargar_respuestas=${loadQuestionnaireAnswers}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data.respuesta)
      .catch((err) => err.response);
};
const SubProyectoService = {
   create,
   get,
   deleteSub,
   createActiv,
   deleteAct,
   getActividad,
   getLista,
   // fetchAll
};

export default SubProyectoService;
