import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const findByYears = (anios, token) => {
   return axios
      .get(`${API_URL}reporte/filtro/proyecto?begin_year=${anios.begin}&end_year=${anios.end}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data.respuesta)
      .catch((err) => console.log(err.response.data));
};

const getReporte = (data, token) => {
   return axios
      .post(`${API_URL}reporte`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getReportePagos = (id, token) => {
   return axios
      .get(`${API_URL}reporte_pago/${id}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const findProyecto = (busqueda, token) => {
   return axios
      .get(`${API_URL}proyecto/buscar/${busqueda}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getProyecto = (proyectId, token) => {
   return axios
      .get(`${API_URL}subproyectos/list/${proyectId}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

// const updateRestricciones = (data, id, token) => {
//     return axios.post(`${API_PROYECTO}/restriccion/${id}`, data, {
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`
//         }
//     })
//         .then(response => response.data)
//         .catch(err => err.response.data)
// }

const ReportesService = {
   findByYears,
   getReporte,
   getReportePagos,
   findProyecto,
   getProyecto,
};

export default ReportesService;
