import React from 'react'

export const TitleUC = ({value}) => {
    return (
        <div className='pb-4'>
                <h2>{value}</h2>
        </div>
    )
}

export default TitleUC
