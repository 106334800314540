import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import AppContext from '../../../AppContext';
import UserContext from '../../../UserContext';
import AuthService from '../../../services/AuthService';
import DominiosService from '../../../services/DominiosService';
import VoluntariosService from '../../../services/VoluntariosService';
import AlertUC from '../../ui/AlertUC';
import Formulario from './Formulario';

function EditVoluntario() {
   const token = useContext(AppContext);
   const user = useContext(UserContext);

   const currentPath = window.location.pathname;
   const [dis, setDis] = useState(false);
   const [feedback, setFeedback] = useState({
      isOpen: false,
      data: [],
   });

   const { id } = useParams();
   let Rid = id;
   if (!Rid) {
      Rid = user.codUsuario;
   }
   const [voluntario, setVoluntario] = useState({});
   const paises = [
      { value: false, label: 'Chileno' },
      { value: true, label: 'Extranjero' },
   ];

   const fetchVoluntario = async (id) => {
      const response = await VoluntariosService.fetchById(id, token);
      const areaestudio = DominiosService.fetchSelected('areas_estudio', response.idareaestudio);
      const genero = DominiosService.fetchSelected('genero', response.idgenero);
      const categoria = DominiosService.fetchSelected('categorias', response.idcategoria);
      const institucion = DominiosService.fetchSelected('instituciones', response.idinstitucion);
      const region = DominiosService.fetchSelected('regiones', response.region);
      const comuna = DominiosService.fetchSelected('comunas', response.comuna);
      const res = await Promise.all([areaestudio, genero, categoria, institucion, region, comuna]);
      console.log(res);
      if (res[0].value === '') {
         res[0] = await DominiosService.fetchSelected('unidades', response.idareaestudio);
      }
      if (res[0].value === '') {
         res[0] = await DominiosService.fetchSelected('participacion', response.idareaestudio);
      }
      if (res[3].value === '') {
         res[3] = await DominiosService.fetchSelected('cursos', response.idinstitucion);
      }
      const pais = paises.filter((opcion) => (opcion.value === response.extranjero ? opcion : ''));
      const formattedValues = {
         id: id,
         nombres: response.nombres,
         apaterno: response.apaterno,
         amaterno: response.amaterno,
         rut: response.rut,
         uid: response.uid,
         pasaporte: response.pasaporte,
         isPasaporte: response.isPasaporte,

         correo: response.correo?.toLowerCase(),
         telefono: response.telefono,
         nacimiento: response.nacimiento,
         facultad: response.idfacultado,

         ingreso: response.ingreso,
         extranjero: pais[0],
         newsletter: response.newsletter,

         areaestudio: res[0],
         otrainstitucion: response.otrainstitucion,
         activado: response.activado,
         plazoadm: response.plazoadm,
         condiciones: response.condiciones,

         perfil: response.idperfil,
         genero: res[1],
         categoria: res[2],
         institucion: res[3],
         region: res[4],
         comuna: res[5],
         idusuariomodif: response.idusuariomodif,
         is_cas: response.usuarioCAS,
      };
      setVoluntario(formattedValues);
   };

   useEffect(() => {
      fetchVoluntario(Rid);
   }, []);

   const onSubmit = async (data) => {
      console.log(data);
      const newVoluntario = {
         id: Rid,
         captcha: data.captcha,
         nombres: data.nombres,
         apaterno: data.apaterno,
         amaterno: data.amaterno,
         rut: data.rut ? data.rut : '',
         pasaporte: data.pasaporte ? data.pasaporte : '',
         isPasaporte: data.isPasaporte,
         uid: data.uid,

         correo: data.correo?.toLowerCase(),
         telefono: data.telefono,
         nacimiento: data.nacimiento,
         idfacultad: data.facultad,

         ingreso: data.ingreso,
         extranjero: data.extranjero.value,
         newsletter: data.newsletter,
         contrasena: dis ? 'P@st0Rra||' : data.contrasena,
         confirmarcontrasena: dis ? 'P@st0Rra||' : data.confirmarcontrasena,

         idareaestudio: data.areaestudio?.value,
         activado: data.activado,
         plazoadm: null,

         condiciones: data.condiciones,
         usuarioCAS: data.is_cas,

         idperfil: data.perfil,
         idgenero: data.genero.value,
         idcategoria: data.categoria.value,
         idinstitucion: data.institucion?.value,
         otrainstitucion: data.otrainstitucion,

         region: data.region.value,
         comuna: data.comuna.value,

         idusuariomodif: 1,
      };
      console.log('EditVoluntario.js', newVoluntario);
      let response = await VoluntariosService.update(newVoluntario, token);
      if (response.errors && Object.keys(response.errors).length > 0) response.respuesta = Object.values(response.errors)[0];

      // If user changes email,
      console.log(response);
      if (response.realizado && user.email === data.correo) {
         setFeedback({
            isOpen: true,
            realizado: true,
            respuesta: 'Debido a que has modificado el correo por temas de seguridad te redireccionaremos para que inicies sesión nuevamente',
         });
         setTimeout(() => {
            AuthService.logout({ setAuth: () => {}, user });
         }, 4000);
      } else {
         setFeedback({
            isOpen: true,
            ...response,
         });
      }
      // setTimeout(()=> history.push('/voluntarios/'), 3000)
   };

   useEffect(() => {
      setDis(voluntario.is_cas || currentPath !== '/mi-cuenta');
   }, [voluntario]);
   return (
      <div>
         <Formulario onSubmit={onSubmit} defaults={voluntario} submitText="Guardar Cambios" dis={dis} />
         {feedback.isOpen && <AlertUC feedback={feedback} setFeedback={setFeedback} />}
      </div>
   );
}

export default EditVoluntario;
