import React from 'react';
import TitleUC from '../../../ui/TitleUC';

const MenuVerVoluntarios = (props) => {
   return (
      <div>
         {/* Tabs mobile se muestran como Select */}
         <div className="d-flex justify-content-between">
            <div className="uc-card card-bg--gray card-radius--none card-border--none d-block d-lg-none mb-32">
               <div className="uc-card_body">
                  <label htmlFor="tabSelect">{/* <strong>Seleccione tab</strong> */}</label>
                  <select
                     name="tabSelect"
                     id="tabSelect"
                     className="uc-input-style"
                     data-tabselect
                     onChange={(val) => {
                        let link = 0;
                        switch (val.target.value) {
                           case 'tab-01':
                              link = 1;
                              break;
                           case 'tab-02':
                              link = 2;
                              break;
                           case 'tab-03':
                              link = 3;
                              break;
                           case 'tab-04':
                              link = 4;
                              break;
                           default:
                              break;
                        }
                        props.setStep(link);
                     }}
                  >
                     <option value="tab-01">Información General</option>
                     <option value="tab-02">Restricciones</option>
                     <option value="tab-03">Cuestionario</option>
                     <option value="tab-04">Subproyectos</option>
                  </select>
               </div>
            </div>
            {/* Tabs en desktop se muestran como botones */}
            <ul className="uc-tabs d-none d-lg-flex">
               <li className="uc-tabs_item">
                  <a className={`uc-tabs_item-link nav-button ${props.step === 0 ? 'active' : ''} `} onClick={() => props.setStep(0)}>
                     Información Inscripción
                  </a>
               </li>
               <li className="uc-tabs_item">
                  <a className={`uc-tabs_item-link nav-button ${props.step === 6 ? 'active' : ''} `} onClick={() => props.setStep(6)}>
                     Pagos
                  </a>
               </li>
               <li className="uc-tabs_item">
                  <a className={`uc-tabs_item-link nav-button ${props.step === 1 ? 'active' : ''} `} onClick={() => props.setStep(1)}>
                     Registro
                  </a>
               </li>
               <li className="uc-tabs_item">
                  <a
                     className={`uc-tabs_item-link nav-button ${props.step === 2 ? 'active' : ''} `}
                     // disabled={id ? false : true}
                     onClick={() => props.setStep(2)}
                  >
                     Cuestionario
                  </a>
               </li>
               <li className="uc-tabs_item">
                  <a
                     className={`uc-tabs_item-link nav-button ${props.step === 3 ? 'active' : ''} `}
                     // disabled={id ? false : true}
                     onClick={() => props.setStep(3)}
                  >
                     Bitacora
                  </a>
               </li>
               <li className="uc-tabs_item">
                  <a
                     className={`uc-tabs_item-link nav-button ${props.step === 4 ? 'active' : ''} `}
                     // disabled={id ? false : true}
                     onClick={() => props.setStep(4)}
                  >
                     Historial de proyectos
                  </a>
               </li>
               <li className="uc-tabs_item">
                  <a
                     className={`uc-tabs_item-link nav-button ${props.step === 5 ? 'active' : ''} `}
                     // disabled={id ? false : true}
                     onClick={() => props.setStep(5)}
                  >
                     Actividades{' '}
                  </a>
               </li>
            </ul>
         </div>
      </div>
   );
};

export default MenuVerVoluntarios;
