import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import VoluntariosService from '../../../services/VoluntariosService';
import ProyectoService from '../../../services/ProyectoService';
import PerfilService from '../../../services/PerfilService';
import DeleteVoluntario from './DeleteVoluntario';
import ModalUC from '../../ui/ModalUC';
import SelectUC from '../../ui/SelectUC';
import AppContext from '../../../AppContext';
import UserContext from '../../../UserContext';
import { TitleUC } from '../../ui/TitleUC';
import { ButtonUC } from '../../ui/ButtonUC';
import AlertUC from '../../ui/AlertUC';
import Table from '../../ui/Table';
import Denegado from '../../Auth/Denegado';
import ModalCargaMasiva from './ModalCargaMasiva';
import { prettifyRut } from 'react-rut-formatter';
import DominiosService from '../../../services/DominiosService';
import * as XLSX from 'xlsx';

const filters = { activado: null, text: '', correoMasivo: null, proyecto: null, categorias: [], genero: null, etiquetas: [] };

export default function Voluntarios() {
   const history = useHistory();
   const token = useContext(AppContext);
   const user = useContext(UserContext);

   const [voluntarios, setVoluntarios] = useState([]);
   const [currentId, setCurrentId] = useState([]);
   const [currentVoluntario, setCurrentVoluntario] = useState({});
   const [permiso, setPermiso] = useState(false);
   const [listPerfil, setListPerfil] = useState({});
   const [curPerfil, setCurPerfil] = useState({});
   const [perfilModal, setPerfilModal] = useState(false);
   const [generos, setGeneros] = useState([]);
   const [ano, setAno] = useState([]);
   const [area, setArea] = useState([]);
   const [categorias, setCategorias] = useState([]);
   const [proyectos, setProyectos] = useState([]);
   const [fullProyectos, setFullProyectos] = useState();
   const [etiquetas, setEtiquetas] = useState([]);
   const [feedback, setFeedback] = useState({
      isOpen: false,
      realizado: false,
      id: null,
      respuesta: '',
      timestamp: '',
      errors: [],
   });

   const [deleteModal, setDeleteModal] = useState(false);
   const [modalCargaMasiva, setModalCargaMasiva] = useState(false);
   const [message, setMessage] = useState('');
   const [alertType, setAlertType] = useState('');

   useEffect(() => {
      setPermiso(PerfilService.permisoHandler(user, 'Voluntarios'));
      fetchVoluntarios();
   }, []);

   const [fullVoluntarios, setFullVoluntarios] = useState([]);

   const filter = () => {
      console.log(filters);
      let filteredVoluntarios = JSON.parse(JSON.stringify(fullVoluntarios));
      if (filters.activado !== null) {
         filteredVoluntarios = filteredVoluntarios.filter((voluntario) => voluntario.activado === filters.activado);
      }
      if (filters.correoMasivo !== null) {
         filteredVoluntarios = filteredVoluntarios.filter((voluntario) => voluntario.recibComunic === filters.correoMasivo);
      }
      if (filters.genero !== null) {
         filteredVoluntarios = filteredVoluntarios.filter((voluntario) => voluntario.codGenero === filters.genero);
      }
      if (filters.categorias.length > 0) {
         let v = filteredVoluntarios.filter((voluntario) => voluntario.codCategoria === filters.categorias[0].value);
         for (let i = 1; i < filters.categorias.length; i++) {
            v = [...v, ...filteredVoluntarios.filter((voluntario) => voluntario.codCategoria === filters.categorias[i].value)];
         }
         filteredVoluntarios = v.filter((v, i, s) => s.indexOf(v) === i);
      }
      if (filters.proyecto !== null) {
         for (let i = 0; i < fullProyectos.length; i++) {
            if (filters.proyecto.value === fullProyectos[i].id) {
               const splitted = fullProyectos[i].usersProyecto?.split('|') || [];
               filteredVoluntarios = filteredVoluntarios.filter((voluntario) => splitted.includes(voluntario.codUsuario.toString()));
               break;
            }
         }
      }
      if (filters.etiquetas.length > 0) {
         let v = [];
         for (let i = 0; i < filters.etiquetas.length; i++) {
            for (let j = 0; j < fullProyectos.length; j++) {
               const tagsProyecto = fullProyectos[j].tagsProyecto.split('|');
               if (tagsProyecto.includes(filters.etiquetas[i].label)) {
                  const splitted = fullProyectos[j].usersProyecto?.split('|') || [];
                  v = [...v, ...filteredVoluntarios.filter((voluntario) => splitted.includes(voluntario.codUsuario.toString()))];
               }
            }
         }
         filteredVoluntarios = v.filter((v, i, s) => s.indexOf(v) === i);
      }

      if (filters.text !== '') {
         filteredVoluntarios = filteredVoluntarios.filter(
            (voluntario) =>
               voluntario.apellMat?.toLowerCase().includes(filters.text.toLowerCase()) ||
               voluntario.apellPat?.toLowerCase().includes(filters.text.toLowerCase()) ||
               voluntario.nom?.toLowerCase().includes(filters.text.toLowerCase()) ||
               voluntario.nomPerfil?.toLowerCase().includes(filters.text.toLowerCase()) ||
               voluntario.email?.toLowerCase().includes(filters.text.toLowerCase()) ||
               voluntario.rut?.toLowerCase().includes(filters.text.toLowerCase())
         );
      }
      if (filters.ano) {
         console.log('🚀 ~ file: VoluntariosComponent.js ~ line 118 ~ filter ~ ano', filters.ano);
         filteredVoluntarios = filteredVoluntarios.filter((voluntario) => voluntario.anoIngreso?.toString() === filters.ano.toString());
      }
      if (filters.area) {
         filteredVoluntarios = filteredVoluntarios.filter((voluntario) => voluntario.areaEstudio?.toString() === filters.area.toString());
      }

      console.log(voluntarios);
      setVoluntarios(filteredVoluntarios);
   };
   const fetchVoluntarios = async () => {
      let voluntarios = VoluntariosService.fetchAll(token);
      let perfiles = PerfilService.getPerfiles(token);
      let areasEstudio = DominiosService.fetchDominiosByNombre('areas_estudio');
      let generos = DominiosService.fetchDominiosByNombre('genero');
      let categorias = DominiosService.fetchDominiosByNombre('categorias');
      let instituciones = DominiosService.fetchDominiosByNombre('instituciones');
      let regiones = DominiosService.fetchDominiosByNombre('regiones');
      let comunas = DominiosService.fetchDominiosByNombre('comunas');

      let unidades = DominiosService.fetchDominiosByNombre('unidades');

      let participacion = DominiosService.fetchDominiosByNombre('participacion');

      let cursos = DominiosService.fetchDominiosByNombre('cursos');

      let proyectos = ProyectoService.fetchAllWithUser(token);

      const resp = await Promise.all([
         voluntarios,
         perfiles,
         areasEstudio,
         generos,
         categorias,
         instituciones,
         regiones,
         comunas,
         unidades,
         participacion,
         cursos,
         proyectos,
      ]);
      voluntarios = resp[0];
      perfiles = resp[1];
      areasEstudio = resp[2];
      generos = resp[3];
      categorias = resp[4];
      instituciones = resp[5];
      regiones = resp[6];
      comunas = resp[7];
      unidades = resp[8];
      participacion = resp[9];
      cursos = resp[10];
      proyectos = resp[11];

      voluntarios.forEach((usuario) => {
         for (let i = 0; i < perfiles.respuesta.length; i++) {
            if (usuario.codPerfil === perfiles.respuesta[i].id) {
               usuario.nomPerfil = perfiles.respuesta[i].nombre;
               break;
            }
         }
         for (let i = 0; i < areasEstudio.length; i++) {
            if (usuario.areaEstudio === areasEstudio[i].id) {
               usuario.areaEstudioNom = areasEstudio[i].nomValor;
               break;
            }
         }
         for (let i = 0; i < unidades.length; i++) {
            if (usuario.areaEstudio === unidades[i].id) {
               usuario.unidadNom = unidades[i].nomValor;
               break;
            }
         }
         for (let i = 0; i < participacion.length; i++) {
            if (usuario.areaEstudio === participacion[i].id) {
               usuario.participacionNom = participacion[i].nomValor;
               break;
            }
         }

         for (let i = 0; i < generos.length; i++) {
            if (usuario.codGenero === generos[i].id) {
               usuario.generoNom = generos[i].nomValor;
               break;
            }
         }

         for (let i = 0; i < categorias.length; i++) {
            if (usuario.codCategoria === categorias[i].id) {
               usuario.categoriaNom = categorias[i].nomValor;
               break;
            }
         }

         for (let i = 0; i < instituciones.length; i++) {
            if (usuario.codInstit === instituciones[i].id) {
               usuario.institucionNom = instituciones[i].nomValor;
               break;
            }
         }

         for (let i = 0; i < cursos.length; i++) {
            if (usuario.codInstit === cursos[i].id) {
               usuario.cursoNom = cursos[i].nomValor;
               break;
            }
         }

         for (let i = 0; i < regiones.length; i++) {
            if (usuario.region === regiones[i].id) {
               usuario.regionNom = regiones[i].nomValor;
               break;
            }
         }

         for (let i = 0; i < comunas.length; i++) {
            if (usuario.comuna === comunas[i].id) {
               usuario.comunaNom = comunas[i].nomValor;
               break;
            }
         }
      });
      setVoluntarios(voluntarios);
      const gen = [{ value: null, label: 'Todos los Géneros' }];
      generos.forEach((val) => {
         gen.push({ value: val.id, label: val.nomValor });
      });
      setGeneros(gen);
      const cat = [];
      categorias.forEach((val) => {
         cat.push({ value: val.id, label: val.nomValor });
      });
      setCategorias(cat);
      const proy = [{ value: null, label: 'Todos los Proyectos' }];
      let tags = [];
      proyectos.respuesta.forEach((val) => {
         proy.push({ value: val.id, label: val.nombreProyecto });
         tags.push(...val.tagsProyecto.split('|'));
      });
      setProyectos(proy);
      const etiquetas = [];
      tags = tags.filter((val, i, self) => self.indexOf(val) === i);
      tags.forEach((val, i) => {
         etiquetas.push({ value: i, label: val });
      });
      setEtiquetas(etiquetas);
      const aes = [{ value: null, label: 'Todas las Áreas de Estudio' }];
      areasEstudio.forEach((val) => {
         aes.push({ value: val.id, label: val.nomValor });
      });
      setArea(aes);

      const year = new Date().getFullYear();
      const anos = [{ value: null, label: 'Todos los Años' }];
      for (let i = 0; i <= 50; i++) {
         anos.push({ value: year - i, label: (year - i).toString() });
      }
      setAno(anos);
      setFullProyectos(proyectos.respuesta);
      setFullVoluntarios(voluntarios);
      // return response;
   };

   const newsletterHandler = async (rowValues) => {
      const data = {
         id: rowValues.codUsuario,
         comunic: rowValues.recibComunic === 's' ? 'n' : 's',
      };
      rowValues.recibComunic = rowValues.recibComunic === 's' ? 'n' : 's';
      const response = await VoluntariosService.updateCorreos(data, token);
      setFeedback({
         isOpen: true,
         ...response,
      });
   };

   const activeHandler = async (rowValues) => {
      const data = {
         id: rowValues.codUsuario,
         activ: rowValues.activado === 's' ? 'n' : 's',
      };
      rowValues.activado = rowValues.activado === 's' ? 'n' : 's';
      const response = await VoluntariosService.updateActivo(data, token);
      setFeedback({
         isOpen: true,
         ...response,
      });
   };

   const editModalHandler = (rowValues) => {
      setCurrentId(rowValues);
   };

   const deleteModalHandler = (rowValues) => {
      setCurrentVoluntario(rowValues, token);
      setDeleteModal(true);
   };

   const setPerfil = async (r) => {
      const res = await PerfilService.getUserPerfil(r.codPerfil, token);
      setListPerfil(res.respuesta);
      setCurPerfil(res.curPerfil);
      setPerfilModal(true);
      setCurrentVoluntario(r);
   };

   const changePerfil = async () => {
      const edit = {
         codUsuario: currentVoluntario.codUsuario,
         curPerfil: curPerfil.value,
      };
      try {
         const res = await PerfilService.updatePerfilUsuario(edit, token);

         res.body.respuesta ? alert(res.body.respuesta) : alert('No se pudo actualizar el perfil');
         setPerfilModal(false);
         fetchVoluntarios();
      } catch (err) {
         alert('No se pudo actualizar el perfil');
      }
   };

   const generateExcel = () => {
      const workbook = XLSX.utils.book_new();
      const data = [];
      voluntarios.forEach((val) => {
         data.push({
            id: val.codUsuario,
            Activo: val.activado,
            'Rut/ Passaporte': val.dv == null ? val.rut : prettifyRut(`${val.rut}${val.dv}`),
            Perfil: val.nomPerfil,
            Nombre: val.nom,
            'Apellido Paterno': val.apellPat,
            'Apellido Materno': val.apellMat,
            'Fecha de nacimiento': val.fechaNacim,
            Género: val.generoNom,
            Correo: val.email,
            'Correo Masivo': val.recibComunic,
            Teléfono: val.telefono,
            Región: val.regionNom,
            Comuna: val.comunaNom,
            Categoría: val.categoriaNom,
            'Área de estudio': val.areaEstudioNom,
            'Institución Educacion Superior': val.institucionNom,
            'Institucion Escolar': val.codCategoria === 9 ? val.otraInstit : null,
            Unidad: val.unidadNom,
            'Curso Actual': val.cursoNom,
            'Año de Ingreso': val.codCategoria !== 9 ? val.anoIngreso : null,
            'Año probable de egreso': val.codCategoria === 9 ? val.anoIngreso : null,
            Participación: val.participacionNom,
            'Otra Participacion': val.areaEstudio === 435 ? val.otraInstit : null,
         });
      });

      const sheet1 = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, sheet1, 'Área de estudio');

      XLSX.writeFile(workbook, 'Voluntarios.xlsx');
   };

   const columns = useMemo(
      () => [
         {
            Header: 'Acciones',
            accessor: 'actions',
            Cell: (props) => {
               const rowValues = props.row.original;
               return (
                  <div>
                     <Link to={`/voluntarios/editar-voluntario/${rowValues.codUsuario}`}>
                        <span onClick={() => editModalHandler(rowValues)} title="Editar">
                           <i className="uc-icon icon-shape--rounded m-1">edit</i>
                        </span>
                     </Link>
                     <span onClick={() => deleteModalHandler(rowValues)} title="Eliminar">
                        <i className="uc-icon icon-shape--rounded m-1">delete</i>
                     </span>
                     <span onClick={() => setPerfil(rowValues)} title="Perfil">
                        <i className="uc-icon icon-shape--rounded m-1">settings_accessibility</i>
                     </span>
                     <Link to={`/voluntarios/historial/${rowValues.codUsuario}/${rowValues.nom}-${rowValues.apellPat}`} title="Historial">
                        <i className="uc-icon icon-shape--rounded m-1">history</i>
                     </Link>
                  </div>
               );
            },
         },
         {
            Header: 'Rut / Pasaporte',
            accessor: 'codUsuario',
            Cell: (props) => {
               const rowValues = props.row.original;
               if (rowValues.dv == null) return rowValues.rut;

               return prettifyRut(`${rowValues.rut}${rowValues.dv}`);
            },
         },
         {
            Header: 'Nombre Completo',
            accessor: 'nom',
            Cell: (props) => {
               const rowValues = props.row.original;

               return `${rowValues.nom} ${rowValues.apellPat} ${rowValues.apellMat}`;
            },
         },
         {
            Header: 'Correo',
            accessor: 'email',
         },
         {
            Header: 'Perfil',
            accessor: 'nomPerfil',
         },
         {
            Header: 'Correo Masivo',
            accessor: 'recibComunic',
            Cell: (props) => {
               const rowValues = props.row.original;
               return (
                  <div className="form-check form-switch text-center px-0 mr-5">
                     <input
                        className="form-check-input mx-0"
                        type="checkbox"
                        name="newsletter"
                        checked={rowValues.recibComunic === 's' ? true : false}
                        onChange={() => newsletterHandler(rowValues)}
                     />
                  </div>
               );
            },
         },
         {
            Header: 'Activo',
            accessor: 'activado',
            Cell: (props) => {
               const rowValues = props.row.original;
               return (
                  <div className="form-check form-switch px-0 text-center mr-5">
                     <input
                        className="form-check-input mx-0"
                        type="checkbox"
                        name="activo"
                        checked={rowValues.activado === 's' ? true : false}
                        onChange={() => {
                           activeHandler(rowValues);
                        }}
                     />
                  </div>
               );
            },
         },
         {
            Header: 'genero',
            accessor: 'generoNom',
         },
      ],
      []
   );
   const initialState = {
      hiddenColumns: ['generoNom'],
   };

   const onChangeProyecto = () => {};

   return !permiso ? (
      <Denegado />
   ) : (
      <div className="container-fluid">
         <TitleUC value="Mantenedor de Voluntarios" />
         {feedback.isOpen && <AlertUC feedback={feedback} setFeedback={setFeedback} />}
         <div className="d-flex justify-content-between row ">
            <div className="col-lg-3 col-sm-6 py-2">
               <ButtonUC type="button" onClick={() => history.push('/voluntarios/inscribir-voluntario')}>
                  Inscribir Voluntario
               </ButtonUC>
            </div>
            <div className="col-lg-3 col-sm-6 py-2">
               <ButtonUC
                  type="button"
                  onClick={() => setModalCargaMasiva(true)}
                  // disabled={true}
               >
                  Carga masiva de voluntarios
               </ButtonUC>
            </div>
            <div className="col-lg-3 col-xs-0 py-2"></div>
            <div className="col-lg-3 py-2">
               <ButtonUC
                  type="button"
                  onClick={() => generateExcel()}
                  // disabled={true}
               >
                  Exportar Usuarios
               </ButtonUC>
            </div>
         </div>
         <div className="pt4"></div>

         <div className="d-flex justify-content-end row  pt-4">
            <div className="col-lg-4 ">
               <SelectUC
                  // dominio='estados_actividades'
                  options={proyectos}
                  onChange={(val) => {
                     filters.proyecto = val;
                     filter();
                  }}
                  defaultValue={{ value: null, label: 'Todos los proyectos' }}
                  placeholder="Todos los proyectos"
                  label="Proyecto:"
               />
            </div>
            <div className="col-lg-4 ">
               <SelectUC
                  // dominio='estados_actividades'
                  options={etiquetas}
                  onChange={(val) => {
                     filters.etiquetas = val;
                     filter();
                  }}
                  placeholder="Todas las Etiquetas"
                  label="Etiquetas:"
                  isMulti
               />
            </div>
            <div className="col-lg-4 ">
               <div className="uc-form-group" style={{ marginBottom: '0' }}>
                  <label>Buscar:</label>
                  <input
                     id="ucsearch"
                     type="text"
                     className="uc-input-style w-icon search"
                     placeholder="Buscar"
                     onChange={(val) => {
                        filters.text = val.target.value;
                        filter();
                     }}
                  />
               </div>
            </div>
         </div>
         <div className="d-flex justify-content-between row ">
            <div className="col-lg-4 ">
               <SelectUC
                  // dominio='estados_actividades'
                  options={categorias}
                  onChange={(val) => {
                     filters.categorias = val;
                     filter();
                  }}
                  placeholder="Todas las Categorías"
                  label="Categorías:"
                  isMulti
               />
            </div>
            <div className="col-lg-4 ">
               <SelectUC
                  // dominio='estados_actividades'
                  options={generos}
                  defaultValue={{ value: null, label: 'Todos los Géneros' }}
                  onChange={(val) => {
                     filters.genero = val.value;
                     filter();
                  }}
                  placeholder="Todos los Géneros"
                  label="Género:"
               />
            </div>
            <div className="col-lg-4 ">
               <SelectUC
                  // dominio='estados_actividades'
                  options={[
                     { value: null, label: 'Todos' },
                     { value: 's', label: 'Recibe correo masivo' },
                     { value: 'n', label: 'No recibe correo masivo' },
                  ]}
                  defaultValue={{ value: null, label: 'Todos' }}
                  onChange={(val) => {
                     filters.correoMasivo = val.value;
                     filter();
                  }}
                  placeholder="Recibe correo Masivo..."
                  label="Correo Masivo:"
               />
            </div>
         </div>
         <div className="d-flex justify-content-between row ">
            <div className="col-lg-4 ">
               <SelectUC
                  // dominio='estados_actividades'
                  options={area}
                  onChange={(val) => {
                     filters.area = val.value;
                     filter();
                  }}
                  defaultValue={{ value: null, label: 'Todas las Áreas de Estudio' }}
                  placeholder="Todos los proyectos"
                  label="Área de Estudio:"
               />
            </div>
            <div className="col-lg-4 ">
               <SelectUC
                  // dominio='estados_actividades'
                  options={ano}
                  onChange={(val) => {
                     filters.ano = val.value;
                     filter();
                  }}
                  defaultValue={{ value: null, label: 'Todos los Años' }}
                  placeholder="Todos los Años"
                  label="Año de ingreso del voluntario:"
               />
            </div>

            <div className="col-lg-3  ">
               <div className="uc-form-group" style={{ marginBottom: '0' }}>
                  <label>Usuarios:</label>
               </div>
               <div className="uc-form-group uc-radio-group">
                  <input
                     type="radio"
                     id="switch_1"
                     name="switch"
                     defaultValue="si"
                     className="uc-orig-radio"
                     onClick={() => {
                        filters.activado = 's';
                        filter();
                     }}
                  />
                  <label htmlFor="switch_1" className="uc-radio-button">
                     Activos
                  </label>
                  <input
                     type="radio"
                     id="switch_2"
                     name="switch"
                     defaultValue="no"
                     className="uc-orig-radio"
                     onClick={() => {
                        filters.activado = 'n';
                        filter();
                     }}
                  />
                  <label htmlFor="switch_2" className="uc-radio-button">
                     Inactivo
                  </label>
                  <input
                     type="radio"
                     id="switch_3"
                     name="switch"
                     defaultValue="maybe"
                     className="uc-orig-radio"
                     defaultChecked
                     onClick={() => {
                        filters.activado = null;
                        filter();
                     }}
                  />
                  <label htmlFor="switch_3" className="uc-radio-button">
                     Todos
                  </label>
               </div>
            </div>
            <div className="col-lg-1 "></div>
         </div>
         <Table columns={columns} data={voluntarios} initialState={initialState} />
         {deleteModal && (
            <DeleteVoluntario
               currentVoluntario={currentVoluntario}
               token={token}
               setModal={setDeleteModal}
               setMessage={setMessage}
               setAlertType={setAlertType}
               fetchVoluntarios={fetchVoluntarios}
               feedback={feedback}
               setFeedback={setFeedback}
            />
         )}

         {perfilModal && (
            <ModalUC
               title={`Editar Perfil de ${currentVoluntario.nom} ${currentVoluntario.apellPat}`}
               setModal={setPerfilModal}
               setAlertType="success"
               style={{ maxWidth: '550px', zIndex: 200 }}
            >
               <SelectUC label="Seleccione Perfil" options={listPerfil} defaultValue={curPerfil} onChange={(e) => setCurPerfil(e)} />

               <ButtonUC type="button" className="uc-btn btn-cta" onClick={changePerfil}>
                  Actualizar perfil
               </ButtonUC>
            </ModalUC>
         )}
         {modalCargaMasiva && <ModalCargaMasiva setModal={setModalCargaMasiva} setMessage={setMessage} setAlertType={setAlertType} token={token} />}
      </div>
   );
}
