import React from 'react'
import { useForm, Controller } from 'react-hook-form';
import DominiosService from '../../../services/DominiosService';
import ModalUC from '../../ui/ModalUC'
import InputUC from '../../ui/InputUC';
import SelectUC from '../../ui/SelectUC';
import dominioValidationSchema from './dominiosValidationSchema'
import { yupResolver } from '@hookform/resolvers/yup';


export default function CreateDominio ({ setModal, currentId, setMessage, setAlertType, setCurrentId, changeCurrent, currentString, token }) {
    
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(dominioValidationSchema)
    });

    const submitHandler = async (data) => {
        const newDominio = {
            codDominio: currentId,
            nomValor: data.valor,
            text: data.text,
            descrip: data.descrip,
            opc: 1,
            oculto: data.oculto.value,
            idUsuario: null,
            protegido: 'n'
        }

        const response = await DominiosService.create(newDominio, token)
        setMessage(response.respuesta)
        response.realizado === true ? setAlertType('success') : setAlertType('error')
        changeCurrent(currentString, currentId)
        setModal(false)
    }

    const ocultoOption = [{ value: 'n', label: 'No' }, { value: 's', label: 'Si' }]

    return (
        <ModalUC setModal={setModal}
            title='Agregar nuevo Valor'
            icon='success'
            type='success'
        >

            <form onSubmit={handleSubmit(submitHandler)}>
                <InputUC
                    name='valor'
                    label='Valor'
                    placeholder='Valor'
                    type='text'
                    errores={errors.valor?.message}
                    {...register('valor', { required: true })}
                />

                <Controller
                    name='oculto'
                    control={control}
                    rules={{ required: true }}
                    defaultValue={{ value: 'n', label: 'No' }}
                    render={({ field }) => (
                        <SelectUC
                            label='Oculto'
                            options={ocultoOption}
                            errores={errors.oculto?.message}
                            {...field}
                        />)}
                />
                <div className='d-flex justify-content-center'>
                    <input className='uc-btn btn-cta' type='submit' value='Guardar' />
                </div>
            </form>
            
            </ModalUC>
    )
}

