import React, {useState} from 'react'

import ModalUC from '../../ui/ModalUC'




export default function PerfilModal ({ setOpenModal, perfil, setPerfil, submit, openModal }) {
    
    const changeValue = (val) => {
         const indexPermiso = perfil.permisos.findIndex(p => p.id == val.id);
         perfil.permisos[indexPermiso].permiso = !perfil.permisos[indexPermiso].permiso
         const refreshPerfil = perfil
        setPerfil({...refreshPerfil})
    }
    const permisos = perfil.permisos ? perfil.permisos.map(p =>(
        <tr>
            <td>{p.funcion}</td>
            <td>
                <input type="checkbox" onChange={() => changeValue(p)} defaultChecked={p.permiso}/>
            </td>
        </tr>
    )) : "cargando..."
    
    const handleNombre = (e) => {
        let largo = e.target.value.trim()
        if(largo.length > 0){
            perfil.nombre = e.target.value;

            setPerfil({...perfil});
        }
    }

    return ( openModal &&
        <ModalUC 
            setModal={setOpenModal}
            title='Perfil'
            style={{ maxWidth: "250px"}}
        >   

        <center>
            <div className="uc-form-group">
                <label for="ucinput">Nombre Perfil</label>
                <input type="text" className="uc-input-style mb-24" defaultValue={perfil.nombre} onChange={handleNombre}/>
            </div>
            <center>
                <table className="uc-table mb-24">
                    <thead>
                        <tr>
                            <th>Permiso</th> 
                            <th>Habilitado</th>   
                        </tr>
                    </thead>
                    <tbody>
                        {permisos}
                    </tbody>
                </table>
            </center>
            <div>
                <button onClick={submit} className="uc-btn btn-cta">Guardar</button>
            </div>
        </center>
        
            </ModalUC>
    )
}