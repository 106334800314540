import React from "react";
import SelectUC from "../ui/SelectUC";
import { Link } from "react-router-dom";

function Paginador(props) {
  const pageSizeOptions = [
    { value: 6, label: "6" },
    { value: 12, label: "12" },
    { value: 24, label: "24" },
  ];

  const paginador = () =>
    props.paginador.map((p, key) => (
      <li key={key} className={"page-item " + (p === props.currentPage ? "active" : "")}>
        <Link to={"/catalogo/" + p} onClick={(e) => props.handleCurrentPage(p)} className="page-link">
          {p}
        </Link>
      </li>
    ));

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-lg-2"></div>
      <div className="col-lg-8">
        <nav className="uc-pagination mx-auto">
          {props.currentPage > 1 && (
            <button className="uc-pagination_next ml-12">
              <Link to={"/catalogo/" + (props.currentPage - 1)} onClick={(e) => props.handleCurrentPage(props.currentPage - 1)}>
                <i className="uc-icon">keyboard_arrow_left</i>
              </Link>
            </button>
          )}
          <ul className="uc-pagination_pages">{paginador()}</ul>
          {props.currentPage < props.paginador.length && (
            <button className="uc-pagination_next ml-12">
              <Link to={"/catalogo/" + (props.currentPage + 1)} onClick={(e) => props.handleCurrentPage(props.currentPage + 1)}>
                <i className="uc-icon">keyboard_arrow_right</i>
              </Link>
            </button>
          )}
        </nav>
      </div>
      <div className="col-lg-2">
        <SelectUC label="Resultados por página" onChange={(e) => props.handleResPorPagina(e)} options={pageSizeOptions} defaultValue={pageSizeOptions[0]} placeholder="Resultados por página" />
      </div>
    </div>
  );
}

export default Paginador;
