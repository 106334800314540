import React, { useState, useEffect, useContext, useMemo } from 'react';
import DominiosService from '../../../services/DominiosService';

import Table from '../../ui/Table';
import PerfilModal from './PerfilModal';
import AppContext from '../../../AppContext';
import UserContext from '../../../UserContext';
import { Alerts } from '../../ui/Alerts';
import { ButtonUC } from '../../ui/ButtonUC';
import { TitleUC } from '../../ui/TitleUC';
import PerfilService from '../../../services/PerfilService';
import { set } from 'lodash';
import Denegado from '../../Auth/Denegado';

function PerfilComponent() {
   const token = useContext(AppContext);
   const user = useContext(UserContext);

   const [currentString, setCurrentString] = useState('');
   const [perfil, setPerfil] = useState([]);
   const [newPerfil, setNewPerfil] = useState({});
   const [perfiles, setPerfiles] = useState([]);
   const [permiso, setPermiso] = useState(false);

   const [openModal, setOpenModal] = useState(false);
   const [deleteModal, setDeleteModal] = useState(false);
   const [busqueda, setBusqueda] = useState('');
   const [message, setMessage] = useState('');
   const [alertType, setAlertType] = useState('');

   const addNewPerfil = () => {
      setPerfil(newPerfil);
      setOpenModal(true);
   };
   const submit = async () => {
      if (perfil.nombre.length == 0) {
         alert('Debe agregar un nombre');
         return;
      }
      let newPerfil = {
         permisos: JSON.stringify(perfil.permisos),
         nombre: perfil.nombre,
         eliminable: 's',
         modificable: 's',
         fechaActualiz: '',
         id: perfil.id,
      };
      newPerfil.permisos = JSON.stringify(perfil.permisos);
      console.log(perfil);
      const res = await PerfilService.savePerfil(newPerfil, token);
      alert(res.respuesta);
      setOpenModal(false);
      fetchPermisos();
      await actualizaTabla();
   };

   const editHandler = (rowValues) => {
      setPerfil(rowValues);
      setOpenModal(true);
   };

   const deleteHandler = async (rowValues) => {
      const { id, nombre } = rowValues;
      const tempPerfil = {
         id: id,
         nombre: nombre,
      };

      const res = await PerfilService.deletePerfil(tempPerfil, token);
      if (res.respuesta) {
         alert(res.respuesta);
         actualizaTabla();
      } else {
         alert('Ha ocurrido un error');
      }
   };

   const fetchPermisos = () => {
      DominiosService.fetchDominiosByNombre('funciones').then((response) => {
         let permisosArray = [];
         response.forEach((p) => {
            permisosArray.push({
               id: p.id,
               funcion: p.nomValor,
               permiso: false,
            });
         });
         setNewPerfil({
            nombre: '',
            eliminable: 's',
            modificable: 's',
            fechaActualiz: '',
            permisos: permisosArray,
         });
      });
   };

   const columns = useMemo(
      () => [
         {
            Header: 'Acciones',
            accessor: 'actions',
            Cell: (props) => {
               const rowValues = props.row.values;
               console.log('rowValues', rowValues);
               return (
                  <div>
                     {rowValues.modificable === 's' && (
                        <span onClick={() => editHandler(rowValues)} title="Editar">
                           <i className="uc-icon icon-shape--rounded mx-2">edit</i>
                        </span>
                     )}
                     {rowValues.eliminable === 's' && (
                        <span onClick={() => deleteHandler(rowValues)} title="Eliminar">
                           <i className="uc-icon icon-shape--rounded mx-2">delete</i>
                        </span>
                     )}
                  </div>
               );
            },
         },
         {
            Header: 'Perfil',
            accessor: 'nombre',
         },
         {
            Header: 'id',
            accessor: 'id',
         },
         {
            Header: 'Eliminable',
            accessor: 'eliminable',
            Cell: (props) => {
               return props.value === 's' ? 'Sí' : 'No';
            },
         },
         {
            Header: 'Modificable',
            accessor: 'modificable',
            Cell: (props) => {
               return props.value === 's' ? 'Sí' : 'No';
            },
         },
         {
            Header: 'Última actualización',
            accessor: 'fechaActualiz',
         },
         {
            Header: 'permisos',
            accessor: 'permisos',
         },
      ],
      []
   );

   const initialState = {
      hiddenColumns: ['id', 'permisos'],
   };

   const barraBuscar = (e) => {
      setBusqueda(e.target.value);
   };

   useEffect(() => {
      try {
         setPermiso(PerfilService.permisoHandler(user, 'Perfil'));
         actualizaTabla();
         fetchPermisos();
      } catch (err) {
         alert('Se ha producido un error');
         console.log(err);
      }
   }, []);

   const actualizaTabla = () => {
      let per = [];
      PerfilService.getPerfiles(token).then((res) => {
         per = res.respuesta;
         per.forEach((p) => {
            let j = JSON.parse(p.permisos);
            p.permisos = j;
         });
         console.log(per);
         setPerfiles([...per]);
      });
   };
   return !permiso ? (
      <Denegado />
   ) : (
      <div className="container">
         <TitleUC value="Mantenedor de perfiles" />
         <div className="row">
            <div className="col">{message && <Alerts message={message} setMessage={setMessage} alertType={alertType} />}</div>
         </div>

         <div className="d-flex justify-content-between row py-2">
            <div className="col-lg-4 py-2">
               <button id="buttonAgregarID" onClick={addNewPerfil} className="uc-btn btn-cta btn-primary">
                  Ingresar nuevo perfil
               </button>
            </div>
         </div>

         {<Table data={perfiles} columns={columns} initialState={initialState} />}

         <PerfilModal
            setOpenModal={setOpenModal}
            openModal={openModal}
            token={token}
            submit={submit}
            perfil={perfil}
            perfiles={perfiles}
            setPerfil={setPerfil}
            setPerfiles={setPerfiles}
            actualizaTabla={actualizaTabla}
         />
      </div>
   );
}

export default PerfilComponent;
