export default function Footer() {
  return (
    <footer className="uc-footer" style={{ position: "absolute", width: "100%", bottom: 0 }}>
      <div className="container pb-48">
        <div className="row">
          <div className="col-7 col-md-3 col-xl-2 mb-32">
            <a href="/">
              <img src="https://kit-digital-uc-prod.s3.amazonaws.com/assets/logo-uc-blanco.svg" alt="Logo" className="img-fluid" />
            </a>
          </div>
          <div className="col-lg-8 offset-lg-1">
            <div className="h2 color-white text-font--serif mt-28">ACUTIS - Pastoral UC</div>
          </div>
        </div>
      </div>
    </footer>
  );
}
