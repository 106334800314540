import React, { useContext, useEffect, useMemo, useState } from "react";

import ProyectoService from "../../../services/ProyectoService";
import AppContext from "../../../AppContext";
import UserContext from "../../../UserContext";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { TitleUC } from "../../ui/TitleUC";

import Table from "../../ui/Table";
import Alerts from "../../ui/Alerts";
import { ButtonUC } from "../../ui/ButtonUC";
import PerfilService from "../../../services/PerfilService";
import Denegado from "../../Auth/Denegado";
import Confirm from "../../ui/Confirm";
import SelectUC from "../../ui/SelectUC";
import moment from "moment";

function ProyectosComponent() {
  const history = useHistory();
  const token = useContext(AppContext);
  const user = useContext(UserContext);

  const [message, setMessage] = "";
  const [alertType, setAlertType] = "";
  const [permiso, setPermiso] = useState(false);
  const [projects, setProjects] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [filtros, setFiltros] = useState({
    estado: "",
    busqueda: "",
  });
  const [curProyectId, setCurProyectId] = useState();
  const [textConfirm, setTextConfirm] = useState("");

  const options = [
    { value: "13", label: "Pre-Postulación" },
    { value: "14", label: "Postulación" },
    { value: "15", label: "Activo" },
    { value: "16", label: "Terminado" },
    { value: "17", label: "Suspendido" },
    { value: "18", label: "Cancelado" },
    { value: "19", label: "Todos" },
  ];
  const fetchProyectos = async (estado, busqueda) => {
    try {
      const rawProjects = await ProyectoService.fetchAll(token, estado, busqueda);
      const parsedData = rawProjects.respuesta.map((project) => ({
        id: project.id,
        nom: project.nombreProyecto,
        estado: project.estado,
        fechaIni: project.inicioProyecto,
        fechaTerm: project.finProyecto,
        fechaInscripIni: project.inicioInscrip,
        fechaInscripTerm: project.finInscrip,
        // estado: project.estado.nomValor
      }));
      setLoading(false);
      setProjects(parsedData);
      //(rawProjects);
    } catch (err) {
      console.log(err);
    }
  };

  const cancelProyecto = (pro) => {
    setCurProyectId(pro);
    setConfirmModal(true);
    setTextConfirm(`quiere cancelar el proyecto ${pro.nom}`);
  };
  const accept = async () => {
    const res = await ProyectoService.cancelProyecto(curProyectId.id, token);
    alert(res.respuesta);
    setConfirmModal(false);
    fetchProyectos();
  };

  const cancel = () => {
    setConfirmModal(false);
  };

  const onChangeProyecto = (e) => {
    setFiltros({
      ...filtros,
      estado: e.value,
    });
    fetchProyectos(e.value, filtros.busqueda);
  };

  const onChangeBusqueda = (e) => {
    setFiltros({
      ...filtros,
      busqueda: e.target.value,
    });
    fetchProyectos(filtros.estado, e.target.value);
  };
  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        accessor: "actions",
        Cell: (props) => {
          const rowValues = props.row.values;
          return (
            <div>
              <Link to={`/catalogo/1/inscripcion/${rowValues.id}`}>
                <span title="Ver en catálogo">
                  <i className="uc-icon icon-shape--rounded m-1">visibility</i>
                </span>
              </Link>
              <Link to={`/proyectos/editar/${rowValues.id}`}>
                <span title="Editar proyecto">
                  <i className="uc-icon icon-shape--rounded m-1">edit</i>
                </span>
              </Link>
              <Link to={`/proyectos/clonar/${rowValues.id}`}>
                <span title="Clonar">
                  <i className="uc-icon icon-shape--rounded m-1">content_copy</i>
                </span>
              </Link>
              <Link to={`/proyectos/editar/${rowValues.id}?sub=true`}>
                <span title="Subproyectos">
                  <i className="uc-icon icon-shape--rounded m-1">people_alt</i>
                </span>
              </Link>
              <span title="Eliminar proyecto" onClick={() => cancelProyecto(rowValues)}>
                <i className="uc-icon icon-shape--rounded m-1">block</i>
              </span>
            </div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Nombre Proyecto",
        accessor: "nom",
      },
      {
        Header: "Estado",
        accessor: "estado",
        // Cell: (props) => {
        //     const estado = props.row.values;
        //     console.log(estado)
        //     return (
        //         <span title='Editar'>
        //             {estado.idEstado.nomValor}
        //         </span>
        //     );
        // },
      },
      {
        Header: "Inicio inscripción",
        accessor: "fechaInscripIni",
        Cell: (props) => {
          const fecha = props.row.values.fechaInscripIni;
          return <span>{moment(fecha).format("YYYY-MM-DD")}</span>;
        },
      },
      {
        Header: "Fin inscripción",
        accessor: "fechaInscripTerm",
        Cell: (props) => {
          const fecha = props.row.values.fechaInscripTerm;
          return <span>{moment(fecha).format("YYYY-MM-DD")}</span>;
        },
      },
      {
        Header: "Inicio proyecto",
        accessor: "fechaIni",
        Cell: (props) => {
          const fecha = props.row.values.fechaIni;

          return <span>{moment(fecha).format("YYYY-MM-DD")}</span>;
        },
      },
      {
        Header: "Fin proyecto",
        accessor: "fechaTerm",
        Cell: (props) => {
          const fecha = props.row.values.fechaTerm;

          return <span>{moment(fecha).format("YYYY-MM-DD")}</span>;
        },
      },
    ],
    []
  );
  // useEffect(() => {
  //     fetchEstados()
  // }, [])
  const initialState = {
    hiddenColumns: ["id"],
  };
  useEffect(() => {
    setPermiso(PerfilService.permisoHandler(user, "Proyectos"));
    fetchProyectos("19", "");
  }, []);

  return !permiso ? (
    <Denegado />
  ) : (
    <div className="container-fluid">
      <TitleUC value="Proyectos" />
      <div className="row">
        <div className="col">{message && <Alerts message={message} setMessage={setMessage} alertType={alertType} setAlertType={setAlertType} />}</div>
      </div>
      <div className="d-flex justify-content-between row py-2">
        <div className="col-lg-4 py-2">
          <ButtonUC type="button" className="uc-btn btn-primary" onClick={() => history.push("/proyectos/crear")}>
            Crear nuevo proyecto
          </ButtonUC>
        </div>
        <div className="col-lg-4 py-2">
          {/* <div className="uc-form-group uc-radio-group">
                        <input type="radio" id="switch_1" name="switch" defaultValue="si" className="uc-orig-radio" defaultChecked />
                        <label htmlFor="switch_1" className="uc-radio-button">
                            Activos
                        </label>
                        <input type="radio" id="switch_2" name="switch" defaultValue="no" className="uc-orig-radio" />
                        <label htmlFor="switch_2" className="uc-radio-button">
                            Inactivo
                        </label>
                        <input type="radio" id="switch_3" name="switch" defaultValue="maybe" className="uc-orig-radio" />
                        <label htmlFor="switch_3" className="uc-radio-button">
                            Todos
                        </label>
                    </div> */}
          <SelectUC
            // dominio='estados_actividades'
            options={options}
            onChange={onChangeProyecto}
            noLabel
          />
        </div>
        <div className="col-lg-4 py-2">
          <input id="ucsearch" type="text" className="uc-input-style w-icon search" onChange={onChangeBusqueda} placeholder="Buscar por" />
        </div>
      </div>
      {loading ? <>Cargando datos...</> : <Table columns={columns} data={projects} initialState={initialState} />}
      {confirmModal && <Confirm accept={accept} cancel={cancel} setStateModal={setConfirmModal} texto={textConfirm} />}
    </div>
  );
}

export default ProyectosComponent;
