import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Logout from '../Auth/Logout';
import UserContext from '../../UserContext';
import AppContext from '../../AppContext';
import InscripService from '../../services/InscripService';

function Topbar({ setAuth, isLoged }) {
   const user = useContext(UserContext);
   const token = useContext(AppContext);
   const [numNotifica, setNumNotifica] = React.useState();

   React.useEffect(() => {
      if (user.codUsuario) getNot();
   });

   const getNot = () => {
      // InscripService.getNewMensaje(user.codUsuario, token).then((r) => setNumNotifica(r));
      InscripService.getNotificaciones(user.codUsuario, token).then((r) => {
         if (r) {
           const uniqueData = Array.from(new Set(r.respuesta.map(JSON.stringify))).map(JSON.parse);
           setNumNotifica(() => uniqueData.length);
         } else {
           setNumNotifica(undefined);
         }
       });
   };

   // if(isLoged)  setInterval(getNot, 1000 * 60) ;
   return (
      <div className="uc-top-bar">
         <div className="container">
            <div className="top-bar_mobile-logo d-block d-lg-none">
               <img src="https://kit-digital-uc-prod.s3.amazonaws.com/assets/logo-uc-comprimido.svg" alt="Logo UC" className="img-fluid" />
            </div>
            <div className="top-bar_links justify-content-between d-none d-lg-flex">
               <ul className="top-bar_links">
                  <li>
                     <a href="http://uc.cl/" target="_blank" rel="noreferrer" className="text-size--sm external">
                        ir al sitio UC
                     </a>
                  </li>
               </ul>
               <ul className="top-bar_links">
                  <li>
                     <a href="https://pastoral.uc.cl/" target="_blank" rel="noreferrer" className="text-size--sm external">
                        Nosotros
                     </a>
                  </li>
                  {isLoged && (
                     <li>
                        <Link to="/notificaciones" rel="noreferrer" className="text-size--sm">
                           Notificaciones {numNotifica > 0 ? `(${numNotifica})` : '(0)'}
                        </Link>
                     </li>
                  )}

                  <li>
                     <Link to="/mi-cuenta" className="text-size--sm">
                        {user !== undefined ? user.email : ''}
                     </Link>
                  </li>
                  {setAuth && (
                     <li>
                        <Logout setAuth={setAuth} />
                     </li>
                  )}
               </ul>
            </div>
         </div>
      </div>
   );
}

export default Topbar;
