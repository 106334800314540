import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import AppContext from "../../../../AppContext";

import InscripService from "../../../../services/InscripService";
import ResCuestionario from "../../../catalogo/ResCuestionario";
import TitleUC from "../../../ui/TitleUC";
import Bitacora from "./Bitacora";
import Historial from "./Historial";
import MenuVerVoluntarios from "./MenuVerVoluntarios";
import Pagos from "./Pagos";
import Registros from "./Registro";

import UserContext from "../../../../UserContext";
import SubProyectosService from "../../../../services/SubProyectosService";
import VoluntariosService from "../../../../services/VoluntariosService";
import MisActividades from "../../../misproyectos/MisActividades";
import EditarInscripcion from "./EditarInscripcion";

function VerVoluntario() {
  const { id, idSub, idPro } = useParams();
  const token = useContext(AppContext);
  const user = useContext(UserContext);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const men = query.get("mensaje");
  const queryStep = query.get("step");

  const [cues, setCues] = useState([]);
  const [bitacora, setBitacora] = useState([]);
  const [subproyectos, setSubproyectos] = useState([]);
  const [proyecto, setProyecto] = useState([]);
  const [curSub, setCurSub] = useState({});
  const [step, setStep] = useState(0);
  const [voluntario, setVoluntario] = useState({});
  const [actividadModal, setActividadModal] = useState(false);
  const [inscrip, setInscrip] = useState({});

  const actualizarMisProyectos = () => {
    InscripService.getMisProyectos(id, token)
      .then((res) => {
        let pro = res.respuesta;
        let idCur = pro.findIndex((e) => e.codSubProyecto == idSub);
        let curProyecto = [];
        curProyecto.push(pro[idCur]);
        setProyecto([...curProyecto]);
      })
      .catch((err) => console.log(err));
    InscripService.verVoluntario(id, idSub, token)
      .then((res) => {
        setCues(JSON.parse(res.inscripSubProyecto.respta));
        setBitacora(res.bitacora);
        setInscrip(res.codLabels);
        console.log("res", res);
      })
      .catch((err) => console.log(err));
    SubProyectosService.get(idPro, token)
      .then((res) =>
        setSubproyectos(
          res.map((option) => {
            if (option.id == idSub) {
              let cur = { value: option.id, label: option.nombre };
              setCurSub({ ...cur });
              console.log("true");
            }
            return { value: option.id, label: option.nombre };
          })
        )
      )
      .catch((err) => console.log(err));
    VoluntariosService.fetchById(id, token).then((r) => setVoluntario(r));
  };

  const setVisto = async (visto, bitacora) => {
    console.log("visto", visto);
    console.log("bitacora", bitacora);

    const bit = {
      visto: visto,
      codBitacora: bitacora.id,
    };
    bitacora.visto = visto;
    const res = await InscripService.setVisto(bitacora, token);
    console.log(res);
    actualizarMisProyectos();
  };

  useEffect(() => {
    actualizarMisProyectos();
    if (men) {
      setStep(3);
    }
    if (queryStep) {
      setStep(parseInt(queryStep));
    }
  }, []);

  return (
    <div>
      <TitleUC value={`${voluntario.nombres} ${voluntario.apaterno}`} />

      <MenuVerVoluntarios step={step} setStep={setStep} />
      {step === 0 && <EditarInscripcion subproyectos={subproyectos} idSub={idSub} inscrip={inscrip} />}
      {step === 1 && <Registros voluntario={voluntario} />}
      {step === 2 && (
        <div className="uc-form-group">
          <ResCuestionario cuestionario={cues} edit={true} setCuestionario={setCues} />
        </div>
      )}
      {step === 3 && <Bitacora bitacora={bitacora} setVisto={setVisto} />}
      {step === 4 && <Historial codUser={id} token={token} />}
      {step === 5 && <MisActividades proyectos={proyecto} soloActiv={true} actividadModal={actividadModal} setActividadModal={setActividadModal} actualizarMisProyectos={actualizarMisProyectos} codUsuario={id} voluntario={voluntario} curSub={curSub.value} user={user} token={token} setProyectos={setProyecto} />}
      {step === 6 && <Pagos codUser={id} codSub={idSub} />}
    </div>
  );
}

export default VerVoluntario;
