import React, { useEffect, useRef, useState } from 'react';

import formVoluntarioSchema from './formVoluntarioSchema';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useRut } from 'react-rut';
import { InputUC } from '../../ui/InputUC';
import { SelectUC } from '../../ui/SelectUC';
import { SubTitle } from '../../ui/SubTitle';
import { TitleUC } from '../../ui/TitleUC';

function Formulario({ onSubmit, defaults, dis, submitText, alertData, read, useCaptcha }) {
   const [{ formattedValue }, setRut] = useRut();
   const [isExtranjero, setExtranjero] = useState(false);
   const validationSchema = formVoluntarioSchema;
   const [captcha, setCaptcha] = useState(process.env.REACT_APP_BACKEND_URL + 'captcha/captchaService');
   const [emailUC, setEmailUC] = useState(false);
   const [infAcademica, setInfAcademica] = useState(false);
   const [displayOther, setDisplayOther] = useState(false);
   const pass1 = useRef(null);
   const pass2 = useRef(null);

   const getCaptcha = () => {
      setCaptcha('');
      setTimeout(() => {
         console.log(1);
         setCaptcha(process.env.REACT_APP_BACKEND_URL + 'captcha/captchaService?t=' + Math.round(Math.random() * 999999));
      }, 100);
   };

   const {
      register,
      handleSubmit,
      control,
      setValue,
      getValues,
      reset,
      formState: { errors },
   } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(validationSchema),
      defaultValues: defaults,
   });

   const paises = [
      { value: false, label: 'Chilena' },
      { value: true, label: 'Extranjera' },
   ];

   const handleTipoDocumento = (e) => {
      setExtranjero(e.target.value === 'true' ? true : false);
      setValue('rut', '');
      setValue('pasaporte', '');
      // setExtranjero(e)
   };

   const InputPasaporte = (
      <input
         name="pasaporte"
         {...register('pasaporte')}
         type="text"
         style={{ width: '310px' }}
         placeholder="Número de pasaporte"
         className="uc-input-style"
         maxLength="12"
         disabled={dis}
      />
   );
   const InputRUT = (
      <input
         name="rut"
         type="text"
         {...register('rut')}
         style={{ width: '310px' }}
         value={formattedValue}
         placeholder="12.345.678-9"
         className="uc-input-style"
         maxLength="12"
         disabled={dis}
         onChange={(e) => {
            setRut(e.target.value);
         }}
      />
   );

   const radioHandler = () => {
      if (defaults.id || dis) {
         return StaticRadio;
      } else {
         return InputRadio;
      }
   };

   const InputRadio = (
      <div className="uc-form-group uc-radio-group" onChange={(e) => handleTipoDocumento(e)}>
         <input
            type="radio"
            id="rut"
            disabled={dis}
            name="isPasaporte"
            {...register('isPasaporte')}
            value={false}
            className="uc-orig-radio"
            defaultChecked
         />
         <label htmlFor="rut" style={{ width: '110px' }} className="uc-radio-button text-center">
            RUT
         </label>
         <input type="radio" id="pasaporte" disabled={dis} name="isPasaporte" {...register('isPasaporte')} value={true} className="uc-orig-radio " />
         <label htmlFor="pasaporte" style={{ width: '110px' }} className="uc-radio-button text-center">
            Pasaporte
         </label>
      </div>
   );

   const StaticRadio = (
      <div className="uc-form-group uc-radio-group">
         <input type="radio" id="rut" disabled={true} name="isPasaporte" value={false} className="uc-orig-radio" checked={!isExtranjero} />
         <label htmlFor="rut" style={{ width: '110px' }} className="uc-radio-button text-center">
            RUT
         </label>
         <input type="radio" id="pasaporte" disabled={true} name="isPasaporte" checked={isExtranjero} value={true} className="uc-orig-radio " />
         <label htmlFor="pasaporte" style={{ width: '110px' }} className="uc-radio-button text-center">
            Pasaporte
         </label>
      </div>
   );

   const StaticRut = (
      <input
         name="rut"
         type="text"
         style={{ width: '310px' }}
         value={defaults.rut}
         placeholder="12.345.678-9"
         className="uc-input-style"
         maxLength="12"
         disabled={true}
         {...register('rut')}
      />
   );
   const StaticPasaporte = (
      <input
         name="pasaporte"
         type="text"
         style={{ width: '310px' }}
         value={defaults.pasaporte}
         placeholder="12.345.678-9"
         className="uc-input-style"
         maxLength="12"
         disabled={true}
         {...register('pasaporte')}
      />
   );
   const inputHandler = () => {
      if (defaults.id || dis) {
         //campo estatico
         if (isExtranjero) {
            // console.log('hay id, es pasaporte')
            return StaticPasaporte;
         } else {
            // console.log('hay id, es rut')ls
            return StaticRut;
         }
      } else {
         //campo abierto
         if (isExtranjero) {
            // console.log('no id, es pasaporte')
            return InputPasaporte;
         } else {
            // console.log('no id, es rut')
            return InputRUT;
         }
      }
   };

   useEffect(() => {
      reset(defaults);
   }, [defaults]);

   useEffect(() => {
      setExtranjero(defaults.isPasaporte);
   }, [defaults]);

   return (
      <div className="container-fluid">
         {!read && <TitleUC value="Formulario de inscripción" />}
         <SubTitle value="Información Personal" />
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
               <div className="col-lg">
                  <InputUC
                     name="nombres"
                     label="Nombres"
                     type="text"
                     placeholder="Nombres"
                     disabled={read}
                     errores={errors.nombres?.message}
                     {...register('nombres')}
                  />
               </div>
            </div>

            <div className="row">
               <div className="col-lg">
                  {errors.isPasaporte?.message}
                  <InputUC
                     name="apaterno"
                     label="Apellido paterno"
                     type="text"
                     placeholder="Apellido paterno"
                     disabled={read}
                     errores={errors.apaterno?.message}
                     {...register('apaterno')}
                  />
               </div>
               <div className="col-lg">
                  <InputUC
                     name="amaterno"
                     label="Apellido materno"
                     type="text"
                     placeholder="Apellido materno"
                     disabled={read}
                     errores={errors.amaterno?.message}
                     {...register('amaterno')}
                  />
               </div>
            </div>

            <div className="row d-flex">
               <div className="col-lg">
                  <div className="uc-form-group">
                     <label className="label">
                        <div className="uc-form-group form-switch" style={{ paddingLeft: '0px', marginBottom: '-4px' }}>
                           <label className="form-check-label" htmlFor="isrut">
                              Documento de identificación
                           </label>
                        </div>
                     </label>

                     <div className="row flex justify-content-between">
                        <div className="col-xl-5 mb-2 ">{radioHandler()}</div>
                        <div className="col-7 mb-2">{inputHandler()}</div>
                     </div>

                     {errors.rut?.message && <p className="p-size--sm p-2 form-error">{errors.rut?.message}</p>}
                     {errors.pasaporte?.message && <p className="p-size--sm p-2 form-error">{errors.pasaporte?.message}</p>}
                  </div>
               </div>

               <div className="col-lg" style={{ zIndex: 102 }}>
                  <Controller
                     name="extranjero"
                     control={control}
                     render={({ field }) => (
                        <SelectUC label="Nacionalidad" options={paises} isdisabled={read} errores={errors.extranjero?.message} {...field} />
                     )}
                  />
               </div>
            </div>

            <div className="row">
               <div className="col-lg">
                  <InputUC
                     name="nacimiento"
                     label="Fecha de nacimiento"
                     type="date"
                     placeholder="Fecha de nacimiento"
                     disabled={read}
                     errores={errors.nacimiento?.message}
                     {...register('nacimiento')}
                  />
               </div>
               <div className="col-lg" style={{ zIndex: 101 }}>
                  <Controller
                     name="genero"
                     control={control}
                     render={({ field }) => (
                        <SelectUC label="Género" errores={errors.genero?.message} dominio="genero" isdisabled={read} {...field} />
                     )}
                  />
               </div>
            </div>

            <div className="row">
               <div className="col-lg">
                  <InputUC
                     name="telefono"
                     label="Teléfono"
                     type="number"
                     maxLength="9"
                     minLength="8"
                     placeholder="(+56)"
                     disabled={read}
                     errores={errors.telefono?.message}
                     {...register('telefono')}
                  />
               </div>
               <div className="col-lg">
                  <InputUC
                     name="correo"
                     label="Correo"
                     type="email"
                     placeholder="voluntario@mail.com"
                     errores={errors.correo?.message}
                     {...register('correo')}
                     onChange={(r) => {
                        const val = r.target.value.toLowerCase();
                        setValue('correo', val);
                        if (val.endsWith('@uc.cl')) {
                           setValue('contrasena', 'P@st0Rra||');
                           setValue('confirmarcontrasena', 'P@st0Rra||');
                           setEmailUC(true);
                        } else if (emailUC === true) {
                           setValue('contrasena', '');
                           setValue('confirmarcontrasena', '');
                           setEmailUC(false);
                        }
                     }}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-lg" style={{ zIndex: 100 }}>
                  <Controller
                     name="region"
                     control={control}
                     render={({ field }) => (
                        <SelectUC label="Región" errores={errors.region?.message} dominio="regiones" isdisabled={read} {...field} />
                     )}
                  />
               </div>
               <div className="col-lg" style={{ zIndex: 99 }}>
                  <Controller
                     name="comuna"
                     control={control}
                     render={({ field }) => (
                        <SelectUC label="Comuna" errores={errors.comuna?.message} dominio="comunas" isdisabled={read} {...field} />
                     )}
                  />
               </div>
               {false && <InputUC name="uid" type="text" disabled={read} {...register('uid')} />}
            </div>
            {!read && !dis && (
               <div className="row" style={{ display: emailUC ? 'none' : 'flex' }}>
                  <input name="editMode" type="hidden" value={defaults.id ? true : false} {...register('editMode')} />

                  <div className="col-lg">
                     <InputUC
                        name="contrasena"
                        label="Contraseña"
                        type="password"
                        placeholder=""
                        disabled={dis}
                        defaultValue={dis ? defaults.rut : ''}
                        errores={errors.contrasena?.message}
                        {...register('contrasena')}
                     />
                  </div>
                  <div className="col-lg">
                     <InputUC
                        name="confirmarcontrasena"
                        label="Confirmar contraseña"
                        type="password"
                        placeholder=""
                        disabled={dis}
                        defaultValue={dis ? defaults.rut : ''}
                        errores={errors.confirmarcontrasena?.message}
                        {...register('confirmarcontrasena')}
                     />
                  </div>
               </div>
            )}
            {emailUC && !dis && (
               <div className="row">
                  <div className="col-lg">
                     La contraseña de las cuentas con correo UC se administran a través de los sistemas centrales de la Universidad (CAS)
                  </div>
               </div>
            )}

            <input name="is_cas" type="hidden" value={dis} {...register('is_cas')} />
            <hr className="uc-hr" />

            <SubTitle value="Información Académica" />
            <div className="row">
               <div className="col-lg-6" style={{ zIndex: 98 }}>
                  <Controller
                     name="categoria"
                     control={control}
                     render={({ field }) => {
                        if (field.value?.value !== infAcademica) {
                           console.log(infAcademica);
                           if (infAcademica !== undefined) {
                              setValue('areaestudio', null);
                              setValue('otrainstitucion', null);
                              if (field.value?.value === 10 || field.value?.value === 11) setValue('institucion', { value: 34, label: 'UC' });
                              else setValue('institucion', null);
                              setValue('ingreso', null);
                              setDisplayOther(false);
                           }

                           setInfAcademica(field.value?.value);
                        }
                        return <SelectUC dominio="categorias" errores={errors.categoria?.message} label="Categoría" isdisabled={read} {...field} />;
                     }}
                  />
               </div>
               {infAcademica === 4 && (
                  <div className="col-lg-6" style={{ zIndex: 97 }}>
                     <Controller
                        name="areaestudio"
                        control={control}
                        render={({ field }) => (
                           <SelectUC
                              dominio="areas_estudio"
                              errores={errors.areaestudio?.message}
                              label="Área de estudio"
                              isdisabled={read}
                              {...field}
                           />
                        )}
                     />
                  </div>
               )}
               {infAcademica === 9 && (
                  <div className="col-lg-6" style={{ zIndex: 97 }}>
                     <InputUC
                        name="otrainstitucion"
                        label="Institución"
                        type="text"
                        placeholder=""
                        disabled={read}
                        defaultValue={defaults.otrainstitucion}
                        errores={errors.otrainstitucion?.message}
                        maxLength="100"
                        {...register('otrainstitucion')}
                     />
                  </div>
               )}
               {infAcademica === 10 && (
                  <div className="col-lg-6" style={{ zIndex: 97 }}>
                     <Controller
                        name="areaestudio"
                        control={control}
                        render={({ field }) => (
                           <SelectUC
                              dominio="areas_estudio"
                              errores={errors.areaestudio?.message}
                              label="Área de estudio"
                              isdisabled={read}
                              {...field}
                           />
                        )}
                     />
                  </div>
               )}
               {infAcademica === 11 && (
                  <div className="col-lg-6" style={{ zIndex: 97 }}>
                     <Controller
                        name="areaestudio"
                        control={control}
                        render={({ field }) => (
                           <SelectUC dominio="unidades" errores={errors.areaestudio?.message} label="Unidad" isdisabled={read} {...field} />
                        )}
                     />
                  </div>
               )}
               {infAcademica === 12 && (
                  <div className="col-lg-6" style={{ zIndex: 97 }}>
                     <Controller
                        name="areaestudio"
                        control={control}
                        render={({ field }) => {
                           console.log(getValues());
                           console.log(field);
                           if (field.value?.value === 435) setDisplayOther(true);
                           else {
                              setDisplayOther(false);
                              setValue('otrainstitucion', null);
                           }
                           return (
                              <SelectUC
                                 dominio="participacion"
                                 errores={errors.areaestudio?.message}
                                 label="Participacion"
                                 isdisabled={read}
                                 {...field}
                              />
                           );
                        }}
                     />
                  </div>
               )}
            </div>

            <div className="row">
               {infAcademica === 4 && (
                  <>
                     <div className="col-lg-6" style={{ zIndex: 96 }}>
                        <Controller
                           name="institucion"
                           control={control}
                           render={({ field }) => (
                              <SelectUC
                                 dominio="instituciones"
                                 errores={errors.institucion?.message}
                                 label="Institución Educación Superior"
                                 isdisabled={read}
                                 {...field}
                              />
                           )}
                        />
                     </div>
                     <div className="col-lg-6" style={{ zIndex: 95 }}>
                        <InputUC
                           name="ingreso"
                           label="Año de ingreso"
                           type="number"
                           placeholder="AAAA"
                           disabled={read}
                           defaultValue={defaults.ingreso}
                           errores={errors.ingreso?.message}
                           min="1500"
                           max="3000"
                           maxLength="4"
                           {...register('ingreso')}
                        />
                     </div>
                  </>
               )}
               {infAcademica === 9 && (
                  <>
                     <div className="col-lg-6" style={{ zIndex: 96 }}>
                        <Controller
                           name="institucion"
                           control={control}
                           render={({ field }) => (
                              <SelectUC dominio="cursos" errores={errors.curso?.message} label="Curso actual" isdisabled={read} {...field} />
                           )}
                        />
                     </div>
                     <div className="col-lg-6" style={{ zIndex: 95 }}>
                        <InputUC
                           name="ingreso"
                           label="Año estimado de egreso"
                           type="number"
                           placeholder="AAAA"
                           disabled={read}
                           defaultValue={defaults.ingreso}
                           errores={errors.ingreso?.message}
                           min="1500"
                           max="3000"
                           maxLength="4"
                           {...register('ingreso')}
                        />
                     </div>
                  </>
               )}
               {infAcademica === 10 && (
                  <div className="col-lg-6" style={{ zIndex: 96 }}>
                     <Controller
                        name="institucion"
                        control={control}
                        render={({ field }) => (
                           <SelectUC dominio="instituciones" errores={errors.institucion?.message} label="Institución" isdisabled={true} {...field} />
                        )}
                     />
                  </div>
               )}
               {infAcademica === 11 && (
                  <div className="col-lg-6" style={{ zIndex: 96 }}>
                     <Controller
                        name="institucion"
                        control={control}
                        render={({ field }) => (
                           <SelectUC dominio="instituciones" errores={errors.institucion?.message} label="Institución" isdisabled={true} {...field} />
                        )}
                     />
                  </div>
               )}
               {infAcademica === 12 && displayOther && (
                  <div className="col-lg-6" style={{ zIndex: 96 }}>
                     <InputUC
                        name="otrainstitucion"
                        label="Especifica"
                        type="text"
                        placeholder=""
                        disabled={read}
                        defaultValue={defaults.otrainstitucion}
                        errores={errors.otrainstitucion?.message}
                        maxLength="100"
                        {...register('otrainstitucion')}
                     />
                  </div>
               )}
            </div>
            <hr className="uc-hr" />
            <div className="row">
               <div className="col-lg-12 d-flex justify-content-center pt-4">
                  <div className="form-check">
                     <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue
                        name="newsletter"
                        id="newsletter"
                        disabled={read}
                        defaultChecked
                        {...register('newsletter')}
                     />
                     <label className="form-check-label" htmlFor="newsletter">
                        Acepto recibir comunicaciones de Pastoral UC
                     </label>
                  </div>
               </div>
               <div className="col-lg-12 d-flex justify-content-center pt-4">
                  <div className="form-check">
                     <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue
                        name="condiciones"
                        id="condiciones"
                        disabled={read}
                        {...register('condiciones')}
                     />
                     <label className="form-check-label" htmlFor="condiciones">
                        Acepto{' '}
                        <a href="https://protecciondedatos.uc.cl/politica/politica" target="_blank" rel="noreferrer">
                           Términos y Condiciones
                        </a>
                     </label>
                  </div>
               </div>
               <div className="col-lg-12 d-flex justify-content-center">
                  <p className="p-size--sm form-error">{errors.condiciones?.message}</p>
               </div>
            </div>

            <hr className="uc-hr" />

            {useCaptcha && (
               <>
                  <div className="col-lg-12 d-flex justify-content-center pt-4">
                     <img style={{ padding: '0px', height: '80px', width: 'auto', border: '1px solid #DDD' }} alt="captcha" src={captcha} />
                     <a
                        onClick={() => {
                           getCaptcha();
                        }}>
                        <i className="uc-icon ml-2">refresh</i>
                     </a>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center py-1">
                     <span>Escribe los caracteres de la imagen:</span>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center pb-4">
                     <input
                        className="uc-input-style text-center"
                        style={{ width: '300px' }}
                        name="captcha"
                        id="captcha"
                        type="text"
                        {...register('captcha')}
                     />
                  </div>
               </>
            )}
            <div className="col-lg-12 d-flex justify-content-center py-4">
               {!read && (
                  <>
                     <input type="submit" className="uc-btn btn-cta" style={{ maxWidth: '250px' }} value={submitText} />
                  </>
               )}
            </div>
         </form>
      </div>
   );
}

export default Formulario;
