import React from 'react'
import VoluntariosService from '../../../services/VoluntariosService';

export default function ModalConfirm(props) {

    const deleteHandler = async () => {
        const response = await VoluntariosService
            .remove(props.currentVoluntario.codUsuario, props.token)
        response.realizado === 's' ?
            props.setAlertType('success') :
            props.setAlertType('error')
        props.setFeedback({
            isOpen: true,
            ...response
        })
        props.setMessage(response.respuesta)
        props.setModal(false)
        props.fetchVoluntarios();
    }

    return (
        <div className='uc-modal'>
            <div className="uc-modal_content uc-message error" style={{ maxWidth: '650px' }}>
                <span className="uc-message_close-button" onClick={() => props.setModal(false)}><i className="uc-icon">close</i></span>
                <div className="uc-message_body">
                    <h2 className="mb-24"><i className="uc-icon warning-icon p-4">warning</i> ¿Estás seguro de esta acción?</h2>
                    <hr className="uc-hr my-32" />
                    <div className='d-flex justify-content-between'>
                        <button className="uc-btn btn-cta" onClick={() => deleteHandler()}>Eliminar Valor</button>
                        <button className="uc-btn btn-primary" onClick={() => props.setModal(false)}>Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
