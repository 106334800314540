import React from 'react'

export const Alerts = ({ feedback, setFeedback, postMsg }) => {
    console.log(feedback)
    const closeAlert = () => {
        setFeedback({
            isOpen: false,
            realizado: false,
            id: null,
            respuesta: "",
            timestamp: "",
            errors: []
        })
    }
    let realizado = feedback.realizado === true ? true : false
    let classname = realizado ? 'success' : 'error'

    return (
        <div className={`uc-alert ${classname} mb-12`}>
            <div className="uc-alert_content ">
                <div className=''>
                    <i className="uc-icon icon-size--sm">{realizado ? 'check_circle' : 'cancel'}</i>
                    <span className="p p-size--sm bold ml-8">{feedback.respuesta} {postMsg ? postMsg : ''}</span>
                </div>
            </div>
            <div className="uc-alert_close" onClick={() => closeAlert()}><i className="uc-icon icon-size--sm">close</i></div>
        </div>
    )
}

export default Alerts
