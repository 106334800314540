import React, { createContext, useState, useContext } from 'react'

const DataContext = createContext()

export const DataProvider = ({ children }) => {
    const [contextValues, setData] = useState({
        nom: '',
        idEstado: {},
        descripcion: '',
        fechaInscripIni: '',
        fechaInscripTerm: '',
        fechaIni: '',
        fechaTerm: '',
        aporteminimo: '',
        aportesugerido: '',
        categorias: [],
        etiquetas: [],
        urlLogo: '',
        urlProyecto: '',
        confirmacion_manual: undefined,
        uc_only: undefined,
        has_max_vacantes: undefined,
        max_vacantes: undefined,
        has_limite_areas: undefined,
        areas_permitidas: undefined,
        has_porcentaje_areas: undefined,
        porcentaje_areas: undefined,
        has_porcentaje_volareas: undefined,
        porcentaje_volareas: undefined,
        has_porcentaje_generos: undefined,
        porcentaje_generos: undefined,
        has_edad_min: undefined,
        edad_min: undefined,
        has_edad_max: undefined,
        edad_max: undefined,
        subproyectos: [],
        cuestionario: {},
        cargos: [],
        roles: [],
        idUsuarioModif: 1 //HARDCODED
    })


    const setValues = (values) => {
        setData((prevData) => ({
            ...prevData,
            ...values,
        }));

    }
    return (
        <DataContext.Provider value={{ contextValues, setValues }}>
            {children}
        </DataContext.Provider>
    )
}

export const useData = () => useContext(DataContext)