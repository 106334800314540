import React, { useEffect, useState, useMemo } from "react";
import Table from "./../../../ui/Table";
import InscripService from "../../../../services/InscripService";
import ReactPDF, { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import moment from "moment";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import JefaturasService from "../../../../services/JefaturasService";

function Historial({ codUser, token, nombre }) {
  const [historialInscrip, setHistorialInscrip] = useState([]);
  const [historialJefatura, setHistorialJefatura] = useState([]);
  const [showCertificado, setShowCertificado] = useState(false);
  const [res, setRes] = useState(<></>);
  const { idPro, nombre: nombreParam } = useParams();

  const styles = StyleSheet.create({
    viewer: {
      width: "100%", //the pdf viewer will take up all of the width and height
      minHeight: "700px",
    },
    page: {},
    image: {
      position: "absolute",
      width: "100%",
      height: "auto",
      zIndex: -1,
    },
    title: {
      marginTop: "17%",
      textAlign: "center",
      marginBottom: 20,
      fontSize: 25,
    },
    content: { textAlign: "center", fontSize: 20, lineHeight: "1.5px", margin: "0 10%" },
  });

  const verCertificado = async (row, hist) => {
    const resp = await InscripService.getCertificado(codUser, row.codSubProyecto, token);
    setRes(resp);
    setShowCertificado(true);
  };
  const Certificado = () => (
    <Document>
      <Page size="Letter" orientation="landscape" style={styles.page}>
        <Image src="/certificado.png" style={styles.image} />
        <View style={styles.title}>
          <Text>CERTIFICADO</Text>
        </View>
        <View style={styles.content}>
          <Text>Mediante el presente documento, certifico que</Text>
          <Text>{res?.respuesta?.fullName}</Text>
          <Text>participó en "{res?.respuesta?.nomProyecto}" {res?.respuesta.tipo && (
            res.respuesta.tipo === 'jefatura' ? 
            (<p>como {res.respuesta.tipo}</p>) : 
            (<p>como rol {res.respuesta.cargo}</p>)
          )}
          </Text>
          <Text>
            desde el {moment(res?.respuesta?.fechaInicio).format("LL")} hasta {moment(res?.respuesta?.fechaTermino).format("LL")}
          </Text>
          <Text>en la Pontificia Universidad Católica de Chile.</Text>
          <Text>Santiago, {moment().format("LL")}.</Text>
        </View>
      </Page>
    </Document>
  );

  const columnsInscrip = useMemo(
    () => [
      {
        Header: "Acciones",
        accessor: "actions",
        Cell: (props) => {
          const hist = props.data;
          const { values } = props.row;
          let show = true;
          if (values.estadoProyecto !== "Terminado") show = false;
          else if (!["Pagado", "Eximido", "No Aplica"].includes(values.estadoPago)) show = false;
          return (
            show && (
              <div>
                <span to={`/voluntarios/editar-voluntario/${values.id}`}>
                  <span onClick={() => verCertificado(values, hist)} title="Descargar certificado">
                    <i className="uc-icon  mx-2">verified</i>
                  </span>
                </span>
              </div>
            )
          );
        },
      },
      {
        Header: "codSubProyecto",
        accessor: "codSubProyecto",
      },
      {
        Header: "idProyecto",
        accessor: "idProyecto",
      },
      {
        Header: "Proyecto",
        accessor: "nomProyecto",
        Cell: (props) => {
          const { values } = props.row;

          return idPro || nombreParam ? <Link to={`/proyectos/editar/${values.idProyecto}?vol`}>{values.nomProyecto}</Link> : values.nomProyecto;
        },
      },
      {
        Header: "Subproyecto",
        accessor: "nomSubProyecto",
        Cell: (props) => {
          const { values } = props.row;
          console.log(props);
          return idPro || nombreParam ? (
            <Link to={`/proyectos/editar/${values.idProyecto}?sub=true`}>{values.nomSubProyecto}</Link>
          ) : (
            values.nomSubProyecto
          );
        },
      },
      {
        Header: "Estado",
        accessor: "estadoProyecto",
      },
      {
        Header: "Pago",
        accessor: "estadoPago",
      },
    ],
    []
  );

  const columnsJefatura = useMemo(
    () => [
      {
        Header: "Acciones",
        accessor: "actions",
        Cell: (props) => {
          const hist = props.data;
          const { values } = props.row;
          let show = true;
          if (values.estadoProyecto !== "Terminado") show = false;
          return (
            show && (
              <div>
                <span to={`/voluntarios/editar-voluntario/${values.id}`}>
                  <span onClick={() => {
                    setRes({
                      respuesta: {
                        fullName: values.fullName,
                        nomProyecto: values.nomProyecto,
                        fechaInicio: values.fechaInicio,
                        fechaTermino: values.fechaTermino,
                        tipo: values.tipo,
                        cargo: values.cargo
                      }
                    });
                    setShowCertificado(true);
                  }} title="Descargar certificado">
                    <i className="uc-icon  mx-2">verified</i>
                  </span>
                </span>
              </div>
            )
          );
        },
      },
      {
        Header: "codSubProyecto",
        accessor: "codSubProyecto",
      },
      {
        Header: "idProyecto",
        accessor: "idProyecto",
      },
      {
        Header: "Estado",
        accessor: "estadoProyecto",
      },
      {
        Header: "Tipo",
        accessor: "tipo",
      },
      {
        Header: "Nombre",
        accessor: "fullName",
      },
      {
        Header: "Fecha inicio",
        accessor: "fechaInicio",
      },
      {
        Header: "Fecha termino",
        accessor: "fechaTermino",
      },
      {
        Header: "Proyecto",
        accessor: "nomProyecto",
        Cell: (props) => {
          const { values } = props.row;

          return idPro || nombreParam ? <Link to={`/proyectos/editar/${values.idProyecto}?vol`}>{values.nomProyecto}</Link> : values.nomProyecto;
        },
      },
      {
        Header: "Subproyecto",
        accessor: "nomSubProyecto",
        Cell: (props) => {
          const { values } = props.row;
          console.log(props);
          return idPro || nombreParam ? (
            <Link to={`/proyectos/editar/${values.idProyecto}?sub=true`}>{values.nomSubProyecto}</Link>
          ) : (
            values.nomSubProyecto
          );
        },
      },
      {
        Header: "Nivel/Rol",
        accessor: "cargo",
      },
    ],
    []
  );

  const initialStateInscrip = {
    hiddenColumns: ["codSubProyecto", "idProyecto"],
  };
  const initialStateJefatura = {
    hiddenColumns: ["codSubProyecto", "idProyecto", "estadoProyecto", "tipo", "fullName", "fechaInicio", "fechaTermino"],
  };

  useEffect(() => {
    InscripService.getHistorial(codUser, token).then((r) => setHistorialInscrip(r.respuesta));
    JefaturasService.getHistorial(codUser, token).then((r) => setHistorialJefatura(r.respuesta));
  }, []);
  return (
    <>
      <h2>{typeof nombre === "undefined" ? "" : `${nombre}`} </h2>
      <Table columns={columnsInscrip} data={historialInscrip} initialState={initialStateInscrip} />
      <Table columns={columnsJefatura} data={historialJefatura} initialState={initialStateJefatura} />

      {showCertificado && (
        <div data-modal="modalExample" className="uc-modal">
          <div className="uc-modal_content uc-message success mb-32">
            <a onClick={() => setShowCertificado(false)} className="uc-message_close-button" data-mclosed>
              <i className="uc-icon">close</i>
            </a>
            <div className="uc-message_body">
              <PDFViewer style={styles.viewer}>
                <Certificado />
              </PDFViewer>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Historial;
