import React, {useState} from 'react';
import ModalUC from '../ui/ModalUC';
import InputUC from '../ui/InputUC';

import InscripService from '../../services/InscripService';

function Mensaje(props){

    const [asunto, setAsunto] = useState('');
    const [mensaje, setMensaje] = useState('');

    const handleAsunto = (e) => {
        setAsunto(e.target.value);
        console.log(e.target.value)
    }

    const handleMensaje = (e) => {
        setMensaje(e.target.value)
        console.log(e.target.value) 
    }
    const enviarMensaje = async () => {
        // if(asunto.length === 0){
        //     alert('El mensaje debe tener un asunto')
        //     return false;
        // }
        if(mensaje.length === 0){
            alert('El mensaje debe tener contenido')
            return false;
        }
        const newMensaje = {
            codUsuarioAccion: props.user.codUsuario,
            codUsuarioAfecta: props.user.codUsuario,
            codSubproyecto: props.curSub.codSubProyecto,
            coment: "Mensaje: " + mensaje,
            visto: "n",
            tipo : 62
        }

        const res = await InscripService.enviarMensaje(newMensaje, props.token);
        alert(res.respuesta);
        props.setMensajeModal(false)
    }

    return(
        <ModalUC
        title={"Enviar mensaje"}
        setModal={props.setMensajeModal}
        setAlertType='success'
        style={{ maxWidth: "250px" }}
    >
        <div className='uc-form-group'>
            <h4 className='mb-24'>
                {props.curSub.nomProyecto}
            </h4>
            {/* <InputUC label='Asunto'  onBlur={handleAsunto}/> */}
            <InputUC label='Mensaje' onBlur={handleMensaje}/>
            <a className="uc-btn btn-cta" onClick={enviarMensaje}>Enviar mensaje</a>
        </div>
        </ModalUC>
    )
}

export default Mensaje;