import React, { useState } from 'react';
import AuthService from '../../services/AuthService';
import { useForm } from 'react-hook-form';
import Alerts from '../ui/Alerts';

function Login({ saveToken }) {
   const {
      register,
      handleSubmit,
      formState: { errors },
      setValue
   } = useForm({ mode: 'onBlur' });
   const [message, setMessage] = useState('');

   const handleEmailChange = (event) => {
     const { value } = event.target;
     setValue("username", value.toLowerCase(), { shouldValidate: true });
   };

   const onSubmit = async (data) => {
      data.username = data.username.toLowerCase();
      try {
         const token = await AuthService.login(data);
         saveToken(token.data.jwt);
      } catch (err) {
         if (err.response.status === 400) setMessage(err.response.data.jwt);
         else setMessage('Credenciales invalidas');
      }
   };

   const [captcha, setCaptcha] = useState(process.env.REACT_APP_BACKEND_URL + 'captcha/captchaService');

   const getCaptcha = () => {
      setCaptcha('');
      setTimeout(() => {
         console.log(1);
         setCaptcha(process.env.REACT_APP_BACKEND_URL + 'captcha/captchaService?t=' + Math.round(Math.random() * 999999));
      }, 100);
   };

   return (
     <div style={{ minHeight: "235px" }}>
       <form onSubmit={handleSubmit(onSubmit)}>
         <div className="uc-form-group">
           <label htmlFor="username">Correo</label>
           <input
             id="Correo"
             type="text"
             className="uc-input-style"
             placeholder="voluntario@email.com"
             name="username"
             {...register("username", { required: true })}
             onChange={handleEmailChange}
           />
         </div>
         <div className="uc-form-group">
           <label htmlFor="name">Contraseña</label>
           <input
             id="password"
             type="password"
             className="uc-input-style"
             placeholder="* * * * * * * * * "
             name="password"
             {...register("password", { required: true })}
           />
         </div>
         <>
           <div className="col-lg-12 d-flex justify-content-center pt-4">
             <img
               style={{
                 padding: "0px",
                 height: "80px",
                 width: "auto",
                 border: "1px solid #DDD",
               }}
               alt="captcha"
               src={captcha}
             />
             <a
               onClick={() => {
                 getCaptcha();
               }}
             >
               <i className="uc-icon ml-2">refresh</i>
             </a>
           </div>
           <div className="col-lg-12 d-flex justify-content-center py-1">
             <span>Escribe los caracteres de la imagen:</span>
           </div>
           <div className="col-lg-12 d-flex justify-content-center pb-4">
             <input
               className="uc-input-style text-center"
               style={{ width: "300px" }}
               name="captcha"
               id="captcha"
               type="text"
               {...register("captcha")}
             />
           </div>
         </>
         {message && (
           <Alerts
             alertType="error"
             message={message}
             setMessage={setMessage}
           />
         )}
         <div className="d-flex justify-content-center py-12">
           <button className="uc-btn btn-cta uppercase" type="submit">
             Iniciar Sesión
           </button>
         </div>

         <div className="row justify-content-center text-center pb-12">
           <a
             className="p-size--sm"
             href={process.env.REACT_APP_FRONT_URL + `inscripcion?pbl=s`}
           >
             Registrarme
           </a>
         </div>
         <div className="row justify-content-center text-center pb-12">
           <a
             className="p-size--sm"
             href={process.env.REACT_APP_FRONT_URL + `recuperar-contrasena`}
           >
             ¿Olvidaste tu contraseña?
           </a>
         </div>
       </form>
     </div>
   );
}

export default Login;
