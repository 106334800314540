import React from 'react'

export const Alerts = ({ message, alertType, setMessage }) => {
    let alertClass = '';
    let icon = '';
    const closeAlert = () => {
        setMessage('');
    }
    switch (alertType) {
        case 'success':
            alertClass = 'uc-alert success mb-12';
            icon = 'check_circle'
            break;
        case 'warning':
            alertClass = 'uc-alert success mb-12';
            icon = 'warning'
            break;
        case 'error':
            alertClass = 'uc-alert error mb-12';
            icon = 'cancel'
            break;
        default:
            alertClass = 'uc-alert info mb-12';
            icon = 'info'
            break;
    }

    return (
        <div className={alertClass}>
            <div className="uc-alert_content ">
                <div className=''>
                    <i className="uc-icon icon-size--sm">{icon}</i>
                    <span className="p p-size--sm bold ml-8">{message}</span>
                </div>
            </div>
            <div className="uc-alert_close" onClick={closeAlert}><i className="uc-icon icon-size--sm">close</i></div>
        </div>
    )
}

export default Alerts
