import React, { useState } from "react";
import "moment/locale/es";
import Table from "../../../ui/Table";

function Bitacora({ bitacora, setVisto }) {
  const [visto2, setVisto2] = useState({});
  const bit = [];
  bitacora.forEach((b, i) => {
    visto2[i] = b.visto === "s";
    bit.push({
      accion: (
        <input
          type="checkbox"
          checked={visto2[i]}
          onChange={() => {
            setVisto(visto2[i] ? "n" : "s", b);
            visto2[i] = !visto2[i];
            setVisto2(visto2);
          }}
        />
      ),
      date: b.fechaActualiz,
      log: b.coment,
    });
  });
  return (
    <Table
      columns={[
        { Header: "Leído", accessor: "accion" },
        { Header: "Fecha", accessor: "date", Cell: (props) => props.row.values.date.substring(0, 10) },
        { Header: "Log", accessor: "log" },
      ]}
      data={bit}
      pagination={false}
    />
  );
}

export default Bitacora;
