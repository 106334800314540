import React, { useContext, useEffect, useState } from "react";
import Informacion from "./formularios/Informacion";
import AppContext from "../../../AppContext";
import { useParams } from "react-router";
import ProyectosContainer from "./ProyectosContainer";
import Navbar from "./ui/Navbar";
import AlertUC from "../../ui/AlertUC";
import ProyectoService from "../../../services/ProyectoService";
import moment from "moment";
import { useHistory } from "react-router-dom";

function CreateProyecto() {
  const history = useHistory();
  const token = useContext(AppContext);
  const [imagen, setImagen] = useState();
  const [step, setStep] = useState(1);
  const [informacion, setInformacion] = useState({});
  const { idPro } = useParams();
  const [feedback, setFeedback] = useState({
    isOpen: false,
    realizado: false,
    id: null,
    respuesta: "",
    timestamp: "",
    errors: [],
  });

  const preloadData = async () => {
    setInformacion({
      nom: "",
      idEstado: [],
      descripcion: "",
      fechaInscripIni: "",
      fechaInscripTerm: "",
      fechaIni: "",
      fechaTerm: "",
      pagoRequerido: false,
      aporteminimo: "",
      aportesugerido: "",
      categorias: [],
      etiquetas: [],
      urlLogo: "",
      urlProyecto: "",
      edad_max: undefined,
      idUsuarioModif: 1, //HARDCODED
    });
  };
  const onSubmit = async (data) => {
    const inscripIni = data.fechaInscripIni;
    const inscripTerm = data.fechaInscripTerm;
    const inicio = data.fechaIni;
    const termino = data.fechaTerm;
    const formattedValues = {
      nom: data.nom,
      idEstado: data.idEstado,
      descripcion: data.descripcion,
      fechaInscripIni: moment(inscripIni).format("YYYY-MM-DD"),
      fechaInscripTerm: moment(inscripTerm).format("YYYY-MM-DD"),
      fechaIni: moment(inicio).format("YYYY-MM-DD"),
      fechaTerm: moment(termino).format("YYYY-MM-DD"),
      aporteminimo: data.aporteminimo,
      aportesugerido: data.aportesugerido,
      categorias: data.categorias,
      etiquetas: data.etiquetas,
      urlLogo: imagen,
      urlProyecto: data.urlProyecto,
      codUsuarioModif: 1,
      pagoRequerido: false,
    };
    console.log(data);
    try {
      const response = await ProyectoService.create(JSON.stringify(formattedValues), token);
      console.log(response);
      setFeedback({
        isOpen: true,
        ...response,
      });
      setTimeout(() => history.push(`/proyectos/editar/${response.id}?sub=true`), 3000);
    } catch (err) {
      console.log(err);
      alert("hubo un error");
    }
  };
  useEffect(() => {
    preloadData();
  }, []);

  return (
    <ProyectosContainer>
      <Navbar step={step} setStep={setStep} id={idPro} />
      {step === 1 && <Informacion id={idPro} token={token} step={step} setStep={setStep} defaults={informacion} imagen={imagen} setImagen={setImagen} onSubmit={onSubmit} />}
      {feedback.isOpen && <AlertUC feedback={feedback} setFeedback={setFeedback} postMsg=" Redirigiendo..." />}
    </ProyectosContainer>
  );
}

export default CreateProyecto;
