import React from "react";

import DominiosService from "../../../services/DominiosService";
import { SelectUC } from "../../ui/SelectUC";
import dominioValidationSchema from "./dominiosValidationSchema";
import InputUC from "../../ui/InputUC";
import ModalUC from "../../ui/ModalUC";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export default function EditDominio({ setModal, currentId, changeCurrent, currentString, setCurrentId, currentValor, setMessage, setAlertType, token }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(dominioValidationSchema),
  });
  const ocultoOptions = [
    { value: "n", label: "No" },
    { value: "s", label: "Si" },
  ];

  const submitHandler = async (data) => {
    const newDominio = {
      id: currentValor.id,
      codDominio: currentValor.codDominio,
      nomValor: data.valor === currentValor.nomValor ? currentValor.nomValor : data.valor,
      text: data.text,
      descrip: data.descrip,
      opc: "1",
      oculto: data.oculto.value === currentValor.oculto ? currentValor.oculto : data.oculto.value,
      idUsuario: null,
      protegido: "n",
    };

    const response = await DominiosService.create(newDominio, token);
    setMessage(response.respuesta);
    response.realizado === true ? setAlertType("success") : setAlertType("error");

    changeCurrent(currentString, currentId);
    setModal(false);
  };

  return (
    <ModalUC setModal={setModal} title="Editar Valor" icon="warning" type="warning">
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="uc-form-group">
          <InputUC type="text" name="valor" label="Valor" errores={errors.valor?.message} placeholder="Escriba aquí el nuevo valor" defaultValue={currentValor.nomValor} {...register("valor")} />
        </div>

        <Controller name="oculto" control={control} defaultValue={ocultoOptions.find((found) => found.value === currentValor.oculto)} render={({ field }) => <SelectUC label="Oculto" options={ocultoOptions} errores={errors.oculto?.message} {...field} />} />
        <div className="d-flex justify-content-center">
          <input className="uc-btn btn-cta" type="submit" value="Guardar" />
        </div>
      </form>
    </ModalUC>
  );
}
