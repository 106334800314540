import React, { forwardRef } from 'react'

export const InputUC = forwardRef((props, ref) => {
    return (
        <div className="uc-form-group">
            <label htmlFor={props.name}>{props.label}</label>
            <input
                ref={ref}
                className='uc-input-style'
                {...props}
            />
            <p className="p-size--sm p-2 form-error">{props.errores}</p>
        </div>
    )
})
export default InputUC
