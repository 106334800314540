import axios from 'axios';

const API_INSCRIP = process.env.REACT_APP_BACKEND_URL;
//const API_PROYECTOS = process.env.REACT_APP_BACKEND_URL + 'proyectos'

const create = (data, token) => {
   console.log(data);
   return axios
      .post(`${API_INSCRIP}inscrip/subproyecto`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response);
};

const deleteInscripcion = (cod_usuario, subproyecto, token) => {
   return axios
      .patch(
         `${API_INSCRIP}inscrip/subproyecto/${cod_usuario}/${subproyecto}`,
         { name: 'patch' },
         {
            headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`,
            },
         }
      )
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getMisProyectos = (cod_user, token) => {
   return axios
      .get(`${API_INSCRIP}inscritos/subproyecto/${cod_user}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => console.log(err.response.data));
};

const inscriptActiv = (data, token) => {
   return axios
      .post(`${API_INSCRIP}inscrip/actividad`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const darBaja = (codUser, codActiv, token) => {
   return axios
      .delete(`${API_INSCRIP}inscrip/actividad/${codUser}/${codActiv}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => console.log(err.response.data));
};

const verVoluntario = (codUser, codSub, token) => {
   return axios
      .get(`${API_INSCRIP}verVoluntario/${codUser}/${codSub}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => console.log(err.response.data));
};

const enviarMensaje = (mensaje, token) => {
   return axios
      .post(`${API_INSCRIP}mensaje`, mensaje, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getHistorial = (codUser, token) => {
   return axios
      .get(`${API_INSCRIP}inscrip/historial/${codUser}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => console.log(err.response.data));
};

const getNotificaciones = (codUser, token) => {
   return axios
      .get(`${API_INSCRIP}notificaciones/${codUser}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getNewMensaje = (codUser, token) => {
   return axios
      .get(`${API_INSCRIP}nuevoMensaje/${codUser}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const setVisto = (data, token) => {
   return axios
      .patch(`${API_INSCRIP}setVisto`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const getCertificado = (codUsuario, codSub, token) => {
   return axios
      .get(`${API_INSCRIP}inscrip/certificado/${codUsuario}/${codSub}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const checkRestricciones = (codProyecto, codUsuario, codSub, token) => {
   return axios
      .get(`${API_INSCRIP}proyecto/restriccion/checkRestriccion/${codProyecto}/${codUsuario}/${codSub}`, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const updateInscripSubProyecto = (data, token) => {
   return axios
      .patch(`${API_INSCRIP}inscrip/subproyecto/update`, data, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => err.response.data);
};

const InscripService = {
   create,
   deleteInscripcion,
   getMisProyectos,
   inscriptActiv,
   darBaja,
   verVoluntario,
   enviarMensaje,
   getNotificaciones,
   getNewMensaje,
   setVisto,
   getHistorial,
   getCertificado,
   checkRestricciones,
   updateInscripSubProyecto,
};

export default InscripService;
