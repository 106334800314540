import * as Yup from "yup";

const restriccionesSchema = Yup.object().shape({
  confir: Yup.bool(),
  usuarioUC: Yup.bool(),
  hasMaxIntegrantes: Yup.bool(),
  maxIntegrantes: Yup.number()
    .nullable()
    .when("hasMaxIntegrantes", {
      is: true,
      then: Yup.number().typeError("Solo campos numéricos").integer("Este campo debe ser un número entero").min(0, "Valores negativos no admitidos").required("Este campo es requerido"),
    }),
  hasAreasEstudio: Yup.boolean(),
  idAreasEstudio: Yup.array()
    .nullable()
    .when("hasAreasEstudio", {
      is: true,
      then: Yup.array().min(1, "Este campo es requerido").typeError("Debe contener al menos un área permitida").required("Este campo es requerido"),
    }),
  hasMaxEstudio: Yup.boolean(),
  maxEstudio: Yup.number()
    .when("hasMaxEstudio", {
      is: true,
      then: Yup.number().integer("Este campo debe ser un número entero").min(0, "Valores negativos no admitido").required("Este campo es requerido").typeError("Solo caracteres numéricos"),
    })
    .typeError("Solo caracteres numéricos")
    .nullable(),

  hasMaxGenero: Yup.boolean(),
  maxGenero: Yup.number()
    .when("hasMaxGenero", {
      is: true,
      then: Yup.number().required("Este campo es requerido").integer("Este campo debe ser un número entero").min(0, "Valores negativos no admitidos").typeError("Solo caracteres numéricos"),
    })
    .typeError("Solo caracteres numéricos")
    .nullable(),
  hasMinEdad: Yup.boolean(),
  minEdad: Yup.number()
    .when("hasMinEdad", {
      is: true,
      then: Yup.number().required("Este campo es requerido").integer("Este campo debe ser un número entero").min(0, "Valores negativos no admitidos").moreThan(0, "La edad minima debe ser mayor que cero").lessThan(100, "Este valor no puede superar los 100 años").typeError("Solo caracteres numéricos"),
    })
    .typeError("Solo caracteres numéricos")
    .nullable(),
  hasMaxEdad: Yup.boolean(),
  maxEdad: Yup.number()
    .when("hasMaxEdad", {
      is: true,
      then: Yup.number()
        .required("Este campo es requerido")
        .integer("Este campo debe ser un número entero")
        .min(0, "Valores negativos no admitidos")
        // .moreThan(Yup.ref('minEdad'), 'La edad maxima debe ser mayor que la edad minima')
        .lessThan(100, "Este valor no puede superar los 100 años")
        .typeError("Solo caracteres numéricos"),
    })
    .typeError("Solo caracteres numéricos")
    .nullable(),
});

export default restriccionesSchema;
