import axios from 'axios';

const API_CATALOGO = process.env.REACT_APP_BACKEND_URL + 'proyectos/catalogo';
const cache = {};

const fetchCatalogo = async (config, token) => {
   if (cache[JSON.stringify(config)]) return cache[JSON.stringify(config)];

   const res = await axios
      .post(API_CATALOGO, config, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data)
      .catch((err) => console.log(err));
   cache[JSON.stringify(config)] = res;
   return res;
};

const fetchPaginador = (config, token) => {
   return axios
      .post(`${API_CATALOGO}/num_paginas`, config, {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
      })
      .then((response) => response.data.respuesta)
      .catch((err) => console.log(err));
};

const CatalogoService = {
   fetchCatalogo,
   fetchPaginador,
};

export default CatalogoService;
