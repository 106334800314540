import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../../../AppContext";
import ProyectosContainer from "../ProyectosContainer";

import { useForm } from "react-hook-form";
import SubProyectosService from "../../../../services/SubProyectosService";
import AlertUC from "../../../ui/AlertUC";
import { ButtonUC } from "../../../ui/ButtonUC";
import ModalUC from "../../../ui/ModalUC";
import { SubTitle } from "../../../ui/SubTitle";

import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import ProyectoService from "../../../../services/ProyectoService";
import InputUC from "../../../ui/InputUC";
import SelectUC from "../../../ui/SelectUC";
import NuevaActividadSchema from "../esquemas/NuevaActividadSchema";

function Subproyectos({ id, onSubmit, listSubproyectos, onJefaturas, nivel, refreshMisJefaturas, codProyecto }) {
  const token = useContext(AppContext);

  const [cuestionarios, setCuestionarios] = useState([]);
  const [cuestionarioOpciones, setCuestionarioOpciones] = useState([]);
  const [selectedCuestionarioOpcion, setSelectedCuestionarioOpcion] = useState(null);
  const [cuestionarioPredeterminado, setCuestionarioPredeterminado] = useState(null);
  const [subproyectos, setSubproyectos] = useState([]);
  const [subModal, setSubModal] = useState(false);
  const [edit, seEdit] = useState(false);
  const [modAct, setModAct] = useState(false);
  const [selectedSub, setSelectedSub] = useState({});
  const [selectedAct, setSelectedAct] = useState({});
  const [isNewSub, setIsNewSub] = useState(false);
  const [feedback, setFeedback] = useState({
    isOpen: false,
    realizado: false,
    id: null,
    respuesta: "",
    timestamp: "",
    errors: [],
  });
  const [isSubVisible, setIsSubVisible] = useState(true);
  const subRef = useRef();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    nombre: Yup.string().required("Este campo es requerido").trim(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset: resetSub,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    register: registerAct,
    formState: { errors: errorsAct },
    handleSubmit: handleSubmitAct,
    reset,
  } = useForm({
    resolver: yupResolver(NuevaActividadSchema),
  });

  const addSubproyecto = async (data) => {
    const newSubproyecto = {
      id: data.id,
      idProyecto: onJefaturas ? codProyecto : id,
      nombre: data.nombre,
      visible: isSubVisible ? "s" : "n",
      idUsuarioModif: 1,
    };
    const { id: newId, ...newSub } = newSubproyecto;
    let tempSub = {};
    if (isNewSub) {
      tempSub = newSub;
    } else {
      tempSub = newSubproyecto;
    }
    console.log(selectedCuestionarioOpcion);
    const response = await SubProyectosService.create({
      idCuestionario: selectedCuestionarioOpcion ? selectedCuestionarioOpcion.value : null,
      subproyecto: tempSub
    }, token);
    setFeedback({
      isOpen: true,
      ...response,
    });
    if (onJefaturas) {
      refreshMisJefaturas(response);
    } else {
      const subpro = await SubProyectosService.get(id, token);
      console.log(subpro);
      setSubproyectos(subpro);
    }
    setValue("nombre", "");
    setSubModal(false);
  };
  const updateSubproyecto = (rowValues, name) => {
    const subs = subproyectos.filter((sub) => (sub.id === rowValues.id ? (sub.nombre = name) : sub.nombre));
    setSubproyectos([...subs]);
    console.log(subs);
  };
  const deleteSubproyecto = async (rowValues) => {
    const response = await SubProyectosService.deleteSub(rowValues.id, token);
    console.log(response);
    if (onJefaturas) {
      refreshMisJefaturas(response);
    } else {
      const subpro = await SubProyectosService.get(id, token);
      // console.log(subpro)
      setSubproyectos(subpro);
    }
    if (response.respuesta) {
      setFeedback({
        isOpen: true,
        ...response,
      });
    } else {
      setFeedback({
        isOpen: true,
        respuesta: "No se puede eliminar el sub proyecto si tiene voluntarios inscritos.",
        ...response,
      });
    }
  };
  const newSubModal = () => {
    setValue("nombre", "");
    setIsSubVisible(true);
    setSubModal(true);
    setIsNewSub(true);
  };
  const editSubProyecto = (rowValues) => {
    console.log("edit", rowValues);
    setSubModal(true);
    setIsNewSub(false);
    setSelectedSub(rowValues);
    setIsSubVisible(rowValues.visible === "s");

    const selectedCuestionarioOpcion = cuestionarioOpciones.find(cuestionarioOpcion => cuestionarioOpcion.value === rowValues.idCuestionario);
    setSelectedCuestionarioOpcion(selectedCuestionarioOpcion ? selectedCuestionarioOpcion : cuestionarioPredeterminado);
  };
  const addActividad = (subId) => {
    reset(selectedAct);
    setSelectedAct({
      nombre: "",
      fechaIni: "",
      horaInicio: "",
      fechaTerm: "",
      horaTermino: "",
      descripcion: "",
      vacantes: null,
      autoInscrip: false,
    });
    setModAct(true);
    const sub = subproyectos.find((s) => s.id == subId);
    setSelectedSub(sub);
    console.log("addActividad", selectedAct);
  };
  const updateActividad = (acts, id, name) => {
    acts.map((actividad) => (actividad.id === id ? (actividad.nombre_actividad = name) : actividad.nombre_actividad));
  };
  const deleteActividad = async (sub, idAct) => {
    const response = await SubProyectosService.deleteAct(idAct, token);
    console.log(response);
    if (onJefaturas) {
      await refreshMisJefaturas(response);
    } else {
      const subpro = await SubProyectosService.get(id, token);
      setSubproyectos(subpro);
    }
    setFeedback({
      isOpen: true,
      ...response,
    });
  };

  const [modalActiv, setModalActiv] = useState(false);
  const [activContent, setActivContent] = useState(false);

  const verInscritos = async (sub, idAct) => {
    setModalActiv(true);
    setActivContent("cargando...");
    const response = await SubProyectosService.getActividad(idAct, token);
    console.log(response);
    const table = (
      <table className="uc-table container mb-2">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Email</th>
            <th>Telefono</th>
          </tr>
        </thead>
        <tbody>
          {response?.map((usuario, i) => (
            <tr key={i}>
              <td>{usuario.nom}</td>
              <td>
                {usuario.apellPat} {usuario.apellMat}
              </td>
              <td>{usuario.email}</td>
              <td>{usuario.telefono}</td>
            </tr>
          ))}
          <tr></tr>
        </tbody>
      </table>
    );
    setActivContent(table);
  };

  const editActividad = (act) => {
    const sub = subproyectos.find((s) => s.id == act.codSubProyecto);
    setSelectedSub(sub);
    setSelectedAct(act);
    setModAct(true);
  };

  const saveActividad = async (data) => {
    data.fechaIni = moment(data.fechaIni).format("YYYY-MM-DD");
    data.fechaTerm = moment(data.fechaTerm).format("YYYY-MM-DD");
    data.autoInscrip = data.autoInscrip ? "s" : "n";
    data.codSubProyecto = selectedSub.id;
    const response = await SubProyectosService.createActiv(data, token);
    console.log(response);
    if (onJefaturas) {
      await refreshMisJefaturas(response);
    } else {
      const subpro = await SubProyectosService.get(id, token);
      console.log(subpro);
      setSubproyectos(subpro);
    }
    setModAct(false);
    setFeedback({
      isOpen: true,
      ...response,
    });
  };

  const fetchSubproyectos = () => {
    if (onJefaturas) {
      setSubproyectos(listSubproyectos);
      console.log(listSubproyectos);
    } else {
      SubProyectosService.get(id, token)
        .then((response) => setSubproyectos(response))
        .catch((err) => console.log(err));
    }
  };
  const fetchCuestionarios = async () => {
    try {
       const response = await ProyectoService.fetchCuestionarios(id, token);
       if (response.respuesta) {
          setCuestionarios(response.respuesta);
       }
    } catch (err) {
       console.log(err);
    }
 };
  const listaVoluntarios = (sub) => {
    console.log(sub);
    history.push(`/proyectos/editar/${sub.idProyecto}/sub/${sub.id}/inscripciones`);
  };

  useEffect(() => {
    fetchSubproyectos();
    fetchCuestionarios();
  }, []);

  useEffect(() => {
    if (cuestionarios) {
      const cuestionarioOpciones = cuestionarios.map((option) => ({ value: option.codCuestionario, label: option.nombreCuestionario, predeterminado: option.predeterminado })); 
      console.log(cuestionarioOpciones);
      setCuestionarioOpciones(cuestionarioOpciones);
    }
  }, [cuestionarios]);

  useEffect(() => {
    if (cuestionarioOpciones) {
      const cuestionarioPredeterminado = cuestionarioOpciones.find(cuestionario => cuestionario.predeterminado === 's');
      setCuestionarioPredeterminado(cuestionarioPredeterminado);
    }
  }, [cuestionarioOpciones]);

  useEffect(() => {
    reset(selectedAct);
    resetSub(selectedSub);
  }, [selectedAct, selectedSub]);

  const renderTable = subproyectos.map((subproyecto) => (
    <div className="d-flex justify-content-between row my-4" key={subproyecto.id}>
      <div className="col-lg-12">
        <div className="d-flex align-items-center py-4">
          <h4>{subproyecto.nombre}</h4>
          <span className="uc-heading-decoration"></span>
          <i
            className="uc-icon mx-3"
            type="submit"
            onClick={() => {
              seEdit(true);
              editSubProyecto(subproyecto);
            }}
            title="Editar subproyecto"
          >
            edit
          </i>
          <i className="uc-icon mx-3" type="submit" onClick={() => deleteSubproyecto(subproyecto)} title="Eliminar subproyecto del esquema">
            delete
          </i>
          <i className="uc-icon mx-3" type="submit" onClick={() => listaVoluntarios(subproyecto)} title="Ver voluntarios inscritos">
            groups
          </i>
          <i className="uc-icon mx-3" title="Agregar nueva actividad" onClick={() => addActividad(subproyecto.id)}>
            add_circle_outline
          </i>
        </div>
        <table className="uc-table container mb-2">
          {/* </table>

                <table className="uc-table container" style={{ width: '98%' }}> */}
          <thead>
            <tr>
              {/* <th colSpan={2}><center>Actividades</center></th>
                            <th colSpan={2}>Inicio</th> */}
              <th>Acción</th>
              <th>Actividad</th>
              <th>Fecha</th>
              <th>Hora</th>
              <th colSpan={2}>Vacantes</th>
            </tr>
          </thead>
          <tbody>
            {subproyecto.actividades &&
              subproyecto.actividades.map((actividad, i) => (
                <tr key={i}>
                  <td>
                    <i
                      className="uc-icon px-4"
                      title="Editar actividad"
                      onClick={() => {
                        editActividad(actividad);
                      }}
                    >
                      edit
                    </i>
                    <i className="uc-icon px-4" title="Eliminar actividad" onClick={() => deleteActividad(subproyecto, actividad.id)}>
                      delete
                    </i>
                    <i className="uc-icon px-4" title="Ver inscritos" onClick={() => verInscritos(subproyecto, actividad.id)}>
                      groups
                    </i>
                  </td>
                  <td>{actividad.nombre}</td>
                  <td>{actividad.fechaIni}</td>
                  <td>{actividad.horaInicio}</td>
                  <td colSpan={2}>{actividad.vacantes}</td>
                </tr>
              ))}
            <tr></tr>
          </tbody>
        </table>
      </div>
    </div>
  ));

  return (
    <ProyectosContainer>
      <div className="container-fluid mt-24">
        {!onJefaturas && <SubTitle value="Esquema de subproyectos" />}
        {!onJefaturas && (
          <ButtonUC
            onClick={() => {
              seEdit(false);
              newSubModal(true);
              setSelectedCuestionarioOpcion(cuestionarioPredeterminado);
            }}
            className="uc-btn btn-primary mb-24"
          >
            Nuevo subproyecto
          </ButtonUC>
        )}
        {onJefaturas & (nivel === 37) ? (
          <div>
            <div style={{ float: "left", marginRight: "20px" }}>
              <ButtonUC onClick={() => history.push(`/proyectos/editar/${codProyecto}`)}>Editar Proyecto</ButtonUC>
            </div>
            <div style={{ float: "left" }}>
              <ButtonUC onClick={() => newSubModal(true)}>Nuevo subproyecto</ButtonUC>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        ) : (
          ""
        )}

        {modalActiv && (
          <ModalUC setModal={setModalActiv} title={"Lista de Usuarios"} setAlertType="success">
            {activContent}
          </ModalUC>
        )}

        {subModal && (
          <ModalUC setModal={setSubModal} title={edit ? "Editar subproyecto" : "Agregar subproyecto"} setAlertType="success">
            <form key={"sub"} onSubmit={handleSubmit(addSubproyecto)}>
              <div>
                <div>
                  <input name="id" type="hidden" {...register("id")} />
                  <input ref={subRef} placeholder="Ingresar nombre del Subproyecto" label="Nombre de subproyecto" name="nombre" className="uc-input-style" {...register("nombre")} />
                  <p className="p-size--sm form-error">{errors.nombre?.message}</p>
                </div>
                <div style={{ zIndex: 1000 }}>
                  <SelectUC
                      name="cuestionarios"
                      options={cuestionarioOpciones}
                      onChange={(e) => setSelectedCuestionarioOpcion(e)}
                      placeholder="Seleccionar cuestionario"
                      value={selectedCuestionarioOpcion}
                  />
                </div>
                <div className="form-check form-switch ml-4">
                  <input 
                    id="visible" 
                    name="visible"
                    type="checkbox" 
                    className="form-check-input"
                    defaultChecked={isSubVisible}
                    onChange={(e) => setIsSubVisible(e.target.checked)}
                  />
                  <label className="form-check-label my-auto" htmlFor="visible">
                    Visible
                  </label>
                </div>
              </div>
              <div className=" mb-auto">
                <center>
                  <ButtonUC type="submit" className="uc-btn btn-primary ">
                    {edit ? "Guardar" : "Agregar"}
                  </ButtonUC>
                </center>
              </div>
            </form>
          </ModalUC>
        )}
        {feedback.isOpen && <AlertUC feedback={feedback} setFeedback={setFeedback} />}

        {subproyectos ? (
          renderTable
        ) : (
          <table className="uc-table container mb-2">
            <thead>
              <tr>
                <th style={{ width: "120px" }}>Subproyecto</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Esquema vacío</strong>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {modAct && (
          <ModalUC setModal={setModAct} title={selectedSub.nombre} setAlertType="success" style={{ maxWidth: "250px" }}>
            <div className="">
              <form key={"act"} onSubmit={handleSubmitAct(saveActividad)}>
                {console.log(errorsAct)}
                <InputUC label="Nombre Actividad" name="nombre" type="text" errores={errorsAct.nombre?.message} {...registerAct("nombre")} />

                <div className="row">
                  <div className="col-md-6">
                    <InputUC label="Fecha inicio" type="date" name="fechaIni" {...registerAct("fechaIni")} />
                  </div>
                  <div className="col-md-6">
                    <InputUC label="Hora inicio" type="time" name="horaInicio" {...registerAct("horaInicio")} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <InputUC label="Fecha fin" type="date" name="fechaTerm" {...registerAct("fechaTerm")} />
                  </div>
                  <div className="col-md-6">
                    <InputUC label="Hora fin" type="time" name="horaTermino" {...registerAct("horaTermino")} />
                  </div>
                </div>
                <div className="uc-form-group">
                  <label htmlFor="descripcion" className="uc-label">
                    Descripción
                  </label>
                  <textarea name="descripcion" className="uc-input-style" type="textarea" {...registerAct("descripcion")} />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <InputUC label="Vacantes" type="number" name="vacantes" {...registerAct("vacantes")} />
                  </div>
                  {/* <div className="col-md-6">
                                    <InputUC
                                        label='Auto Inscripcion'
                                        type='checkbox'
                                        name='autoInscrip'
                                        {...registerAct('autoInscrip')}
                                    />
                                </div> */}
                </div>
                <div className="">
                  <button className="uc-btn btn-cta" type="submit">
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </ModalUC>
        )}
      </div>
    </ProyectosContainer>
  );
}

export default Subproyectos;
