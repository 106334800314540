import React, { useState, useEffect } from 'react';
import Formulario from '../../voluntarios/Formulario';
import DominiosService from '../../../../services/DominiosService';

function Registros(props) {
   const [formVol, setFormVol] = useState({});
   const paises = [
      { value: false, label: 'Chileno' },
      { value: true, label: 'Extranjero' },
   ];
   //const []

   const fetchUser = async () => {
      const pais = paises.filter((opcion) => (opcion.value === props.voluntario.extranjero ? opcion : ''));

      const areaestudio = DominiosService.fetchSelected('areas_estudio', props.voluntario.idareaestudio);
      const genero = DominiosService.fetchSelected('genero', props.voluntario.idgenero);
      const categoria = DominiosService.fetchSelected('categorias', props.voluntario.idcategoria);
      const institucion = DominiosService.fetchSelected('instituciones', props.voluntario.idinstitucion);
      const region = DominiosService.fetchSelected('regiones', props.voluntario.region);
      const comuna = DominiosService.fetchSelected('comunas', props.voluntario.comuna);
      const res = await Promise.all([areaestudio, genero, categoria, institucion, region, comuna]);
      console.log(res);
      if (res[0].value === '') {
         res[0] = await DominiosService.fetchSelected('unidades', props.voluntario.idareaestudio);
      }
      if (res[0].value === '') {
         res[0] = await DominiosService.fetchSelected('participacion', props.voluntario.idareaestudio);
      }
      if (res[3].value === '') {
         res[3] = await DominiosService.fetchSelected('cursos', props.voluntario.idinstitucion);
      }

      const formattedValues = {
         //id: id,
         nombres: props.voluntario.nombres,
         apaterno: props.voluntario.apaterno,
         amaterno: props.voluntario.amaterno,
         rut: props.voluntario.rut,
         pasaporte: props.voluntario.pasaporte,
         isPasaporte: props.voluntario.isPasaporte,

         correo: props.voluntario.correo?.toLowerCase(),
         telefono: props.voluntario.telefono,
         nacimiento: props.voluntario.nacimiento,
         facultad: props.voluntario.idfacultado,

         ingreso: props.voluntario.ingreso,
         extranjero: pais[0],
         newsletter: props.voluntario.newsletter,

         areaestudio: res[0],
         otrainstitucion: props.voluntario.otrainstitucion,
         activado: props.voluntario.activado,
         plazoadm: props.voluntario.plazoadm,
         condiciones: props.voluntario.condiciones,

         perfil: props.voluntario.idperfil,
         genero: res[1],
         categoria: res[2],
         institucion: res[3],
         region: res[4],
         comuna: res[5],
         idusuariomodif: props.voluntario.idusuariomodif,
         is_cas: props.voluntario.usuarioCAS,
      };

      setFormVol(formattedValues);
   };

   useEffect(() => {
      fetchUser();
   }, []);

   return <Formulario defaults={formVol} read={true} dis={true} />;
}

export default Registros;
