import React, { useEffect, useMemo, useState, useContext } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import Table from "../../../ui/Table";
import SubProyectosService from "../../../../services/SubProyectosService";
import AppContext from "../../../../AppContext";
import moment from "moment";

import * as XLSX from "xlsx";
import ButtonUC from "../../../ui/ButtonUC";
import UserContext from "../../../../UserContext";

function ListaVoluntarios() {
  const { id, idPro } = useParams();
  const history = useHistory();
  const token = useContext(AppContext);
  const user = useContext(UserContext);
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(false);
  const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss.SSS";

  const verVoluntario = (row) => {
    console.log(row);
    history.push(`/proyectos/editar/${idPro}/sub/${row.codSubProyecto}/inscrito/${row.codUsuario}`);
  };
  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        accessor: "actions",
        Cell: (props) => {
          const rowValues = props.row.values;

          return (
            <span onClick={() => verVoluntario(rowValues)} title="Ver ficha">
              <i className="uc-icon icon-shape--rounded mx-2">edit</i>
            </span>
          );
        },
      },
      {
        Header: "codUsuario",
        accessor: "codUsuario",
      },
      {
        Header: "codSubProyecto",
        accessor: "codSubProyecto",
      },
      {
        Header: "Nombre",
        accessor: "nomVoluntario",
      },
      {
        Header: "Correo",
        accessor: "correo",
      },
      {
        Header: "Subproyecto",
        accessor: "nomSubProyecto",
      },
      {
        Header: "Área estudio",
        accessor: "areaEstudio",
      },
      {
        Header: "Estado",
        accessor: "estadoInscrip",
      },
      {
        Header: "Estado de Pago",
        accessor: "estadPago",
      },
      {
        Header: "Hora de inscripción",
        accessor: "fechaInscrip",
        Cell: (props) => {
          const fecha = props.row.values.fechaInscrip;
          if (fecha !== null) return <span>{moment(fecha).format(DATE_TIME_FORMAT)}</span>;
          else return "";
        },
      }
    ],
    []
  );

  useEffect(() => {
    SubProyectosService.getLista(idPro, id !== undefined ? id : "", false, token)
      .then((res) => setLista(res))
      .catch((err) => console.log(err));
  }, []);
  const initialState = {
    hiddenColumns: ["codUsuario", "codSubProyecto"],
  };
  const generateExcel = () => {
    setLoading(true);
    SubProyectosService.getLista(idPro, id !== undefined ? id : "", true, token)
      .then((res) => {
        const dataToExport = [];
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          const data = {};
          data['Nombre'] = element.nomVoluntario;
          data['Correo'] = element.correo;
          data['Teléfono'] = element.telefono;
          data['Subproyecto'] = element.nomSubProyecto;
          data['Estado'] = element.estadoInscrip;
          data['Estado de Pago'] = element.estadPago;
          data['Hora de inscripción'] = element.fechaInscrip ? moment(element.fechaInscrip).format(DATE_TIME_FORMAT) : '';
    
          for (let index = 0; index < element.cuestionariosRespondidos.length; index++) {
            const cuestionarioRespondido = element.cuestionariosRespondidos[index];
            if (cuestionarioRespondido.respuesta) {
              // If contains file, add link to the questionnaire page
              if (cuestionarioRespondido.containsFile) {
                data[cuestionarioRespondido.enunciado] = {
                  l: { Target: `${process.env.REACT_APP_FRONT_URL}proyectos/editar/${idPro}/sub/${element.codSubProyecto}/inscrito/${element.codUsuario}?step=2` },
                  v: cuestionarioRespondido.respuesta
                }
              } else {
                data[cuestionarioRespondido.enunciado] = cuestionarioRespondido.respuesta
              }
            } else {
              const opcionesSeleccionadas = cuestionarioRespondido.opciones
                .filter(opcion => opcion.checked)
                .map(opcion => opcion.alternativa);
              data[cuestionarioRespondido.enunciado] = opcionesSeleccionadas.join();
            }
          }
    
          dataToExport.push(data);
        }
    
        const workbook = XLSX.utils.book_new();
        let sheet = XLSX.utils.json_to_sheet(dataToExport);
        XLSX.utils.book_append_sheet(workbook, sheet, "Inscritos en subproyecto");
        XLSX.writeFile(workbook, "Reporte.xlsx");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    
  };
  return (
    <div>
      { user.permiso.exportarDatos && (
        <div class="container">
          <div class="row">
            <ButtonUC
              type="button"
              onClick={() => generateExcel()}
              disabled={loading}
            >
              Exportar a Excel
            </ButtonUC>
            { loading && (
              <div class="spinner-border m-2" role="status">
                <span class="sr-only">Cargando...</span>
              </div>
            ) }
          </div>
        </div>
      ) }
      <Table
        id="table_lista_voluntarios"
        columns={columns}
        data={lista}
        // ocultar columna con init
        initialState={initialState}
      />
    </div>
  );
}
export default ListaVoluntarios;
